import { FormikInstance } from '../types/Formik';

const formikToInput = <T>(formik: FormikInstance<T>, identifier: keyof T) => {
  return {
    id: identifier as string,
    name: identifier as string,
    value: formik.values[identifier],
    onChange: formik.handleChange,
    error: formik.touched[identifier] && Boolean(formik.errors[identifier]),
    helperText: formik.touched[identifier] && formik.errors[identifier],
    disabled: formik.isSubmitting,
  };
};
export default formikToInput;
