import { makeStyles, Popover } from '@material-ui/core';
import { FC } from 'react';
import Image, { ImageProps } from './Image';

const useStyles = makeStyles((theme) => ({
  root: {
    pointerEvents: 'none',
  },
  paper: {
    display: 'flex',
  },
  img: {
    maxWidth: '30vw',
    minWidth: '30vw',
  },
}));

interface ImagePopoverProps extends ImageProps {
  anchorEl?: Element | ((element: Element) => Element);
}

const ImagePopover: FC<ImagePopoverProps> = ({ alt, url, backgroundColor, anchorEl }) => {
  const styles = useStyles();

  return (
    <Popover
      open={Boolean(anchorEl)}
      disableRestoreFocus
      anchorEl={anchorEl}
      classes={{ paper: styles.paper }}
      className={styles.root}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Image className={styles.img} alt={alt} url={url} backgroundColor={backgroundColor} />
    </Popover>
  );
};
export default ImagePopover;
