import fb from 'firebase/app';
import COLLECTION from '../constants/collections';
import {
  ImageMedia,
  ImageMediaProps,
  Media,
  MediaProps,
  MediaType,
  toMedia,
  VideoMedia,
  VideoMediaProps,
} from '../models/Media';
import { OnDataFunction } from '../types/general';
import { defined, notDefined } from '../utils';
import { FirestoreRepo } from './FirestoreRepo';

class MediaRepo extends FirestoreRepo {
  private root(
    partnerId: string,
    type: MediaType.VIDEO,
  ): fb.firestore.CollectionReference<VideoMediaProps>;
  private root(
    partnerId: string,
    type: MediaType.IMAGE,
  ): fb.firestore.CollectionReference<ImageMediaProps>;
  private root(partnerId: string): fb.firestore.CollectionReference<MediaProps>;
  private root(partnerId: string, type?: MediaType) {
    const root = this.fs.collection(COLLECTION.PARTNER).doc(partnerId).collection(COLLECTION.MEDIA);
    if (!type) return root;
    return this.filteredQuery(root, { type });
  }

  async getMedia(partnerId: string, id: string) {
    const snap = await this.root(partnerId).doc(id).get();
    return this.snapToMedia(partnerId, snap);
  }
  async getMedias(partnerId: string, ids: string[]): Promise<Media[]> {
    return this.clearUndefined(
      await Promise.all(ids.map(async (id) => await this.getMedia(partnerId, id))),
    );
  }

  async create(media: Media) {
    notDefined(media.id, 'Media.id');
    const result = await this.root(media.partnerId).add(media.props);
    return result.id;
  }
  async update(media: Media) {
    await this.root(media.partnerId).doc(defined(media.id)).update(media.changes);
  }
  async remove(media: Media) {
    await this.root(media.partnerId).doc(defined(media.id)).delete();
  }

  onMedia(partnerId: string, mediaId: string, onData: OnDataFunction<Media | undefined>) {
    const unsubscribe = this.root(partnerId)
      .doc(mediaId)
      .onSnapshot(
        (snap) => {
          const media = this.snapToMedia(partnerId, snap);
          onData(media, unsubscribe);
        },
        (error) => {
          console.error(error);
          onData(undefined, unsubscribe);
        },
      );
    return unsubscribe;
  }
  onMedias(partnerId: string, onData: OnDataFunction<Media[]>) {
    const unsubscribe = this.root(partnerId).onSnapshot(
      (snap) => {
        const medias = this.snapToMedias(partnerId, snap);
        onData(medias, unsubscribe);
      },
      (error) => {
        console.error(error);
        onData([], unsubscribe);
      },
    );
    return unsubscribe;
  }

  private snapToMedia(
    partnerId: string,
    snap: fb.firestore.DocumentSnapshot<VideoMediaProps>,
  ): VideoMedia | undefined;
  private snapToMedia(
    partnerId: string,
    snap: fb.firestore.DocumentSnapshot<ImageMediaProps>,
  ): ImageMedia | undefined;
  private snapToMedia(
    partnerId: string,
    snap: fb.firestore.DocumentSnapshot<MediaProps>,
  ): Media | undefined;
  private snapToMedia(partnerId: string, snap: fb.firestore.DocumentSnapshot<MediaProps>) {
    const data = snap.data();
    if (!data) return undefined;
    return toMedia(partnerId, data, snap.id);
  }
  private snapToMedias(
    partnerId: string,
    snap: fb.firestore.QuerySnapshot<VideoMediaProps>,
  ): VideoMedia[];
  private snapToMedias(
    partnerId: string,
    snap: fb.firestore.QuerySnapshot<ImageMediaProps>,
  ): ImageMedia[];
  private snapToMedias(partnerId: string, snap: fb.firestore.QuerySnapshot<MediaProps>): Media[];
  private snapToMedias(partnerId: string, snap: fb.firestore.QuerySnapshot<MediaProps>) {
    return this.clearUndefined(snap.docs.map((snap) => this.snapToMedia(partnerId, snap)));
  }
}

export { MediaRepo };
