import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Partner } from '../../models/Partner';
import useCreateIntegration from '../action/integration/useCreateIntegration';

export interface useCreateIntegrationFormConfig {
  partner?: Partner;
  onSuccess?: () => Promise<void> | void;
}

const useCreateIntegrationForm = ({ partner, onSuccess }: useCreateIntegrationFormConfig) => {
  const { t } = useTranslation();

  const createIntegration = useCreateIntegration();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      externalKey: '',
    },
    onSubmit: async ({ externalKey }) => {
      if (!partner) return;
      await createIntegration.action(partner, externalKey);
      formik.resetForm();
      if (onSuccess) await onSuccess();
    },
    validationSchema: yup.object({
      externalKey: yup
        .string()
        .required(t('integration.validation.external-key-required'))
        .length(36, t('integration.validation.external-key-invalid')),
    }),
  });
  return formik;
};
export default useCreateIntegrationForm;
