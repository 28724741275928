import { Button, ButtonProps, Link } from '@material-ui/core';
import { VFC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface LinkButtonProps extends ButtonProps {
  to: string;
}

const LinkButton: VFC<LinkButtonProps> = ({ to, children, ...ButtonProps }) => {
  return (
    <Link component={RouterLink} to={to}>
      <Button variant="text" color="default" size="small" {...ButtonProps}>
        {children}
      </Button>
    </Link>
  );
};
export default LinkButton;
