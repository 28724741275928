import { MediaType } from './enum';
import { ImageMedia, VideoMedia } from './spec';

function newMedia(type: MediaType.IMAGE): typeof ImageMedia.new;
function newMedia(type: MediaType.VIDEO): typeof VideoMedia.new;
function newMedia(type: MediaType) {
  switch (type) {
    case MediaType.IMAGE:
      return ImageMedia.new;
    case MediaType.VIDEO:
      return VideoMedia.new;

    default:
      throw new Error('Unknown implementation');
  }
}

export { newMedia };
