import { PartnerLinkType } from '../enum';
import {
  NewPartnerLinkBaseProps,
  PartnerLinkBase,
  PartnerLinkBaseProps,
} from '../PartnerLink.base';

interface ExternalPartnerPartnerLinkProps extends PartnerLinkBaseProps {
  type: PartnerLinkType.EXT_PARTNER_PARTNER;
  partnerId: string;
  extPartnerId: string;
}

interface NewExternalPartnerPartnerLinkProps extends NewPartnerLinkBaseProps {
  partnerId: string;
  extPartnerId: string;
}

class ExternalPartnerPartnerLink extends PartnerLinkBase<ExternalPartnerPartnerLinkProps> {
  static new(props: NewExternalPartnerPartnerLinkProps) {
    return new ExternalPartnerPartnerLink({
      type: PartnerLinkType.EXT_PARTNER_PARTNER,
      ...super.New(props),
    });
  }

  get type() {
    return this._props.type;
  }
  get partnerId() {
    return this._props.partnerId;
  }
  get extPartnerId() {
    return this._props.extPartnerId;
  }
}

export { ExternalPartnerPartnerLink };
export type { ExternalPartnerPartnerLinkProps, NewExternalPartnerPartnerLinkProps };
