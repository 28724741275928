const COLLECTION = {
  PARTNER: 'partner',
  INTEGRATION: 'integration',
  ADVERT: 'advert',
  MEDIA: 'media',
  IMPRESSION: 'impression',
  MEMBER: 'member',
  LINK: 'link',
  PARTNERLINK: 'partnerlink',
};
export default COLLECTION;
