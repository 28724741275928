import {
  Divider,
  Drawer,
  DrawerProps,
  IconButton,
  List,
  makeStyles,
  Toolbar,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import clsx from 'clsx';
import { FC, ReactNode } from 'react';
import { SliderLayoutContextProps, useSliderLayoutCtx } from './SliderLayout';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: ({ drawerWidth }: { drawerWidth: number }) => drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
    },
  }),
  { index: 1 },
);

interface SliderLayoutDrawerProps extends DrawerProps {
  render?: (layout: SliderLayoutContextProps) => ReactNode;
}

const SliderLayoutDrawer: FC<SliderLayoutDrawerProps> = ({
  open,
  children,
  render,
  ...DrawerProps
}) => {
  const layout = useSliderLayoutCtx();
  const styles = useStyles(layout);

  return (
    <Drawer
      open={layout.isDrawerOpen}
      variant="permanent"
      {...DrawerProps}
      className={clsx(styles.root, {
        [styles.drawerOpen]: layout.isDrawerOpen,
        [styles.drawerClose]: !layout.isDrawerOpen,
      })}
      classes={{
        paper: clsx({
          [styles.drawerOpen]: layout.isDrawerOpen,
          [styles.drawerClose]: !layout.isDrawerOpen,
        }),
      }}
    >
      <Toolbar>
        <IconButton edge="start" onClick={() => layout.closeDrawer()}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <List>
        {render && render(layout)}
        {children}
      </List>
    </Drawer>
  );
};
export default SliderLayoutDrawer;
