import { Button, ButtonProps, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { VFC } from 'react';

export interface OverlapButtonProps extends ButtonProps {}
const useOverlapButtonStyles = makeStyles<Theme, {}, 'button'>((theme) => ({
  button: {
    position: 'absolute',
    padding: 0,
    margin: 0,
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  },
}));
const OverlapButton: VFC<OverlapButtonProps> = (ButtonProps) => {
  const styles = useOverlapButtonStyles();
  return (
    <Button fullWidth {...ButtonProps} className={clsx(styles.button, ButtonProps.className)} />
  );
};
export default OverlapButton;
