import { useEffect, useMemo, useState } from 'react';
import { Advert } from '../../../models/Advert';
import { Integration } from '../../../models/Integration';
import { AdvertIntegrationLink } from '../../../models/Link';
import { integrationRepo, linkRepo } from '../../../repo';
import { defined } from '../../../utils';
import useRenderSafe from '../../useRenderSafe';

export interface AdvertIntegration {
  integration: Integration | undefined;
  link: AdvertIntegrationLink;
}

const useAdvertIntegrations = (advert?: Advert, linksOnly?: boolean) => {
  const partnerId = useRenderSafe(advert?.partnerId);
  const advertId = useRenderSafe(advert?.id);
  const [advertIntegrationLinks, setAdvertIntegrationLinks] = useState<AdvertIntegrationLink[]>();
  const [advertIntegrationsData, setAdvertIntegrationsData] = useState<{
    [linkId: string]: {
      link: AdvertIntegrationLink;
      integration?: Integration;
      unsubscribe?: () => void;
    };
  }>({});

  useEffect(() => {
    try {
      if (!partnerId || !advertId) return setAdvertIntegrationLinks(undefined);
      return linkRepo.onAdvertIntegrationLinks(partnerId, advertId, (advertIntegrationLinks) =>
        setAdvertIntegrationLinks(advertIntegrationLinks),
      );
    } catch (error) {
      setAdvertIntegrationLinks(undefined);
    }
  }, [partnerId, advertId]);

  useEffect(() => {
    if (linksOnly) setAdvertIntegrationsData({});
    if (!partnerId || !advertId)
      return setAdvertIntegrationsData((data) => {
        Object.entries(data).forEach(([, { unsubscribe }]) => {
          if (unsubscribe) unsubscribe();
        });
        return {};
      });
    setAdvertIntegrationsData((data) => {
      //Add new and update existing
      Object.keys(data).forEach((linkId) => {
        if (!advertIntegrationLinks?.some((p) => p.id === linkId)) {
          const unsubscribe = data[linkId].unsubscribe;
          if (unsubscribe) unsubscribe();
          delete data[linkId];
        }
      });
      //Remove old
      advertIntegrationLinks?.forEach((advertIntegrationLink) => {
        if (!advertIntegrationLink.id) return;
        if (!data[advertIntegrationLink.id]) {
          data[advertIntegrationLink.id] = {
            link: advertIntegrationLink,
          };
          const unsubscribe = integrationRepo.onIntegration(
            advertIntegrationLink.integrationId,
            (advert) => {
              setAdvertIntegrationsData((old) => ({
                ...old,
                [defined(advertIntegrationLink.id)]: {
                  ...old[defined(advertIntegrationLink.id)],
                  advert,
                  unsubscribe,
                },
              }));
            },
          );
        } else {
          data[advertIntegrationLink.id].link = advertIntegrationLink;
        }
      });
      return data;
    });
    return () => {
      setAdvertIntegrationsData((data) => {
        Object.entries(data).forEach(([, { unsubscribe }]) => {
          if (unsubscribe) unsubscribe();
        });
        return {};
      });
    };
  }, [advertIntegrationLinks, advertId, linksOnly, partnerId]);

  const advertIntegrations: AdvertIntegration[] = useMemo(
    () =>
      Object.values(advertIntegrationsData)
        .filter((p) => p.integration)
        .map(({ integration, link }) => ({ integration, link })),
    [advertIntegrationsData],
  );

  return { advertIntegrationLinks, advertIntegrations };
};
export default useAdvertIntegrations;
