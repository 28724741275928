import { FieldValue, timeStampField } from '../../firebase';
import DataModel from '../DataModel';
import { MediaType, SizeType } from './enum';

interface MediaBaseProps {
  type: MediaType;
  size: SizeType;
  name: string;
  fileName: string;
  backgroundColor: string;
  isActive: boolean;
  createdAt: Date | FieldValue;
  updatedAt: Date | FieldValue;
}

interface NewMediaBaseProps {
  size: SizeType;
  name: string;
  fileName: string;
  backgroundColor?: string;
  isActive?: boolean;
}

abstract class MediaBase<P extends MediaBaseProps> extends DataModel<P> {
  protected static New<P extends NewMediaBaseProps>(props: P) {
    return {
      isActive: false,
      createdAt: timeStampField(),
      updatedAt: timeStampField(),
      backgroundColor: '#FFFFFF',
      ...props,
    };
  }

  protected _partnerId;
  constructor(partnerId: string, props: P, id?: string) {
    super(props, id);
    this._partnerId = partnerId;
  }

  get partnerId() {
    return this._partnerId;
  }

  get type() {
    return this._props.type;
  }
  get size() {
    return this._props.size;
  }
  get name() {
    return this._props.name;
  }
  get fileName() {
    return this._props.fileName;
  }
  get backgroundColor() {
    return this._props.backgroundColor;
  }
  get isActive() {
    return this._props.isActive;
  }
  get createdAt() {
    if (this.changes.createdAt) throw Error('Is timestampField');
    return this._props.createdAt;
  }
  get updatedAt() {
    if (this.changes.updatedAt) throw Error('Is timestampField');
    return this._props.updatedAt;
  }

  changeFile(fileName: string, size: SizeType) {
    this.propChange(fileName, 'fileName');
    this.propChange(size, 'size');
  }
  set name(v) {
    this.propChange(v, 'name');
  }
  set backgroundColor(v) {
    this.propChange(v, 'backgroundColor');
  }
  set isActive(v) {
    this.propChange(v, 'isActive');
  }

  protected propChange<K1 extends keyof P, K2 extends keyof P[K1], K3 extends keyof P[K1][K2]>(
    v: any,
    k1: K1,
    k2?: K2,
    k3?: K3,
  ) {
    if (!super.propChange(v, k1, k2, k3)) return false;
    super.propChange(timeStampField(), 'updatedAt');
    return true;
  }
}

export { MediaBase };
export type { MediaBaseProps, NewMediaBaseProps };
