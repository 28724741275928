import { LinkType } from '../enum';
import { LinkBase, LinkBaseProps, NewLinkBaseProps } from '../Link.base';

interface AdvertMediaLinkProps extends LinkBaseProps {
  type: LinkType.ADVERT_MEDIA;
  advertId: string;
  mediaId: string;
}

interface NewAdvertMediaLinkProps extends NewLinkBaseProps {
  advertId: string;
  mediaId: string;
}

class AdvertMediaLink extends LinkBase<AdvertMediaLinkProps> {
  static new(partnerId: string, props: NewAdvertMediaLinkProps) {
    return new AdvertMediaLink(partnerId, {
      type: LinkType.ADVERT_MEDIA,
      ...super.New(props),
    });
  }

  get type() {
    return this._props.type;
  }
  get advertId() {
    return this._props.advertId;
  }
  get mediaId() {
    return this._props.mediaId;
  }
}

export { AdvertMediaLink };
export type { AdvertMediaLinkProps, NewAdvertMediaLinkProps };
