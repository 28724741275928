import { useState } from 'react';
import { IntegrationType, newIntegration } from '../../../models/Integration';
import { Partner } from '../../../models/Partner';
import { integrationRepo } from '../../../repo';
import { defined } from '../../../utils';

const useCreateIntegration = () => {
  const [isBusy, setIsBusy] = useState(false);
  const action = async (partner: Partner, externalKey: string) => {
    try {
      setIsBusy(true);
      const integration = newIntegration(IntegrationType.TAPIO)({
        partnerId: defined(partner.id, 'partner.id'),
        externalKey: externalKey,
      });
      await integrationRepo.create(integration);
    } catch (error) {
    } finally {
      setIsBusy(false);
    }
  };

  return { isBusy, action };
};
export default useCreateIntegration;
