import { LinkType } from './enum';
import { Link, LinkProps } from './Link';
import {
  AdvertIntegrationLink,
  AdvertIntegrationLinkProps,
  AdvertMediaLink,
  AdvertMediaLinkProps,
  AdvertPartnerLink,
  AdvertPartnerLinkProps,
  ExternalAdvertIntegrationLink,
  ExternalAdvertIntegrationLinkProps,
} from './spec';

function toLink(partnerId: string, props: AdvertMediaLinkProps, id?: string): AdvertMediaLink;
function toLink(
  partnerId: string,
  props: AdvertIntegrationLinkProps,
  id?: string,
): AdvertIntegrationLink;
function toLink(partnerId: string, props: AdvertPartnerLinkProps, id?: string): AdvertPartnerLink;
function toLink(
  partnerId: string,
  props: ExternalAdvertIntegrationLinkProps,
  id?: string,
): ExternalAdvertIntegrationLink;
function toLink(partnerId: string, props: LinkProps, id?: string): Link;
function toLink(partnerId: string, props: LinkProps, id?: string): Link {
  switch (props.type) {
    case LinkType.ADVERT_INTEGRATION:
      return new AdvertIntegrationLink(partnerId, props, id);
    case LinkType.ADVERT_MEDIA:
      return new AdvertMediaLink(partnerId, props, id);
    case LinkType.ADVERT_PARTNER:
      return new AdvertPartnerLink(partnerId, props, id);
    case LinkType.EXT_ADVERT_INTEGRATION:
      return new ExternalAdvertIntegrationLink(partnerId, props, id);

    default:
      throw new Error('Unknown implementation');
  }
}

export { toLink };
