import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { newAdvert } from '../../../models/Advert';
import { Partner } from '../../../models/Partner';
import { advertRepo } from '../../../repo';
import { defined } from '../../../utils';

const useCreateAdvert = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isBusy, setIsBusy] = useState(false);
  const action = async (partner: Partner, props: { name: string }) => {
    try {
      setIsBusy(true);
      const advert = newAdvert()(defined(partner.id), {
        name: props.name,
        isActive: true,
      });
      const advertId = await advertRepo.create(advert);
      enqueueSnackbar(t('advert.action.create.successMessage'), { variant: 'success' });
      return advertId;
    } catch (error) {
      enqueueSnackbar(t('advert.action.create.failedMessage'), { variant: 'error' });
    } finally {
      setIsBusy(false);
    }
  };

  return { isBusy, action };
};
export default useCreateAdvert;
