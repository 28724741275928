import { useEffect, useState } from 'react';
import { Media } from '../../../models/Media';
import { Partner } from '../../../models/Partner';
import { mediaRepo } from '../../../repo';
import useRenderSafe from '../../useRenderSafe';

const useMedia = (partner: Partner, id?: string) => {
  const partnerId = useRenderSafe(partner.id);
  const [media, setMedia] = useState<{ value: Media; unsubscribe: () => void }>();

  useEffect(() => {
    try {
      //Remove old subscription
      if (!partnerId || !id) {
        return setMedia((v) => {
          v?.unsubscribe();
          return undefined;
        });
      } else {
        const unsubscribe = mediaRepo.onMedia(partnerId, id, (media) => {
          if (media)
            setMedia((v) => {
              v?.unsubscribe();
              return { value: media, unsubscribe };
            });
        });
        return unsubscribe;
      }
    } catch (error) {
      console.error(error);
    }
  }, [id, partnerId]);

  return media?.value;
};
export default useMedia;
