import { useEffect, useMemo, useState } from 'react';
import { Partner } from '../../../models/Partner';
import { ExternalPartnerPartnerLink } from '../../../models/PartnerLink';
import { partnerLinkRepo, partnerRepo } from '../../../repo';
import { defined } from '../../../utils';
import useRenderSafe from '../../useRenderSafe';

export interface IncomingPartner {
  partner: Partner | undefined;
  link: ExternalPartnerPartnerLink;
}

const usePartnerIncomingPartners = (partner?: Partner, linksOnly?: boolean) => {
  const partnerId = useRenderSafe(partner?.id);
  const [incomingPartnerLinks, setIncomingPartnerLinks] = useState<ExternalPartnerPartnerLink[]>();
  const [incomingPartnersData, setPartnersData] = useState<{
    [linkId: string]: {
      link: ExternalPartnerPartnerLink;
      partner?: Partner;
      unsubscribe?: () => void;
    };
  }>({});

  useEffect(() => {
    try {
      if (!partnerId) return setIncomingPartnerLinks(undefined);
      return partnerLinkRepo.onIncomingPartnerLinks(partnerId, (incomingPartnerLinks) =>
        setIncomingPartnerLinks(incomingPartnerLinks),
      );
    } catch (error) {
      setIncomingPartnerLinks(undefined);
    }
  }, [partnerId]);

  useEffect(() => {
    if (linksOnly) setPartnersData({});
    if (!partnerId)
      return setPartnersData((data) => {
        Object.entries(data).forEach(([, { unsubscribe }]) => {
          if (unsubscribe) unsubscribe();
        });
        return {};
      });
    setPartnersData((data) => {
      //Add new and update existing
      Object.keys(data).forEach((linkId) => {
        if (!incomingPartnerLinks?.some((p) => p.id === linkId)) {
          const unsubscribe = data[linkId].unsubscribe;
          if (unsubscribe) unsubscribe();
          delete data[linkId];
        }
      });
      //Remove old
      incomingPartnerLinks?.forEach((partnerLink) => {
        if (!partnerLink.id) return;
        if (!data[partnerLink.id]) {
          data[partnerLink.id] = {
            link: partnerLink,
          };
          const unsubscribe = partnerRepo.onPartner(partnerLink.extPartnerId, (partner) => {
            setPartnersData((old) => ({
              ...old,
              [defined(partnerLink.id)]: {
                ...old[defined(partnerLink.id)],
                partner,
                unsubscribe,
              },
            }));
          });
        } else {
          data[partnerLink.id].link = partnerLink;
        }
      });
      return data;
    });
    return () => {
      setPartnersData((data) => {
        Object.entries(data).forEach(([, { unsubscribe }]) => {
          if (unsubscribe) unsubscribe();
        });
        return {};
      });
    };
  }, [incomingPartnerLinks, partnerId, linksOnly]);

  const incomingPartners: IncomingPartner[] = useMemo(
    () =>
      Object.values(incomingPartnersData)
        .filter((p) => p.partner)
        .map(({ partner, link }) => ({ partner, link })),
    [incomingPartnersData],
  );

  return { incomingPartnerLinks, incomingPartners };
};
export default usePartnerIncomingPartners;
