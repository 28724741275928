import { FieldValue, timeStampField } from '../../firebase';
import DataModel from '../DataModel';

interface ImpressionProps {
  advertId: string;
  integrationId: string;
  mediaId: string;
  timestamp: Date | FieldValue;
}
interface NewImpressionProps {
  advertId: string;
  integrationId: string;
  mediaId: string;
}

class Impression extends DataModel<ImpressionProps> {
  static new(partnerId: string, props: NewImpressionProps) {
    return new Impression(partnerId, {
      timestamp: timeStampField(),
      ...props,
    });
  }

  protected _partnerId;
  constructor(partnerId: string, props: ImpressionProps, id?: string) {
    super(props, id);
    this._partnerId = partnerId;
  }

  get partnerId() {
    return this._partnerId;
  }

  get advertId() {
    return this._props.advertId;
  }
  get integrationId() {
    return this._props.integrationId;
  }
  get mediaId() {
    return this._props.mediaId;
  }
  get timestamp() {
    if (this.changes.timestamp) throw Error('Is timestampField');
    return this._props.timestamp;
  }
}
export { Impression };
export type { ImpressionProps, NewImpressionProps };
