import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Advert, AdvertProps, toAdvert } from '../../../models/Advert';
import { advertRepo } from '../../../repo';

const useUpdateAdvertName = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isBusy, setIsBusy] = useState(false);
  const action = async (advert: Advert, name: string) => {
    try {
      setIsBusy(true);
      const changed = toAdvert(advert.partnerId, advert.props as AdvertProps, advert.id);
      changed.name = name;
      await advertRepo.update(changed);
      enqueueSnackbar(t('advert.action.changeName.successMessage'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('advert.action.changeName.successMessage'), { variant: 'error' });
    } finally {
      setIsBusy(false);
    }
  };

  return { isBusy, action };
};
export default useUpdateAdvertName;
