import { useEffect, useState } from 'react';

const useRenderSafe = <T>(value?: T) => {
  const [v, setV] = useState<T>();

  useEffect(() => {
    if (v !== value) setV(value);
  }, [value, v]);

  return v;
};
export default useRenderSafe;
