import { Chip, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { FC } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '0.8em',
    marginLeft: '0.2em',
    height: 'auto',
    color: theme.palette.common.white,
    pointerEvents: 'none',
    alignSelf: 'center',
  },
  positive: {
    backgroundColor: theme.palette.success.light,
  },
  negative: {
    backgroundColor: theme.palette.error.light,
  },
  neutral: {
    backgroundColor: theme.palette.warning.light,
  },
}));

export interface TagProps {
  type?: 'positive' | 'negative' | 'neutral';
}

const Tag: FC<TagProps> = ({ children, type = 'neutral' }) => {
  const styles = useStyles();
  return (
    <Chip
      size="small"
      label={children}
      className={clsx(styles.root, {
        [styles.positive]: type === 'positive',
        [styles.negative]: type === 'negative',
        [styles.neutral]: type === 'neutral',
      })}
    />
  );
};
export default Tag;
