import { LinkType } from '../enum';
import { LinkBase, LinkBaseProps, NewLinkBaseProps } from '../Link.base';

interface AdvertIntegrationLinkProps extends LinkBaseProps {
  type: LinkType.ADVERT_INTEGRATION;
  advertId: string;
  integrationId: string;
}

interface NewAdvertIntegrationLinkProps extends NewLinkBaseProps {
  advertId: string;
  integrationId: string;
}

class AdvertIntegrationLink extends LinkBase<AdvertIntegrationLinkProps> {
  static new(partnerId: string, props: NewAdvertIntegrationLinkProps) {
    return new AdvertIntegrationLink(partnerId, {
      type: LinkType.ADVERT_INTEGRATION,
      ...super.New(props),
    });
  }

  get type() {
    return this._props.type;
  }
  get advertId() {
    return this._props.advertId;
  }
  get integrationId() {
    return this._props.integrationId;
  }
}

export { AdvertIntegrationLink };
export type { AdvertIntegrationLinkProps, NewAdvertIntegrationLinkProps };
