import { IntegrationType } from './enum';
import { TapioIntegration } from './spec';

function newIntegration(type: IntegrationType): typeof TapioIntegration.new;
function newIntegration(type: IntegrationType) {
  switch (type) {
    case IntegrationType.TAPIO:
      return TapioIntegration.new;

    default:
      throw new Error('Unknown implementation');
  }
}

export { newIntegration };
