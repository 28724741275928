import { Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import { createContext, FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../../components/common/group.button/PrimaryButton';
import TertiaryButton from '../../components/common/group.button/TertiaryButton';

interface ConfirmationState {
  action: () => void | Promise<void>;
  message: string;
  confirmButton: string;
  cancelButton: string;
}

export const ConfirmationContext = createContext<(options: Partial<ConfirmationState>) => void>(
  undefined as any,
);
export const useConfirm = () => useContext(ConfirmationContext);
export const ConfirmationCtxProvider: FC = ({ children }) => {
  const { t } = useTranslation();
  const [state, setState] = useState<ConfirmationState>();

  const confirm = (options: Partial<ConfirmationState>) => {
    const _options: ConfirmationState = {
      action: () => {},
      message: t('general.confirm-question'),
      confirmButton: t('button.confirm'),
      cancelButton: t('button.cancel'),
      ...options,
    };
    setState(_options);
  };
  const close = () => {
    setState(undefined);
  };

  return (
    <ConfirmationContext.Provider value={confirm}>
      <Dialog open={Boolean(state)} onClose={() => setState(undefined)}>
        <DialogTitle>{state?.message}</DialogTitle>
        <DialogActions>
          <PrimaryButton
            onClick={async () => {
              await state?.action();
              close();
            }}
          >
            {state?.confirmButton}
          </PrimaryButton>
          <TertiaryButton onClick={close}>{state?.cancelButton}</TertiaryButton>
        </DialogActions>
      </Dialog>
      {children}
    </ConfirmationContext.Provider>
  );
};
