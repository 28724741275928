import DataModel from '../DataModel';
import { PartnerLinkStatus, PartnerLinkType } from './enum';

interface PartnerLinkBaseProps {
  type: PartnerLinkType;
  status: PartnerLinkStatus;
  isActive: boolean;
}

interface NewPartnerLinkBaseProps {
  isActive?: boolean;
}

abstract class PartnerLinkBase<P extends PartnerLinkBaseProps> extends DataModel<P> {
  protected static New<P extends NewPartnerLinkBaseProps>(props: P) {
    return {
      isActive: false,
      status: PartnerLinkStatus.PENDING,
      ...props,
    };
  }

  get type() {
    return this._props.type;
  }
  get isActive() {
    return this._props.isActive;
  }
  get status() {
    return this._props.status;
  }

  accept() {
    this.propChange(PartnerLinkStatus.ACCEPTED, 'status');
  }
  refuse() {
    this.propChange(PartnerLinkStatus.REFUSED, 'status');
  }

  set isActive(v) {
    this.propChange(v, 'isActive');
  }
}

export { PartnerLinkBase };
export type { PartnerLinkBaseProps, NewPartnerLinkBaseProps };
