import { LinkType } from '../enum';
import { LinkBase, LinkBaseProps, NewLinkBaseProps } from '../Link.base';

interface AdvertPartnerLinkProps extends LinkBaseProps {
  type: LinkType.ADVERT_PARTNER;
  advertId: string;
  linkedPartnerId: string;
}

interface NewAdvertPartnerLinkProps extends NewLinkBaseProps {
  advertId: string;
  linkedPartnerId: string;
}

class AdvertPartnerLink extends LinkBase<AdvertPartnerLinkProps> {
  static new(partnerId: string, props: NewAdvertPartnerLinkProps) {
    return new AdvertPartnerLink(partnerId, {
      type: LinkType.ADVERT_PARTNER,
      ...super.New(props),
    });
  }

  get type() {
    return this._props.type;
  }
  get advertId() {
    return this._props.advertId;
  }
  get linkedPartnerId() {
    return this._props.linkedPartnerId;
  }
}

export { AdvertPartnerLink };
export type { AdvertPartnerLinkProps, NewAdvertPartnerLinkProps };
