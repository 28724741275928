import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  makeStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import useRemoveAdvert from '../../hooks/action/advert/useRemoveAdvert';
import useUpdateAdvertActive from '../../hooks/action/advert/useUpdateAdvertActive';
import useUpdateAdvertName from '../../hooks/action/advert/useUpdateAdvertName';
import { useConfirm } from '../../hooks/context/useConfirm';
import { usePartnerCtx } from '../../hooks/context/usePartner';
import { Advert } from '../../models/Advert';
import { PartnerPermission } from '../../models/Partner/enum';
import EditableTypography from '../common/EditableTypography';
import TertiaryButton from '../common/group.button/TertiaryButton';
import Tag from '../common/Tag';
import AdvertIntegrationTable from './AdvertIntegrationTable';
import AdvertMediaTable from './AdvertMediaTable';
import AdvertPartnerTable from './AdvertPartnerTable';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    gap: theme.spacing(4),
  },
  deleteAdvertButton: {
    color: theme.palette.error.main,
  },
  disableAdvertButton: {
    color: theme.palette.error.main,
  },
  enableAdvertButton: {
    color: theme.palette.success.main,
  },

  fill: {
    flex: 1,
  },
  fillRow: {
    flex: 1,

    display: 'flex',
    flexDirection: 'row',

    gap: theme.spacing(4),
  },
}));

export interface AdvertAccordionProps {
  advert: Advert;
}

const AdvertAccordion: VFC<AdvertAccordionProps> = ({ advert }) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const { partner } = usePartnerCtx();

  const confirm = useConfirm();
  const removeAdvert = useRemoveAdvert();
  const updateAdvertName = useUpdateAdvertName();
  const updateAdvertActive = useUpdateAdvertActive();

  return (
    <Accordion TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <EditableTypography onEdit={(value) => updateAdvertName.action(advert, value)}>
          {advert.name}
        </EditableTypography>
        <Box flex={1} />
        {!advert.isActive ? (
          <Tag type="negative">{t('advert.tag.disabled')}</Tag>
        ) : (
          <Tag type="positive">{t('advert.tag.enabled')}</Tag>
        )}
      </AccordionSummary>
      <AccordionDetails className={styles.content}>
        <AdvertMediaTable advert={advert} />
        <div className={styles.fillRow}>
          {partner.hasPermission(PartnerPermission.PARTNERSHIP_OWNER) && (
            <AdvertIntegrationTable advert={advert} />
          )}
          {partner.hasPermission(PartnerPermission.PARTNERSHIP_MEMBER) && (
            <AdvertPartnerTable advert={advert} />
          )}
        </div>
      </AccordionDetails>
      <AccordionActions>
        <TertiaryButton
          className={styles.deleteAdvertButton}
          onClick={() => {
            confirm({
              message: t('advert.action.delete.question'),
              confirmButton: t('advert.action.delete.confirm'),
              cancelButton: t('advert.action.delete.cancel'),
              action: () => removeAdvert.action(advert),
            });
          }}
        >
          {t('advert.action.delete.button')}
        </TertiaryButton>
        {advert.isActive ? (
          <TertiaryButton
            className={styles.disableAdvertButton}
            onClick={() => updateAdvertActive.action(advert, false)}
          >
            {t('advert.action.disable.disableButton')}
          </TertiaryButton>
        ) : (
          <TertiaryButton
            className={styles.enableAdvertButton}
            onClick={() => updateAdvertActive.action(advert, true)}
          >
            {t('advert.action.disable.enableButton')}
          </TertiaryButton>
        )}
        <Box flex={1} />
      </AccordionActions>
    </Accordion>
  );
};
export default AdvertAccordion;
