import {
  Divider,
  Fade,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import { Dispatch, SetStateAction, useEffect, VFC } from 'react';
import { useTranslation } from 'react-i18next';
import { usePartnerCtx } from '../../hooks/context/usePartner';
import usePartnerIncomingPartners from '../../hooks/data/partner/usePartnerIncomingPartners';
import { Partner } from '../../models/Partner';
import { PartnerLinkStatus } from '../../models/PartnerLink';
import Tag from '../common/Tag';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,

    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    padding: theme.spacing(1),

    maxHeight: '100%',
  },
  divider: {
    margin: theme.spacing(1),
  },
}));

export interface IncomingPartnerListProps {
  selectedPartner?: Partner;
  setSelectedPartner: Dispatch<SetStateAction<Partner | undefined>>;
}

const IncomingPartnerList: VFC<IncomingPartnerListProps> = ({
  selectedPartner,
  setSelectedPartner,
}) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const { partner } = usePartnerCtx();
  const { incomingPartners } = usePartnerIncomingPartners(partner);
  const acceptedIncomingPartners = incomingPartners.filter(
    (p) => p.link.status === PartnerLinkStatus.ACCEPTED,
  );

  useEffect(() => {
    if (selectedPartner?.id === partner.id) return;
    const existsInList = acceptedIncomingPartners.find(
      (p) => selectedPartner && p.partner && p.partner.id === selectedPartner.id,
    );
    if (!existsInList) setSelectedPartner(partner);
  }, [acceptedIncomingPartners, partner, selectedPartner, setSelectedPartner]);

  if (acceptedIncomingPartners.length <= 0) return null;
  return (
    <Fade in>
      <Paper className={styles.root}>
        <Typography variant="h6">{t('partnership.incoming-list')}</Typography>
        <List>
          <ListItem
            button
            onClick={(e) => {
              setSelectedPartner(partner);
            }}
            selected={selectedPartner?.id === partner.id}
          >
            <ListItemText>{partner.name}</ListItemText>
          </ListItem>
          <Divider className={styles.divider} />
          {acceptedIncomingPartners.map((incomingPartner) => (
            <ListItem
              key={incomingPartner.partner?.id}
              button
              onClick={(e) => setSelectedPartner(incomingPartner.partner)}
              selected={selectedPartner?.id === incomingPartner.partner?.id}
            >
              <ListItemText>
                {incomingPartner.partner?.name}
                {!incomingPartner.link.isActive ? (
                  <Tag type="negative">{t('partnership.tag.disabled')}</Tag>
                ) : (
                  <Tag type="positive">{t('partnership.tag.enabled')}</Tag>
                )}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Paper>
    </Fade>
  );
};

export default IncomingPartnerList;
