import { useState } from 'react';
import { Integration } from '../../../models/Integration';
import { integrationRepo } from '../../../repo';

const useRemoveIntegration = () => {
  const [isBusy, setIsBusy] = useState(false);
  const action = async (integration: Integration) => {
    try {
      setIsBusy(true);
      await integrationRepo.remove(integration);
    } catch (error) {
    } finally {
      setIsBusy(false);
    }
  };

  return { isBusy, action };
};
export default useRemoveIntegration;
