import fb from 'firebase/app';
import COLLECTION from '../constants/collections';
import { Impression, ImpressionProps, toImpression } from '../models/Impression';
import { OnDataFunction } from '../types/general';
import { defined, notDefined } from '../utils';
import { FirestoreRepo } from './FirestoreRepo';

class ImpressionRepo extends FirestoreRepo {
  private root(partnerId: string): fb.firestore.CollectionReference<ImpressionProps>;
  private root(partnerId: string) {
    return this.fs.collection(COLLECTION.PARTNER).doc(partnerId).collection(COLLECTION.IMPRESSION);
  }

  async getImpression(partnerId: string, id: string) {
    const snap = await this.root(partnerId).doc(id).get();
    return this.snapToImpression(partnerId, snap);
  }
  async getImpressions(partnerId: string, ids: string[]) {
    return this.clearUndefined(
      await Promise.all(ids.map(async (id) => await this.getImpression(partnerId, id))),
    );
  }

  async create(impression: Impression) {
    notDefined(impression.id, 'Impression.id');
    const result = await this.root(impression.partnerId).add(impression.props);
    return result.id;
  }
  async update(impression: Impression) {
    await this.root(impression.partnerId).doc(defined(impression.id)).update(impression.changes);
  }
  async remove(impression: Impression) {
    await this.root(impression.partnerId).doc(defined(impression.id)).delete();
  }

  onImpression(
    partnerId: string,
    impressionId: string,
    onData: OnDataFunction<Impression | undefined>,
  ) {
    const unsubscribe = this.root(partnerId)
      .doc(impressionId)
      .onSnapshot(
        (snap) => {
          const impression = this.snapToImpression(partnerId, snap);
          onData(impression, unsubscribe);
        },
        (error) => {
          console.error(error);
          onData(undefined, unsubscribe);
        },
      );
    return unsubscribe;
  }
  onImpressions(partnerId: string, onData: OnDataFunction<Impression[]>) {
    const unsubscribe = this.root(partnerId).onSnapshot(
      (snap) => {
        const impressions = this.snapToImpressions(partnerId, snap);
        onData(impressions, unsubscribe);
      },
      (error) => {
        console.error(error);
        onData([], unsubscribe);
      },
    );
    return unsubscribe;
  }

  onMediaImpressions(partnerId: string, mediaId: string, onData: OnDataFunction<Impression[]>) {
    const unsubscribe = this.filteredQuery(this.root(partnerId), { mediaId }).onSnapshot(
      (snap) => {
        const impressions = this.snapToImpressions(partnerId, snap);
        onData(impressions, unsubscribe);
      },
      (error) => {
        console.error(error);
        onData([], unsubscribe);
      },
    );
    return unsubscribe;
  }
  onAdvertImpressions(partnerId: string, advertId: string, onData: OnDataFunction<Impression[]>) {
    const unsubscribe = this.filteredQuery(this.root(partnerId), { advertId }).onSnapshot(
      (snap) => {
        const impressions = this.snapToImpressions(partnerId, snap);
        onData(impressions, unsubscribe);
      },
      (error) => {
        console.error(error);
        onData([], unsubscribe);
      },
    );
    return unsubscribe;
  }
  onIntegrationImpressions(
    partnerId: string,
    integrationId: string,
    onData: OnDataFunction<Impression[]>,
  ) {
    const unsubscribe = this.filteredQuery(this.root(partnerId), { integrationId }).onSnapshot(
      (snap) => {
        const impressions = this.snapToImpressions(partnerId, snap);
        onData(impressions, unsubscribe);
      },
      (error) => {
        console.error(error);
        onData([], unsubscribe);
      },
    );
    return unsubscribe;
  }

  private snapToImpression(
    partnerId: string,
    snap: fb.firestore.DocumentSnapshot<ImpressionProps>,
  ) {
    const data = snap.data();
    if (!data) return undefined;
    return toImpression(partnerId, data, snap.id);
  }
  private snapToImpressions(partnerId: string, snap: fb.firestore.QuerySnapshot<ImpressionProps>) {
    return this.clearUndefined(snap.docs.map((snap) => this.snapToImpression(partnerId, snap)));
  }
}

export { ImpressionRepo };
