import { useState } from 'react';
import { Advert, AdvertProps, toAdvert } from '../../../models/Advert';
import { advertRepo } from '../../../repo';

const useUpdateAdvertActive = () => {
  const [isBusy, setIsBusy] = useState(false);
  const action = async (advert: Advert, status: boolean) => {
    try {
      setIsBusy(true);
      const changed = toAdvert(advert.partnerId, advert.props as AdvertProps, advert.id);
      changed.isActive = status;
      await advertRepo.update(changed);
    } catch (error) {
    } finally {
      setIsBusy(false);
    }
  };

  return { isBusy, action };
};
export default useUpdateAdvertActive;
