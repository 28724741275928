import DataModel from '../DataModel';
import { PartnerPermission } from './enum';

interface PartnerProps {
  name: string;
  readonly permissions: PartnerPermission[];
}
interface NewPartnerProps {
  name: string;
  permissions: PartnerPermission[];
}

class Partner extends DataModel<PartnerProps> {
  static new(props: NewPartnerProps) {
    return new Partner({ ...props });
  }

  get name() {
    return this._props.name;
  }

  hasPermission(permission: PartnerPermission) {
    return this._props.permissions.includes(permission);
  }
}
export { Partner };
export type { PartnerProps, NewPartnerProps };
