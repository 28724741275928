import { useState } from 'react';
import { Media } from '../../../models/Media';
import { mediaRepo } from '../../../repo';

const useRemoveMedia = () => {
  const [isBusy, setIsBusy] = useState(false);
  const action = async (media: Media) => {
    try {
      setIsBusy(true);
      await mediaRepo.remove(media);
    } catch (error) {
    } finally {
      setIsBusy(false);
    }
  };

  return { isBusy, action };
};
export default useRemoveMedia;
