import { IconButton, IconButtonProps, makeStyles } from '@material-ui/core';
import RefuseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { VFC } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.error.light,
  },
}));

const RefuseIconButton: VFC<IconButtonProps> = ({ ...IconButtonProps }) => {
  const styles = useStyles();

  return (
    <IconButton {...IconButtonProps} className={clsx(styles.root, IconButtonProps.className)}>
      <RefuseIcon />
    </IconButton>
  );
};
export default RefuseIconButton;
