import DataModel from '../DataModel';
import { IntegrationStatus, IntegrationType } from './enum';

interface IntegrationBaseProps {
  partnerId: string;
  type: IntegrationType;
  status: IntegrationStatus;
  key?: string;
  externalKey: string;
  label?: string;
  isActive: boolean;
}

interface NewIntegrationBaseProps {
  partnerId: string;
  externalKey: string;
  isActive?: boolean;
}

abstract class IntegrationBase<P extends IntegrationBaseProps> extends DataModel<P> {
  protected static New<P extends NewIntegrationBaseProps>(props: P) {
    return {
      status: IntegrationStatus.LOADING,
      isActive: false,
      ...props,
    };
  }

  get partnerId() {
    return this._props.partnerId;
  }
  get type() {
    return this._props.type;
  }
  get status() {
    return this._props.status;
  }
  get key() {
    return this._props.key;
  }
  get externalKey() {
    return this._props.externalKey;
  }
  get label() {
    return this._props.label;
  }
  get isActive() {
    return this._props.isActive;
  }

  set key(v) {
    if (this.key) throw new Error('This integration already has a key');
    this.propChange(v, 'key');
  }
  set label(v) {
    this.propChange(v, 'label');
  }
  set isActive(v) {
    this.propChange(v, 'isActive');
  }

  link(externalKey: string, label: string) {
    if (this.isLinked) throw new Error('Integration already linked');
    this.propChange(label, 'label');
    this.propChange(externalKey, 'externalKey');
    this.propChange(IntegrationStatus.LINKED, 'status');
  }
  unlink() {
    if (this.isUnlinked) throw new Error('Integration is not linked');
    this.propChange(IntegrationStatus.UNLINKED, 'status');
  }

  get isLoading() {
    return this.status === IntegrationStatus.LOADING;
  }
  get isLinked() {
    return this.status === IntegrationStatus.LINKED;
  }
  get isUnlinked() {
    return this.status === IntegrationStatus.UNLINKED;
  }
}

export { IntegrationBase };
export type { IntegrationBaseProps, NewIntegrationBaseProps };
