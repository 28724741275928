import { MediaType } from '../enum';
import { MediaBase, MediaBaseProps, NewMediaBaseProps } from '../Media.base';

interface VideoMediaProps extends MediaBaseProps {
  type: MediaType.VIDEO;
}

interface NewVideoMediaProps extends NewMediaBaseProps {}

class VideoMedia extends MediaBase<VideoMediaProps> {
  static new(partnerId: string, props: NewVideoMediaProps) {
    return new VideoMedia(partnerId, {
      type: MediaType.VIDEO,
      ...super.New(props),
    });
  }
}

export { VideoMedia };
export type { VideoMediaProps, NewVideoMediaProps };
