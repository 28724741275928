import DataModel from '../DataModel';
import { LinkType } from './enum';

interface LinkBaseProps {
  type: LinkType;
  isActive: boolean;
}

interface NewLinkBaseProps {
  isActive?: boolean;
}

abstract class LinkBase<P extends LinkBaseProps> extends DataModel<P> {
  protected static New<P extends NewLinkBaseProps>(props: P) {
    return {
      isActive: false,
      ...props,
    };
  }

  protected _partnerId;
  constructor(partnerId: string, props: P, id?: string) {
    super(props, id);
    this._partnerId = partnerId;
  }

  get partnerId() {
    return this._partnerId;
  }

  get type() {
    return this._props.type;
  }
  get isActive() {
    return this._props.isActive;
  }

  set isActive(v) {
    this.propChange(v, 'isActive');
  }
}

export { LinkBase };
export type { LinkBaseProps, NewLinkBaseProps };
