import { useState } from 'react';
import { PartnerLink, toPartnerLink } from '../../../models/PartnerLink';
import { partnerLinkRepo } from '../../../repo';

const useUpdatePartnerLinkStatus = () => {
  const [isBusy, setIsBusy] = useState(false);
  const action = async (accept: boolean, partnerLink: PartnerLink) => {
    try {
      setIsBusy(true);
      const changed = toPartnerLink(partnerLink.props, partnerLink.id);
      if (accept) changed.accept();
      else changed.refuse();
      await partnerLinkRepo.update(changed);
    } catch (error) {
    } finally {
      setIsBusy(false);
    }
  };

  return { isBusy, action };
};
export default useUpdatePartnerLinkStatus;
