import { useState } from 'react';
import { PartnerLink, toPartnerLink } from '../../../models/PartnerLink';
import { partnerLinkRepo } from '../../../repo';

const useUpdatePartnerLinkActive = () => {
  const [isBusy, setIsBusy] = useState(false);
  const action = async (partnerLink: PartnerLink, status: boolean) => {
    try {
      setIsBusy(true);
      const changed = toPartnerLink(partnerLink.props, partnerLink.id);
      changed.isActive = status;
      await partnerLinkRepo.update(changed);
    } catch (error) {
    } finally {
      setIsBusy(false);
    }
  };

  return { isBusy, action };
};
export default useUpdatePartnerLinkActive;
