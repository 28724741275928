import { useEffect, useMemo, useState } from 'react';
import { Advert } from '../../../models/Advert';
import { AdvertPartnerLink } from '../../../models/Link';
import { Partner } from '../../../models/Partner';
import { advertRepo, linkRepo } from '../../../repo';
import { defined } from '../../../utils';
import useRenderSafe from '../../useRenderSafe';

export interface PartnerExternalAdvert {
  advert: Advert | undefined;
  link: AdvertPartnerLink;
}

const usePartnerExternalAdverts = (
  partner?: Partner,
  extPartner?: Partner,
  linksOnly?: boolean,
) => {
  const partnerId = useRenderSafe(partner?.id);
  const extPartnerId = useRenderSafe(extPartner?.id);
  const [advertPartnerLinks, setAdvertPartnerLinks] = useState<AdvertPartnerLink[]>();
  const [externalAdvertsData, setExternalAdvertsData] = useState<{
    [linkId: string]: {
      link: AdvertPartnerLink;
      advert?: Advert;
      unsubscribe?: () => void;
    };
  }>({});

  useEffect(() => {
    try {
      if (!partnerId || !extPartnerId) return setAdvertPartnerLinks(undefined);
      return linkRepo.onAdvertPartnerLinksByLinkedPartner(
        extPartnerId,
        partnerId,
        (advertPartnerLinks) => setAdvertPartnerLinks(advertPartnerLinks),
      );
    } catch (error) {
      setAdvertPartnerLinks(undefined);
    }
  }, [extPartnerId, partnerId]);

  useEffect(() => {
    if (linksOnly) setExternalAdvertsData({});
    if (!partnerId || !extPartnerId)
      return setExternalAdvertsData((data) => {
        Object.entries(data).forEach(([, { unsubscribe }]) => {
          if (unsubscribe) unsubscribe();
        });
        return {};
      });
    setExternalAdvertsData((data) => {
      //Add new and update existing
      Object.keys(data).forEach((linkId) => {
        if (!advertPartnerLinks?.some((p) => p.id === linkId)) {
          const unsubscribe = data[linkId].unsubscribe;
          if (unsubscribe) unsubscribe();
          delete data[linkId];
        }
      });
      //Remove old
      advertPartnerLinks?.forEach((advertPartnerLink) => {
        if (!advertPartnerLink.id) return;
        if (!data[advertPartnerLink.id]) {
          data[advertPartnerLink.id] = {
            link: advertPartnerLink,
          };
          const unsubscribe = advertRepo.onAdvert(
            extPartnerId,
            advertPartnerLink.advertId,
            (advert) => {
              setExternalAdvertsData((old) => ({
                ...old,
                [defined(advertPartnerLink.id)]: {
                  ...old[defined(advertPartnerLink.id)],
                  advert,
                  unsubscribe,
                },
              }));
            },
          );
        } else {
          data[advertPartnerLink.id].link = advertPartnerLink;
        }
      });
      return data;
    });

    return () => {
      setExternalAdvertsData((data) => {
        Object.entries(data).forEach(([, { unsubscribe }]) => {
          if (unsubscribe) unsubscribe();
        });
        return {};
      });
    };
  }, [advertPartnerLinks, partnerId, linksOnly, extPartnerId]);

  const externalAdverts: PartnerExternalAdvert[] = useMemo(
    () =>
      Object.values(externalAdvertsData)
        .filter((p) => p.advert)
        .map(({ advert, link }) => ({ advert, link })),
    [externalAdvertsData],
  );

  return { advertPartnerLinks, externalAdverts };
};
export default usePartnerExternalAdverts;
