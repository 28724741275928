import { Collapse, createTheme, CssBaseline, ThemeProvider } from '@material-ui/core';
import { Shadows } from '@material-ui/core/styles/shadows';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import PrivateRoute from './components/common/PrivateRoute';
import ROUTES from './constants/routes';
import { AuthProvider } from './hooks/context/useAuth';
import { ConfirmationCtxProvider } from './hooks/context/useConfirm';
import PartnerPage from './pages/PartnerPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import SignInPage from './pages/SignInPage';
import TestPage from './pages/TestPage';
import './utils/i18n';

const theme = createTheme({
  shadows: Array<string>(25).fill('none') as Shadows,
  palette: {
    primary: {
      main: '#13CDD4',
      contrastText: '#F5F5F5',
    },
    secondary: {
      main: '#F5F5F5',
      contrastText: '#1A1A1A',
    },
    background: {
      paper: '#FFFFFF',
      default: '#FAFAFA',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ConfirmationCtxProvider>
        <SnackbarProvider maxSnack={3} TransitionComponent={Collapse}>
          <CssBaseline />
          <BrowserRouter>
            <AuthProvider>
              <Switch>
                <Route exact path="/test" component={TestPage} />
                <Route exact path={ROUTES.SIGN_IN} component={SignInPage} />
                <Route exact path={ROUTES.FORGOT_PASSWORD} component={ResetPasswordPage} />
                <PrivateRoute path={ROUTES.PARTNER} component={PartnerPage} />
                <Redirect from="/" to={ROUTES.PARTNER} />
              </Switch>
            </AuthProvider>
          </BrowserRouter>
        </SnackbarProvider>
      </ConfirmationCtxProvider>
    </ThemeProvider>
  );
}

export default App;
