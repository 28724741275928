import { useEffect, useState } from 'react';
import { Advert } from '../../../models/Advert';
import { Partner } from '../../../models/Partner';
import { advertRepo } from '../../../repo';
import useRenderSafe from '../../useRenderSafe';

const usePartnerAdverts = (partner?: Partner) => {
  const partnerId = useRenderSafe(partner?.id);
  const [adverts, setAdverts] = useState<Advert[]>();

  useEffect(() => {
    try {
      if (!partnerId) return setAdverts(undefined);
      return advertRepo.onAdverts(partnerId, (adverts) => setAdverts(adverts));
    } catch (error) {
      setAdverts(undefined);
    }
  }, [partnerId]);

  return adverts;
};
export default usePartnerAdverts;
