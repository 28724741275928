import Crop75Icon from '@material-ui/icons/Crop75';
import CropLandscapeIcon from '@material-ui/icons/CropLandscape';
import CropPortraitIcon from '@material-ui/icons/CropPortrait';
import CropSquareIcon from '@material-ui/icons/CropSquare';
import { FC } from 'react';
import { Media, SizeType } from '../../models/Media';

export interface MediaSizeIndicatorProps {
  media: Media;
}

const MediaSizeIndicator: FC<MediaSizeIndicatorProps> = ({ media }) => {
  switch (media.size) {
    case SizeType.STRIP:
      return <Crop75Icon />;
    case SizeType.LANDSCAPE:
      return <CropLandscapeIcon />;
    case SizeType.SQUARE:
      return <CropSquareIcon />;
    case SizeType.PORTRAIT:
      return <CropPortraitIcon />;

    default:
      return null;
  }
};
export default MediaSizeIndicator;
