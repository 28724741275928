import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  TextField,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { useEffect, useState, VFC } from 'react';
import { useTranslation } from 'react-i18next';
import useCreateAdvertMediaLink from '../hooks/action/link/useCreateAdvertMediaLink';
import useRemoveLink from '../hooks/action/link/useRemoveLink';
import useMediaCreateDialog from '../hooks/context/useMediaCreateDialog';
import { usePartnerCtx } from '../hooks/context/usePartner';
import useAdvert from '../hooks/data/advert/useAdvert';
import useAdvertMedias from '../hooks/data/advert/useAdvertMedias';
import usePartnerMedias from '../hooks/data/partner/usePartnerMedias';
import PrimaryButton from './common/group.button/PrimaryButton';
import TertiaryButton from './common/group.button/TertiaryButton';
import Image from './common/Image';
import MediaSizeIndicator from './common/MediaSizeIndicator';
import MediaTypeIndicator from './common/MediaTypeIndicator';
import Tag from './common/Tag';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    overflowY: 'auto',
  },
  main: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    overflowY: 'auto',
  },
  mediaList: {
    flex: 2,

    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  image: {
    flex: 3,

    display: 'flex',
    flexDirection: 'column',

    maxHeight: '100%',
    overflowY: 'auto',
  },
  listIcons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  bar: {
    overflow: 'visible',
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    padding: theme.spacing(1),
  },
  createLinkButton: {
    backgroundColor: theme.palette.success.main,
  },
  removeLinkButton: {
    backgroundColor: theme.palette.error.main,
  },

  fill: {
    flex: 1,
  },
}));

export interface AddAdvertMediaDialogProps {
  isOpen: boolean;
  onClose: () => void;
  advertId?: string;
}

const AddAdvertMediaDialog: VFC<AddAdvertMediaDialogProps> = ({ isOpen, onClose, advertId }) => {
  const { t } = useTranslation();
  const styles = useStyles();

  const { partner } = usePartnerCtx();
  const advert = useAdvert(partner, advertId);
  const partnerMedias = usePartnerMedias(partner);
  const { advertMediaLinks } = useAdvertMedias(advert, true);

  const [mediaLabelFilter, setMediaLabelFilter] = useState('');
  const [selectedMediaId, setSelectedMediaId] = useState<string>();
  const selectedMedia = partnerMedias?.find((p) => p.media.id === selectedMediaId);

  const mediaCreateDialog = useMediaCreateDialog();
  const createAdvertMediaLink = useCreateAdvertMediaLink();
  const removeLink = useRemoveLink();

  useEffect(() => {
    if (!selectedMediaId && partnerMedias) setSelectedMediaId(partnerMedias[0]?.media.id);
  }, [partnerMedias, selectedMediaId]);

  if (!advert) return null;
  return (
    <Dialog open={isOpen} maxWidth="lg" fullWidth>
      <DialogTitle>{t('advert.media.add-dialog-header')}</DialogTitle>
      <DialogContent className={styles.content}>
        <Paper className={styles.bar}>
          <TextField
            type="search"
            placeholder={t('global.search')}
            margin="none"
            size="small"
            variant="outlined"
            className={styles.fill}
            value={mediaLabelFilter}
            onChange={(e) => setMediaLabelFilter(e.target.value)}
          />

          <PrimaryButton onClick={() => mediaCreateDialog.open({ advertId })}>
            {t('media.action.create.button')}
          </PrimaryButton>
        </Paper>

        <div className={styles.main}>
          <Paper className={styles.mediaList}>
            <List>
              {partnerMedias
                ?.filter((p) => p.media.name.toLowerCase().includes(mediaLabelFilter.toLowerCase()))
                .map((partnerMedia) => {
                  const link = advertMediaLinks?.find((p) => p.mediaId === partnerMedia.media.id);
                  return (
                    <ListItem
                      key={partnerMedia.media.id}
                      button
                      onClick={() => setSelectedMediaId(partnerMedia.media.id)}
                      selected={selectedMediaId === partnerMedia.media.id}
                    >
                      <ListItemText>
                        {partnerMedia.media.name}
                        {partnerMedia.media.isActive ? (
                          <Tag type="positive">{t('media.tag.enabled')}</Tag>
                        ) : (
                          <Tag type="negative">{t('media.tag.disabled')}</Tag>
                        )}
                      </ListItemText>
                      <ListItemIcon>
                        <div className={styles.listIcons}>
                          <MediaTypeIndicator media={partnerMedia.media} />
                          <MediaSizeIndicator media={partnerMedia.media} />
                        </div>
                      </ListItemIcon>
                      <ListItemSecondaryAction>
                        {link ? (
                          <IconButton
                            size="small"
                            className={styles.removeLinkButton}
                            onClick={async () => {
                              await removeLink.action(link);
                            }}
                          >
                            <RemoveIcon />
                          </IconButton>
                        ) : (
                          <IconButton
                            size="small"
                            className={styles.createLinkButton}
                            onClick={async () => {
                              await createAdvertMediaLink.action(advert, partnerMedia.media);
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                        )}
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
            </List>
          </Paper>
          <Paper className={styles.image}>
            <Image
              alt={selectedMedia?.media.name}
              backgroundColor={selectedMedia?.media.backgroundColor}
              url={selectedMedia?.mediaUrl}
            />
          </Paper>
        </div>
      </DialogContent>
      <DialogActions>
        <TertiaryButton onClick={() => onClose()}>{t('global.close')}</TertiaryButton>
      </DialogActions>
    </Dialog>
  );
};
export default AddAdvertMediaDialog;
