import { makeStyles, Toolbar } from '@material-ui/core';
import { FC } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
}));

interface SliderLayoutContentProps {}

const SliderLayoutContent: FC<SliderLayoutContentProps> = ({ children }) => {
  const styles = useStyles();

  return (
    <main className={styles.root}>
      <Toolbar />
      {children}
    </main>
  );
};
export default SliderLayoutContent;
