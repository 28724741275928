import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Media, toMedia } from '../../../models/Media';
import { mediaRepo } from '../../../repo';

const useUpdateMediaLabel = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isBusy, setIsBusy] = useState(false);
  const action = async (media: Media, label: string) => {
    try {
      setIsBusy(true);
      const changed = toMedia(media.partnerId, media.props, media.id);
      changed.name = label;
      await mediaRepo.update(changed);
      enqueueSnackbar(t('media.action.changeLabel.successMessage'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('media.action.changeLabel.successMessage'), { variant: 'error' });
    } finally {
      setIsBusy(false);
    }
  };

  return { isBusy, action };
};
export default useUpdateMediaLabel;
