import fb from 'firebase/app';
import COLLECTION from '../constants/collections';
import { Partner, PartnerProps, toPartner } from '../models/Partner';
import { OnDataFunction } from '../types/general';
import { defined, notDefined } from '../utils';
import { FirestoreRepo } from './FirestoreRepo';

class PartnerRepo extends FirestoreRepo {
  private root(): fb.firestore.CollectionReference<PartnerProps>;
  private root() {
    return this.fs.collection(COLLECTION.PARTNER);
  }

  async getPartner(id: string) {
    const snap = await this.root().doc(id).get();
    return this.snapToPartner(snap);
  }
  async getPartners(ids: string[]) {
    return this.clearUndefined(await Promise.all(ids.map(async (id) => await this.getPartner(id))));
  }

  async create(partner: Partner) {
    notDefined(partner.id, 'Partner.id');
    const result = await this.root().add(partner.props as any);
    return result.id;
  }
  async update(partner: Partner) {
    await this.root().doc(defined(partner.id)).update(partner.changes);
  }
  async remove(partner: Partner) {
    await this.root().doc(defined(partner.id)).delete();
  }

  onPartner(partnerId: string, onData: OnDataFunction<Partner | undefined>) {
    const unsubscribe = this.root()
      .doc(partnerId)
      .onSnapshot(
        (snap) => {
          const partner = this.snapToPartner(snap);
          onData(partner, unsubscribe);
        },
        (error) => {
          console.error(error);
          onData(undefined, unsubscribe);
        },
      );
    return unsubscribe;
  }
  onPartners(onData: OnDataFunction<Partner[]>) {
    const unsubscribe = this.root().onSnapshot(
      (snap) => {
        const partners = this.snapToPartners(snap);
        onData(partners, unsubscribe);
      },
      (error) => {
        console.error(error);
        onData([], unsubscribe);
      },
    );
    return unsubscribe;
  }

  private snapToPartner(snap: fb.firestore.DocumentSnapshot<PartnerProps>) {
    const data = snap.data();
    if (!data) return undefined;
    return toPartner(data, snap.id);
  }
  private snapToPartners(snap: fb.firestore.QuerySnapshot<PartnerProps>) {
    return this.clearUndefined(snap.docs.map((snap) => this.snapToPartner(snap)));
  }
}

export { PartnerRepo };
