import {
  Button,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LocalBarIcon from '@material-ui/icons/LocalBar';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import PeopleIcon from '@material-ui/icons/People';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import PersonIcon from '@material-ui/icons/Person';
import { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch as RouteSwitch, useHistory } from 'react-router-dom';
import AddAdvertMediaDialog from '../components/AddAdvertMediaDialog';
import AdvertArea from '../components/AdvertArea/AdvertArea';
import AdvertCreateWizard from '../components/AdvertCreateWizard/AdvertCreateWizard';
import SliderLayout from '../components/common/SliderLayout/SliderLayout';
import SliderLayoutContent from '../components/common/SliderLayout/SliderLayoutContent';
import SliderLayoutDrawer from '../components/common/SliderLayout/SliderLayoutDrawer';
import SliderLayoutHeader from '../components/common/SliderLayout/SliderLayoutHeader';
import IntegrationDialog from '../components/IntegrationDialog';
import MediaArea from '../components/MediaArea/MediaArea';
import MediaCreateDialog from '../components/MediaCreateDialog';
import MediaUpdateDialog from '../components/MediaUpdateDialog';
import PartnerDialog from '../components/PartnerDialog';
import ROUTES from '../constants/routes';
import { useAuth } from '../hooks/context/useAuth';
import { GlobalDialogCtxProvider } from '../hooks/context/useGlobalDialog';
import { PartnerCtxProvider } from '../hooks/context/usePartner';
import useCurrentPartner from '../hooks/data/useCurrentPartner';
import useMember from '../hooks/data/useMember';
import { PartnerPermission } from '../models/Partner/enum';

const PartnerPage: VFC = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const member = useMember(auth?.currentUser);
  const partner = useCurrentPartner(member);
  const history = useHistory();

  return (
    <PartnerCtxProvider>
      <GlobalDialogCtxProvider
        render={(isOpen, data, open, close) => {
          return (
            <>
              <SliderLayout>
                <SliderLayoutHeader title={partner?.name}>
                  <Button
                    startIcon={<PeopleIcon />}
                    color="inherit"
                    onClick={() => open('partner')}
                  >
                    <Typography variant="subtitle1">{t('partnership.title')}</Typography>
                  </Button>
                  {partner?.hasPermission(PartnerPermission.PARTNERSHIP_OWNER) && (
                    <Button
                      startIcon={<LocalBarIcon />}
                      color="inherit"
                      onClick={() => open('integration')}
                    >
                      <Typography variant="subtitle1">{t('integration.title')}</Typography>
                    </Button>
                  )}
                  {/* <IconButton color="inherit" onClick={() => open('partner')}>
                    <PeopleIcon />
                  </IconButton> */}
                  {/* <IconButton color="inherit" onClick={() => open('integration')}>
                    <LocalBarIcon />
                  </IconButton> */}
                </SliderLayoutHeader>
                <SliderLayoutDrawer
                  render={({ isDrawerOpen }) => (
                    <>
                      {isDrawerOpen && (
                        <>
                          <ListItem>
                            <ListItemIcon>
                              <PersonIcon />
                            </ListItemIcon>
                            <ListItemText>{auth.currentUser?.email}</ListItemText>
                          </ListItem>
                          <Divider />
                        </>
                      )}
                      <ListItem
                        selected={history.location.pathname === ROUTES.ADVERTS}
                        button
                        onClick={() => history.push(ROUTES.ADVERTS)}
                      >
                        <ListItemIcon>
                          <LoyaltyIcon />
                        </ListItemIcon>
                        <ListItemText>{t('advert.page-header')}</ListItemText>
                      </ListItem>
                      <ListItem
                        selected={history.location.pathname === ROUTES.MEDIA}
                        button
                        onClick={() => history.push(ROUTES.MEDIA)}
                      >
                        <ListItemIcon>
                          <PermMediaIcon />
                        </ListItemIcon>
                        <ListItemText>{t('media.page-header')}</ListItemText>
                      </ListItem>

                      <Divider />

                      <ListItem button onClick={() => auth.signOut()}>
                        <ListItemIcon>
                          <ExitToAppIcon />
                        </ListItemIcon>
                        <ListItemText>{t('account.logout')}</ListItemText>
                      </ListItem>
                    </>
                  )}
                />
                <SliderLayoutContent>
                  <RouteSwitch>
                    <Route path={ROUTES.ADVERTS} component={AdvertArea} />
                    <Route path={ROUTES.MEDIA} component={MediaArea} />
                    <Redirect from={`${ROUTES.PARTNER}/`} to={ROUTES.ADVERTS} />
                  </RouteSwitch>
                </SliderLayoutContent>
              </SliderLayout>
              <AdvertCreateWizard
                isOpen={isOpen('advertCreateWizard')}
                onClose={() => close('advertCreateWizard')}
                {...data('advertCreateWizard')}
              />
              <MediaCreateDialog
                isOpen={isOpen('mediaCreate')}
                onClose={() => close('mediaCreate')}
                {...data('mediaCreate')}
              />
              <MediaUpdateDialog
                isOpen={isOpen('mediaUpdate')}
                onClose={() => close('mediaUpdate')}
                {...data('mediaUpdate')}
              />
              <AddAdvertMediaDialog
                isOpen={isOpen('addAdvertMedia')}
                onClose={() => close('addAdvertMedia')}
                {...data('addAdvertMedia')}
              />
              <IntegrationDialog
                isOpen={isOpen('integration')}
                onClose={() => close('integration')}
                {...data('integration')}
              />
              <PartnerDialog
                isOpen={isOpen('partner')}
                onClose={() => close('partner')}
                {...data('partner')}
              />
            </>
          );
        }}
      />
    </PartnerCtxProvider>
  );
};
export default PartnerPage;
