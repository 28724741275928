import fb from 'firebase/app';
import COLLECTION from '../constants/collections';
import {
  Integration,
  IntegrationProps,
  IntegrationStatus,
  IntegrationType,
  TapioIntegration,
  TapioIntegrationProps,
  toIntegration,
} from '../models/Integration';
import { OnDataFunction } from '../types/general';
import { defined, notDefined, single } from '../utils';
import { FirestoreRepo } from './FirestoreRepo';

class IntegrationRepo extends FirestoreRepo {
  private root(
    type: IntegrationType.TAPIO,
  ): fb.firestore.CollectionReference<TapioIntegrationProps>;
  private root(): fb.firestore.CollectionReference<IntegrationProps>;
  private root(type?: IntegrationType) {
    const root = this.fs.collection(COLLECTION.INTEGRATION);
    if (!type) return root;
    return this.filteredQuery(root, { type });
  }

  async getTapioIntegrationByKey(key: string) {
    const snap = await this.filteredQuery(this.root(IntegrationType.TAPIO), { key }).get();
    return single(this.snapToIntegrations(snap), 'tapioIntegration (key)');
  }
  async getIntegration(id: string) {
    const snap = await this.root().doc(id).get();
    return this.snapToIntegration(snap);
  }
  async getIntegrations(ids: string[]) {
    return this.clearUndefined(
      await Promise.all(ids.map(async (id) => await this.getIntegration(id))),
    );
  }

  async create(integration: Integration) {
    notDefined(integration.id, 'Integration.id');
    const result = await this.root().add(integration.props);
    return result.id;
  }
  async update(integration: Integration) {
    await this.root().doc(defined(integration.id)).update(integration.changes);
  }
  async remove(integration: Integration) {
    await this.root().doc(defined(integration.id)).delete();
  }

  onIntegration(integrationId: string, onData: OnDataFunction<Integration | undefined>) {
    const unsubscribe = this.root()
      .doc(integrationId)
      .onSnapshot(
        (snap) => {
          const integration = this.snapToIntegration(snap);
          onData(integration, unsubscribe);
        },
        (error) => {
          console.error(error);
          onData(undefined, unsubscribe);
        },
      );
    return unsubscribe;
  }
  onIntegrations(onData: OnDataFunction<Integration[]>) {
    const unsubscribe = this.root().onSnapshot(
      (snap) => {
        const integrations = this.snapToIntegrations(snap);
        onData(integrations, unsubscribe);
      },
      (error) => {
        console.error(error);
        onData([], unsubscribe);
      },
    );
    return unsubscribe;
  }
  onPartnerIntegrations(
    partnerId: string,
    onData: OnDataFunction<Integration[]>,
    filter?: { status?: IntegrationStatus },
  ) {
    const unsubscribe = this.filteredQuery(this.root(), {
      partnerId,
      ...filter,
    }).onSnapshot(
      (snap) => {
        const integrations = this.snapToIntegrations(snap);
        onData(integrations, unsubscribe);
      },
      (error) => {
        console.error(error);
        onData([], unsubscribe);
      },
    );
    return unsubscribe;
  }

  private snapToIntegration(
    snap: fb.firestore.DocumentSnapshot<TapioIntegrationProps>,
  ): TapioIntegration | undefined;
  private snapToIntegration(
    snap: fb.firestore.DocumentSnapshot<IntegrationProps>,
  ): Integration | undefined;
  private snapToIntegration(snap: fb.firestore.DocumentSnapshot<IntegrationProps>) {
    const data = snap.data();
    if (!data) return undefined;
    return toIntegration(data, snap.id);
  }
  private snapToIntegrations(
    snap: fb.firestore.QuerySnapshot<TapioIntegrationProps>,
  ): TapioIntegration[];
  private snapToIntegrations(snap: fb.firestore.QuerySnapshot<IntegrationProps>): Integration[];
  private snapToIntegrations(snap: fb.firestore.QuerySnapshot<IntegrationProps>) {
    return this.clearUndefined(snap.docs.map((snap) => this.snapToIntegration(snap)));
  }
}

export { IntegrationRepo };
