import { IconButton, IconButtonProps, makeStyles } from '@material-ui/core';
import AcceptIcon from '@material-ui/icons/Done';
import clsx from 'clsx';
import { VFC } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.success.light,
  },
}));

const AcceptIconButton: VFC<IconButtonProps> = ({ ...IconButtonProps }) => {
  const styles = useStyles();

  return (
    <IconButton {...IconButtonProps} className={clsx(styles.root, IconButtonProps.className)}>
      <AcceptIcon />
    </IconButton>
  );
};
export default AcceptIconButton;
