import ImageIcon from '@material-ui/icons/Image';
import VideoIcon from '@material-ui/icons/Movie';
import { FC } from 'react';
import { Media, MediaType } from '../../models/Media';

export interface MediaTypeIndicatorProps {
  media: Media;
}

const MediaTypeIndicator: FC<MediaTypeIndicatorProps> = ({ media }) => {
  switch (media.type) {
    case MediaType.IMAGE:
      return <ImageIcon />;
    case MediaType.VIDEO:
      return <VideoIcon />;

    default:
      return null;
  }
};
export default MediaTypeIndicator;
