import { firestore, storage } from '../firebase';
import { AdvertRepo } from './AdvertRepo';
import { ImpressionRepo } from './ImpressionRepo';
import { IntegrationRepo } from './IntegrationRepo';
import { LinkRepo } from './LinkRepo';
import { MediaRepo } from './MediaRepo';
import { MediaStorageRepo } from './MediaStorageRepo';
import { MemberRepo } from './MemberRepo';
import { PartnerLinkRepo } from './PartnerLinkRepo';
import { PartnerRepo } from './PartnerRepo';

export const advertRepo = new AdvertRepo(firestore);
export const impressionRepo = new ImpressionRepo(firestore);
export const integrationRepo = new IntegrationRepo(firestore);
export const linkRepo = new LinkRepo(firestore);
export const mediaRepo = new MediaRepo(firestore);
export const memberRepo = new MemberRepo(firestore);
export const partnerRepo = new PartnerRepo(firestore);
export const partnerLinkRepo = new PartnerLinkRepo(firestore);
export const mediaStorageRepo = new MediaStorageRepo(storage);

export type {
  AdvertRepo,
  ImpressionRepo,
  IntegrationRepo,
  LinkRepo,
  MediaRepo,
  MemberRepo,
  PartnerRepo,
  PartnerLinkRepo,
  MediaStorageRepo,
};
