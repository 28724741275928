import { useEffect, useState } from 'react';
import { Member } from '../../models/Member';
import { Partner } from '../../models/Partner';
import { partnerRepo } from '../../repo';

const useCurrentPartner = (member?: Member) => {
  const [partnerId, setPartnerId] = useState<string>();
  const [partner, setPartner] = useState<Partner>();

  useEffect(() => {
    try {
      if (member?.partnerId !== partnerId) setPartnerId(member?.partnerId);
    } catch (error) {
      console.error(error);
    }
  }, [member, partnerId]);

  useEffect(() => {
    try {
      if (!partnerId) return;
      const unsubscribe = partnerRepo.onPartner(partnerId, (partner) => {
        setPartner(partner);
      });

      return unsubscribe;
    } catch (error) {
      console.error(error);
    }
  }, [partnerId]);

  return partner;
};
export default useCurrentPartner;
