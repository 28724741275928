import { useFormik } from 'formik';
import { Color, toColor } from 'react-color-palette';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { FILE_SIZE, SUPPORTED_FORMATS } from '../../constants/media';
import { Advert } from '../../models/Advert';
import { Partner } from '../../models/Partner';
import useCreateAdvertMediaLink from '../action/link/useCreateAdvertMediaLink';
import useCreateMedia from '../action/media/useCreateMedia';

export interface useCreateMediaFormConfig {
  partner?: Partner;
  advert?: Advert;
  onSuccess?: () => Promise<void> | void;
}

const useCreateMediaForm = ({ partner, advert, onSuccess }: useCreateMediaFormConfig) => {
  const { t } = useTranslation();

  const createMedia = useCreateMedia();
  const createAdvertMediaLink = useCreateAdvertMediaLink();

  const formik = useFormik<{
    label: string;
    backgroundColor: Color;
    file?: File;
  }>({
    initialValues: {
      label: '',
      backgroundColor: toColor('hex', '#000000'),
      file: undefined,
    },
    onSubmit: async ({ label, backgroundColor, file }) => {
      if (!label || !backgroundColor || !file || !partner) return;
      const media = await createMedia.action(partner, {
        name: label,
        backgroundColor: backgroundColor.hex,
        file,
      });
      if (onSuccess) onSuccess();
      formik.resetForm();
      if (!media || !advert) return;
      await createAdvertMediaLink.action(advert, media);
    },
    validationSchema: yup.object({
      label: yup
        .string()
        .required(t('media.validation.name-required'))
        .min(3, t('media.validation.name-length')),
      file: yup
        .mixed<File>()
        .required(t('media.action.changeFile.validation.file-required'))
        .test(
          'fileSize',
          t('media.action.changeFile.validation.file-size'),
          (file) => !file || file.size <= FILE_SIZE,
        )
        .test(
          'fileFormat',
          t('media.action.changeFile.validation.file-format'),
          (file) => !file || SUPPORTED_FORMATS.includes(file.type),
        ),
    }),
  });

  return formik;
};
export default useCreateMediaForm;

//TODO validate file
// const validateFile = async (v: File, changed: boolean) => {
//   try {
//     await yup
//       .mixed<File>()
//       .test('fileExist', t('media.validation.file-required'), (file) =>
//         Boolean((media?.fileName && !changed) || (file && changed)),
//       )
//       .test(
//         'fileSize',
//         t('media.validation.file-size'),
//         (file) => !file || file.size <= FILE_SIZE,
//       )
//       .test(
//         'fileFormat',
//         t('media.validation.file-format'),
//         (file) => !file || SUPPORTED_FORMATS.includes(file.type),
//       )
//       .validate(v);
//   } catch (err) {
//     return err.errors;
//   }
// };
