import { useCallback, useEffect, useState } from 'react';
import { Media } from '../../../models/Media';
import { mediaStorageRepo } from '../../../repo';

const useMediaUrl = (media?: Media) => {
  const [url, setUrl] = useState<string>();

  const refresh = useCallback(async () => {
    try {
      if (!media) return setUrl(undefined);
      const url = await mediaStorageRepo.getReadUrl(media);
      setUrl(url);
    } catch (error) {
      console.error(error);
    }
  }, [media]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return { value: url, refresh };
};
export default useMediaUrl;
