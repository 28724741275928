import { IntegrationType } from '../enum';
import {
  IntegrationBase,
  IntegrationBaseProps,
  NewIntegrationBaseProps,
} from '../Integration.base';

interface TapioIntegrationProps extends IntegrationBaseProps {
  type: IntegrationType.TAPIO;
}

interface NewTapioIntegrationProps extends NewIntegrationBaseProps {}

class TapioIntegration extends IntegrationBase<TapioIntegrationProps> {
  static new(props: NewTapioIntegrationProps) {
    return new TapioIntegration({
      type: IntegrationType.TAPIO,
      ...super.New(props),
    });
  }

  get type() {
    return this._props.type;
  }
}

export { TapioIntegration };
export type { TapioIntegrationProps, NewTapioIntegrationProps };
