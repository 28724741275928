import { makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { VFC } from 'react';

const useStyles = makeStyles<Theme, { color: string }, 'img'>((theme) => ({
  img: {
    pointerEvents: 'none',
    minWidth: '100%',
    maxWidth: '100%',
    borderStyle: 'solid',
    borderWidth: '1vw',
    borderColor: ({ color }) => color,
    backgroundColor: ({ color }) => color,
  },
}));
export interface ImageProps {
  className?: string;
  alt?: string;
  url?: string;
  backgroundColor?: string;
}
const Image: VFC<ImageProps> = ({ className, alt, url = '', backgroundColor = '#000000' }) => {
  const styles = useStyles({ color: backgroundColor });
  return <img className={clsx(styles.img, className)} alt={alt} src={url || '/img/noImage.jpg'} />;
};
export default Image;
