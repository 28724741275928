import {
  Fade,
  makeStyles,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import useCreateAdvertIntegrationLink from '../../hooks/action/link/useCreateAdvertIntegrationLink';
import useUpdateLinkActive from '../../hooks/action/link/useUpdateLinkActive';
import { usePartnerCtx } from '../../hooks/context/usePartner';
import useAdvertIntegrations from '../../hooks/data/advert/useAdvertIntegrations';
import usePartnerIntegrations from '../../hooks/data/partner/usePartnerIntegrations';
import { Advert } from '../../models/Advert';
import SmallTableCell from '../common/SmallTableCell';
import Tag from '../common/Tag';

const useStyles = makeStyles((theme) => ({
  fill: {
    flex: 1,
  },
}));

export interface AdvertIntegrationTableProps {
  advert: Advert;
}

const AdvertIntegrationTable: VFC<AdvertIntegrationTableProps> = ({ advert }) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const { partner } = usePartnerCtx();

  const partnerIntegrations = usePartnerIntegrations(partner);
  const { advertIntegrationLinks } = useAdvertIntegrations(advert, true);

  const createAdvertIntegrationLink = useCreateAdvertIntegrationLink();
  const updateLinkActive = useUpdateLinkActive();

  return (
    <Fade in>
      <div className={styles.fill}>
        <Typography variant="subtitle1" gutterBottom>
          <strong>{t('advert.integration.title')}</strong>
        </Typography>
        <TableContainer>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell>{t('integration.name')}</TableCell>
                <SmallTableCell>{t('advert.integration.status')}</SmallTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {partnerIntegrations && partnerIntegrations.length <= 0 ? (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Typography align="center">
                      <i>{t('partner.integration.no-integration-internal')}</i>
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                partnerIntegrations?.map((integration) => {
                  const link = advertIntegrationLinks?.find(
                    (p) => p.integrationId === integration.id,
                  );
                  return (
                    <TableRow key={integration.id}>
                      <TableCell>
                        {integration.label}
                        {!integration.isActive && (
                          <Tag type="negative">{t('integration.tag.disabled')}</Tag>
                        )}
                        {!integration.isLinked && (
                          <Tag type="negative">{t('integration.tag.not-linked')}</Tag>
                        )}
                      </TableCell>
                      <SmallTableCell>
                        <Switch
                          color="primary"
                          checked={link?.isActive || false}
                          onChange={(e, checked) => {
                            if (link) updateLinkActive.action(link, checked);
                            else createAdvertIntegrationLink.action(advert, integration);
                          }}
                          disabled={!integration.isLinked}
                        />
                      </SmallTableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Fade>
  );
};
export default AdvertIntegrationTable;
