import { IconButton, IconButtonProps } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { VFC } from 'react';

const CloseIconButton: VFC<IconButtonProps> = ({ ...IconButtonProps }) => {
  return (
    <IconButton {...IconButtonProps}>
      <CloseIcon />
    </IconButton>
  );
};
export default CloseIconButton;
