import fb from 'firebase/app';
import { createContext, FC, useContext, useEffect, useState } from 'react';
import { auth as firebaseAuth } from '../../firebase';

interface AuthContextProps {
  currentUser: fb.User | null | undefined;
  signUp: (email: string, password: string) => Promise<fb.auth.UserCredential>;
  signIn: (email: string, password: string) => Promise<fb.auth.UserCredential>;
  signOut: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
}

export const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);
export const useAuth = () => useContext(AuthContext);
export const AuthProvider: FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState<fb.User | null>();

  useEffect(() => {
    try {
      setIsLoading(true);
      const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
        setCurrentUser(user);
        setIsLoading(false);
      });

      return unsubscribe;
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        signUp: (email: string, password: string) =>
          firebaseAuth.createUserWithEmailAndPassword(email, password),
        signIn: (email: string, password: string) =>
          firebaseAuth.signInWithEmailAndPassword(email, password),
        signOut: () => firebaseAuth.signOut(),
        resetPassword: (email: string) => firebaseAuth.sendPasswordResetEmail(email),
      }}
    >
      {!isLoading && children}
    </AuthContext.Provider>
  );
};
