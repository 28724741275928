import { Box, BoxProps, makeStyles, TextField } from '@material-ui/core';
import clsx from 'clsx';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ROUTES from '../../constants/routes';
import formikToInput from '../../utils/formikToInput';
import formikToSubmit from '../../utils/formikToSubmit';
import PrimaryButton from '../common/group.button/PrimaryButton';
import LinkButton from '../common/LinkButton';
import useResetPasswordForm from './hooks/useResetPasswordForm';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  spacing: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

interface ResetPasswordAreaProps extends BoxProps {}

const ResetPasswordArea: FC<ResetPasswordAreaProps> = ({ className, ...BoxProps }) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const resetPasswordForm = useResetPasswordForm(() => history.push(ROUTES.SIGN_IN));

  return (
    <Box className={clsx(styles.root, className)} {...BoxProps}>
      <form>
        <TextField
          fullWidth
          margin="normal"
          label={t('account.email')}
          {...formikToInput(resetPasswordForm, 'email')}
        />
        <PrimaryButton
          className={styles.spacing}
          fullWidth
          variant="contained"
          color="primary"
          {...formikToSubmit(resetPasswordForm)}
        >
          {t('account.reset-password')}
        </PrimaryButton>
      </form>
      <LinkButton fullWidth to={ROUTES.SIGN_IN}>
        {t('account.to-login')}
      </LinkButton>
    </Box>
  );
};
export default ResetPasswordArea;
