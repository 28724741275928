import { MediaType } from './enum';
import { Media, MediaProps } from './Media';
import { ImageMedia, ImageMediaProps, VideoMedia, VideoMediaProps } from './spec';

function toMedia(partnerId: string, props: VideoMediaProps, id?: string): VideoMedia;
function toMedia(partnerId: string, props: ImageMediaProps, id?: string): ImageMedia;
function toMedia(partnerId: string, props: MediaProps, id?: string): Media;
function toMedia(partnerId: string, props: MediaProps, id?: string): Media {
  switch (props.type) {
    case MediaType.IMAGE:
      return new ImageMedia(partnerId, props, id);
    case MediaType.VIDEO:
      return new VideoMedia(partnerId, props, id);

    default:
      throw new Error('Unknown implementation');
  }
}

export { toMedia };
