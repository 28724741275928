import { useState } from 'react';
import { Link } from '../../../models/Link';
import { linkRepo } from '../../../repo';

const useRemoveLink = () => {
  const [isBusy, setIsBusy] = useState(false);
  const action = async (link: Link) => {
    try {
      setIsBusy(true);
      await linkRepo.remove(link);
    } catch (error) {
    } finally {
      setIsBusy(false);
    }
  };

  return { isBusy, action };
};
export default useRemoveLink;
