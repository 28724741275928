import { FieldValue, incrementField, timeStampField } from '../../firebase';
import DataModel from '../DataModel';
import { MediaType, SizeType } from '../Media';

type MediaInfo = {
  [T in MediaType]?: {
    [S in SizeType]?: number | FieldValue;
  };
};
type IntegrationInfo = string[];

interface AdvertProps {
  name: string;
  isActive: boolean;
  integrationInfo: IntegrationInfo;
  mediaInfo: MediaInfo;
  createdAt: Date | FieldValue;
  updatedAt: Date | FieldValue;
}
interface NewAdvertProps {
  name: string;
  isActive?: boolean;
}

class Advert extends DataModel<AdvertProps> {
  static new(partnerId: string, props: NewAdvertProps) {
    return new Advert(partnerId, {
      isActive: false,
      integrationInfo: [],
      mediaInfo: {
        image: {
          landscape: 0,
          lane: 0,
          portrait: 0,
          square: 0,
          strip: 0,
        },
        video: {
          landscape: 0,
          lane: 0,
          portrait: 0,
          square: 0,
          strip: 0,
        },
      },
      createdAt: timeStampField(),
      updatedAt: timeStampField(),
      ...props,
    });
  }

  protected _partnerId;
  constructor(partnerId: string, props: AdvertProps, id?: string) {
    super(props, id);
    this._partnerId = partnerId;
  }

  get partnerId() {
    return this._partnerId;
  }

  get name() {
    return this._props.name;
  }
  get isActive() {
    return this._props.isActive;
  }
  get integrationInfo() {
    return this._props.integrationInfo;
  }
  get mediaInfo() {
    return this._props.mediaInfo;
  }
  get createdAt() {
    if (this.changes.createdAt) throw Error('Is timestampField');
    return this._props.createdAt;
  }
  get updatedAt() {
    if (this.changes.updatedAt) throw Error('Is timestampField');
    return this._props.updatedAt;
  }

  set name(v) {
    this.propChange(v, 'name');
  }
  set isActive(v) {
    this.propChange(v, 'isActive');
  }

  addMediaInfo(type: MediaType, size: SizeType) {
    this.propChange(incrementField(1), 'mediaInfo', type, size as any);
  }
  removeMediaInfo(type: MediaType, size: SizeType) {
    this.propChange(incrementField(-1), 'mediaInfo', type, size as any);
  }

  addIntegrationInfo(key: string) {
    const newInfo = [...this.integrationInfo];
    newInfo.push(key);
    this.propChange(newInfo, 'integrationInfo');
  }
  removeIntegrationInfo(key: string) {
    const idx = this.integrationInfo.findIndex((p) => p === key);
    const newValue = [...this.integrationInfo];
    newValue.splice(idx);
    this.propChange(newValue, 'integrationInfo');
  }

  protected propChange<
    K1 extends keyof AdvertProps,
    K2 extends keyof AdvertProps[K1],
    K3 extends keyof AdvertProps[K1][K2],
  >(v: any, k1: K1, k2?: K2, k3?: K3) {
    if (!super.propChange(v, k1, k2, k3)) return false;
    super.propChange(timeStampField(), 'updatedAt');
    return true;
  }
}
export { Advert };
export type { AdvertProps, NewAdvertProps };
