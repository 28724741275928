import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemText,
  makeStyles,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core';
import { useState, VFC } from 'react';
import { useTranslation } from 'react-i18next';
import useRemovePartnerLink from '../hooks/action/partnerLink/useRemovePartnerLink';
import useUpdatePartnerLinkActive from '../hooks/action/partnerLink/useUpdatePartnerLinkActive';
import useUpdatePartnerLinkStatus from '../hooks/action/partnerLink/useUpdatePartnerLinkStatus';
import { useConfirm } from '../hooks/context/useConfirm';
import { usePartnerCtx } from '../hooks/context/usePartner';
import usePartnerIncomingPartners from '../hooks/data/partner/usePartnerIncomingPartners';
import usePartnerOutgoingPartners from '../hooks/data/partner/usePartnerOutgoingPartners';
import useCreateOutgoingPartnerForm from '../hooks/form/useCreateOutgoingPartnerForm';
import { PartnerPermission } from '../models/Partner/enum';
import { PartnerLinkStatus } from '../models/PartnerLink';
import AcceptIconButton from './common/group.button/AcceptIconButton';
import PrimaryButton from './common/group.button/PrimaryButton';
import RefuseIconButton from './common/group.button/RefuseIconButton';
import TertiaryButton from './common/group.button/TertiaryButton';
import SmallTableCell from './common/SmallTableCell';
import Tag from './common/Tag';
import Form from './Form';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    overflowY: 'auto',
  },
  form: {
    overflow: 'visible',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: theme.spacing(1),

    height: 'auto',
  },
  deleteIntegrationButton: {
    color: theme.palette.error.main,
  },
  disableIntegrationButton: {
    color: theme.palette.error.main,
  },
  enableIntegrationButton: {
    color: theme.palette.success.main,
  },

  fill: {
    flex: 1,
  },
}));

export interface PartnerDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const PartnerDialog: VFC<PartnerDialogProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const styles = useStyles();

  const { partner } = usePartnerCtx();
  const { incomingPartners } = usePartnerIncomingPartners(partner);
  const { outgoingPartners } = usePartnerOutgoingPartners(partner);

  const acceptedIncomingPartners = incomingPartners.filter(
    (p) => p.link.status === PartnerLinkStatus.ACCEPTED,
  );
  const pendingIncomingPartners = incomingPartners.filter(
    (p) => p.link.status === PartnerLinkStatus.PENDING,
  );

  const createOutgoingPartnerForm = useCreateOutgoingPartnerForm({
    partner,
    onSuccess: () => {},
  });

  const confirm = useConfirm();
  const removePartnerLink = useRemovePartnerLink();
  const updatePartnerLinkActive = useUpdatePartnerLinkActive();
  const updatePartnerLinkStatus = useUpdatePartnerLinkStatus();

  const [tab, setTab] = useState(
    partner.hasPermission(PartnerPermission.PARTNERSHIP_OWNER) ? 0 : 1,
  );

  return (
    <Dialog open={isOpen} maxWidth="md" fullWidth>
      <DialogTitle>{t('partnership.dialog-header')}</DialogTitle>
      <DialogContent className={styles.content}>
        <Typography>{t('partnership.dialog-info')}</Typography>
        <Tabs value={tab} onChange={(e, tab) => setTab(tab)} indicatorColor="primary">
          {partner.hasPermission(PartnerPermission.PARTNERSHIP_OWNER) && (
            <Tab label={t('partnership.incoming')} value={0} />
          )}
          {partner.hasPermission(PartnerPermission.PARTNERSHIP_MEMBER) && (
            <Tab label={t('partnership.outgoing')} value={1} />
          )}
          {partner.hasPermission(PartnerPermission.PARTNERSHIP_OWNER) && (
            <Tab
              label={
                <span>
                  {t('partnership.requests')}
                  {pendingIncomingPartners.length > 0 && (
                    <Chip size="small" label={pendingIncomingPartners.length} />
                  )}
                </span>
              }
              value={2}
              disabled={!partner.hasPermission(PartnerPermission.PARTNERSHIP_OWNER)}
            />
          )}
        </Tabs>
        {tab === 0 && (
          <>
            <TableContainer>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('partner.name')}</TableCell>
                    <SmallTableCell />
                    <SmallTableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {acceptedIncomingPartners.map(
                    (incomingPartner) =>
                      incomingPartner.partner && (
                        <TableRow key={incomingPartner.partner.id}>
                          <TableCell>
                            <ListItemText secondary={incomingPartner.partner.id}>
                              {incomingPartner.partner.name}
                              {incomingPartner.link.isActive ? (
                                <Tag type="positive">{t('partnership.tag.enabled')}</Tag>
                              ) : (
                                <Tag type="negative">{t('partnership.tag.disabled')}</Tag>
                              )}
                            </ListItemText>
                          </TableCell>
                          <SmallTableCell>
                            {incomingPartner.link.isActive ? (
                              <TertiaryButton
                                className={styles.disableIntegrationButton}
                                onClick={() =>
                                  updatePartnerLinkActive.action(incomingPartner.link, false)
                                }
                              >
                                {t('partnership.action.disable.disableButton')}
                              </TertiaryButton>
                            ) : (
                              <TertiaryButton
                                className={styles.enableIntegrationButton}
                                onClick={() =>
                                  updatePartnerLinkActive.action(incomingPartner.link, true)
                                }
                              >
                                {t('partnership.action.disable.enableButton')}
                              </TertiaryButton>
                            )}
                          </SmallTableCell>
                          <SmallTableCell>
                            <TertiaryButton
                              className={styles.deleteIntegrationButton}
                              onClick={() => {
                                confirm({
                                  message: t('partnership.action.delete.question'),
                                  confirmButton: t('partnership.action.delete.confirm'),
                                  cancelButton: t('partnership.action.delete.cancel'),
                                  action: () =>
                                    updatePartnerLinkStatus.action(false, incomingPartner.link),
                                });
                              }}
                            >
                              {t('partnership.action.delete.button')}
                            </TertiaryButton>
                          </SmallTableCell>
                        </TableRow>
                      ),
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
        {tab === 1 && (
          <>
            <Form className={styles.form} form={createOutgoingPartnerForm}>
              <TextField
                margin="none"
                size="small"
                variant="outlined"
                className={styles.fill}
                autoFocus
                label={t('partner.key')}
                value={createOutgoingPartnerForm.values.key}
                onChange={(e) => {
                  createOutgoingPartnerForm.setFieldValue('key', e.target.value);
                }}
                error={Boolean(createOutgoingPartnerForm.errors.key)}
              />
              <PrimaryButton type="submit">{t('partnership.action.create.button')}</PrimaryButton>
            </Form>
            <TableContainer>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('partner.name')}</TableCell>
                    <SmallTableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {outgoingPartners.map(
                    (outgoing) =>
                      outgoing.partner && (
                        <TableRow key={outgoing.partner.id}>
                          <TableCell>
                            <ListItemText secondary={outgoing.partner.id}>
                              {outgoing.partner.name}
                              {outgoing.link.status === PartnerLinkStatus.ACCEPTED && (
                                <>
                                  <Tag type="positive">{t('partnership.tag.accepted')}</Tag>
                                  {outgoing.link.isActive ? (
                                    <Tag type="positive">{t('partnership.tag.enabled')}</Tag>
                                  ) : (
                                    <Tag type="negative">{t('partnership.tag.disabled')}</Tag>
                                  )}
                                </>
                              )}
                              {outgoing.link.status === PartnerLinkStatus.REFUSED && (
                                <Tag type="negative">{t('partnership.tag.refused')}</Tag>
                              )}
                              {outgoing.link.status === PartnerLinkStatus.PENDING && (
                                <Tag type="neutral">{t('partnership.tag.pending')}</Tag>
                              )}
                            </ListItemText>
                          </TableCell>
                          <SmallTableCell>
                            {outgoing.link.status === PartnerLinkStatus.ACCEPTED ? (
                              <TertiaryButton
                                className={styles.deleteIntegrationButton}
                                onClick={() => {
                                  confirm({
                                    message: t('partnership.action.delete.question'),
                                    confirmButton: t('partnership.action.delete.confirm'),
                                    cancelButton: t('partnership.action.delete.cancel'),
                                    action: () => removePartnerLink.action(outgoing.link),
                                  });
                                }}
                              >
                                {t('partnership.action.delete.button')}
                              </TertiaryButton>
                            ) : (
                              <TertiaryButton
                                className={styles.deleteIntegrationButton}
                                onClick={() => {
                                  removePartnerLink.action(outgoing.link);
                                }}
                              >
                                {t('partnership.action.delete-request.button')}
                              </TertiaryButton>
                            )}
                          </SmallTableCell>
                        </TableRow>
                      ),
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
        {tab === 2 && (
          <>
            <TableContainer>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('partner.name')}</TableCell>
                    <SmallTableCell>{t('partnership.action.accept.button')}</SmallTableCell>
                    <SmallTableCell>{t('partnership.action.refuse.button')}</SmallTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pendingIncomingPartners.map(
                    (incomingPartner) =>
                      incomingPartner.partner && (
                        <TableRow key={incomingPartner.partner.id}>
                          <TableCell>
                            <ListItemText secondary={incomingPartner.partner.id}>
                              {incomingPartner.partner.name}
                            </ListItemText>
                          </TableCell>
                          <SmallTableCell>
                            <AcceptIconButton
                              onClick={() =>
                                updatePartnerLinkStatus.action(true, incomingPartner.link)
                              }
                            />
                          </SmallTableCell>
                          <SmallTableCell>
                            <RefuseIconButton
                              onClick={() =>
                                updatePartnerLinkStatus.action(false, incomingPartner.link)
                              }
                            />
                          </SmallTableCell>
                        </TableRow>
                      ),
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <TertiaryButton onClick={() => onClose()}>{t('global.close')}</TertiaryButton>
      </DialogActions>
    </Dialog>
  );
};
export default PartnerDialog;
