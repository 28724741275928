import { useState } from 'react';
import { Link, toLink } from '../../../models/Link';
import { linkRepo } from '../../../repo';

const useUpdateLinkActive = () => {
  const [isBusy, setIsBusy] = useState(false);
  const action = async (link: Link, status: boolean) => {
    try {
      setIsBusy(true);
      const changed = toLink(link.partnerId, link.props, link.id);
      changed.isActive = status;
      await linkRepo.update(changed);
    } catch (error) {
    } finally {
      setIsBusy(false);
    }
  };

  return { isBusy, action };
};
export default useUpdateLinkActive;
