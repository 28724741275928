import DataModel from '../DataModel';
import { MemberRole } from './enums/MemberRole';

interface MemberProps {
  partner: {
    id: string;
    isActive: boolean;
    readonly roles: readonly MemberRole[];
  };
}
interface NewMemberProps {
  partner: {
    id: string;
    isActive: boolean;
    roles: MemberRole[];
  };
}

class Member extends DataModel<MemberProps> {
  static new(props: NewMemberProps) {
    return new Member({
      ...props,
    });
  }

  get partnerId() {
    return this._props.partner.id;
  }
  get isActive() {
    return this._props.partner.isActive;
  }

  hasRole(permission: MemberRole) {
    return this._props.partner.roles.includes(permission);
  }
}
export { Member };
export type { MemberProps, NewMemberProps };
