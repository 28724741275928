import { makeStyles, TextField, Typography } from '@material-ui/core';
import { Dispatch, SetStateAction, VFC } from 'react';
import { useTranslation } from 'react-i18next';
import { usePartnerCtx } from '../../hooks/context/usePartner';
import useCreateAdvertForm from '../../hooks/form/useCreateAdvertForm';
import PrimaryButton from '../common/group.button/PrimaryButton';
import Form from '../Form';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(8),
    alignItems: 'center',
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),

    minWidth: 400,
  },
}));

export interface AdvertStepProps {
  setAdvertId: Dispatch<SetStateAction<string | undefined>>;
  nextStep: () => void;
}

const AdvertStep: VFC<AdvertStepProps> = ({ setAdvertId, nextStep }) => {
  const { t } = useTranslation();
  const styles = useStyles();

  const { partner } = usePartnerCtx();
  const createAdvertForm = useCreateAdvertForm({
    partner,
    onSuccess: (advertId) => {
      setAdvertId(advertId);
      nextStep();
    },
  });

  return (
    <div className={styles.root}>
      <div className={styles.info}>
        <Typography variant="h5">
          <strong> {t('advert.action.create.step.advert')}</strong>
        </Typography>
        <Typography>{t('advert.action.create.step.advert-info')}</Typography>
      </div>

      <Form form={createAdvertForm} className={styles.form}>
        <TextField
          autoFocus
          fullWidth
          label={t('advert.name')}
          value={createAdvertForm.values.name}
          onChange={(e) => createAdvertForm.setFieldValue('name', e.target.value)}
          error={Boolean(createAdvertForm.errors.name)}
          helperText={createAdvertForm.errors.name}
        />
        <PrimaryButton fullWidth type="submit">
          {t('advert.action.create.confirmButton')}
        </PrimaryButton>
      </Form>
    </div>
  );
};

export default AdvertStep;
