import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useAuth } from '../../../hooks/context/useAuth';
import { FormikInstance } from '../../../types/Formik';
import { onFunction } from '../../../types/general';

type SignInFormik = FormikInstance<{
  email: string;
  password: string;
}>;

const useSignInForm = (onSuccess?: onFunction, onFail?: onFunction, onFinish?: onFunction) => {
  const { signIn } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const formik: SignInFormik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async ({ email, password }) => {
      try {
        await signIn(email, password);
        enqueueSnackbar(t('account.message.login-succes'), { variant: 'success' });
        formik.resetForm();
        if (onSuccess) onSuccess();
      } catch (error) {
        enqueueSnackbar(t('account.message.login-failed'), { variant: 'error' });
        if (onFail) onFail();
      } finally {
        if (onFinish) onFinish();
      }
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email(t('account.validation.email-invalid'))
        .required(t('account.validation.email-required')),
      password: yup
        .string()
        .min(5, t('account.validation.password-min-length'))
        .required(t('account.validation.password-required')),
    }),
  });

  return formik;
};
export default useSignInForm;
export type { SignInFormik };
