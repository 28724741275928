import { makeStyles, Paper } from '@material-ui/core';
import { VFC } from 'react';
import { useHistory } from 'react-router-dom';
import Background from '../components/account/Background';
import Logo from '../components/account/Logo';
import SignInArea from '../components/account/SignInArea';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100vw',
    height: '100vh',
    padding: '8vw',
  },
  logo: {
    margin: '2vh',
  },
  area: {
    width: '30%',
    minWidth: 300,
  },
}));

const SignInPage: VFC = () => {
  const styles = useStyles();

  const history = useHistory();

  return (
    <Background className={styles.root}>
      <Logo className={styles.logo} />
      <SignInArea onSignIn={() => history.push('/')} component={Paper} className={styles.area} />
    </Background>
  );
};
export default SignInPage;
