import { MediaType } from '../enum';
import { MediaBase, MediaBaseProps, NewMediaBaseProps } from '../Media.base';

interface ImageMediaProps extends MediaBaseProps {
  type: MediaType.IMAGE;
}

interface NewImageMediaProps extends NewMediaBaseProps {}

class ImageMedia extends MediaBase<ImageMediaProps> {
  static new(partnerId: string, props: NewImageMediaProps) {
    return new ImageMedia(partnerId, {
      type: MediaType.IMAGE,
      ...super.New(props),
    });
  }
}

export { ImageMedia };
export type { ImageMediaProps, NewImageMediaProps };
