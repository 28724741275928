import { useState } from 'react';
import { PartnerLink } from '../../../models/PartnerLink';
import { partnerLinkRepo } from '../../../repo';

const useRemovePartnerLink = () => {
  const [isBusy, setIsBusy] = useState(false);
  const action = async (partnerLink: PartnerLink) => {
    try {
      setIsBusy(true);
      await partnerLinkRepo.remove(partnerLink);
    } catch (error) {
    } finally {
      setIsBusy(false);
    }
  };

  return { isBusy, action };
};
export default useRemovePartnerLink;
