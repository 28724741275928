import { makeStyles, Typography } from '@material-ui/core';
import { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import { Advert } from '../../models/Advert';
import AdvertPartnerTable from '../AdvertArea/AdvertPartnerTable';

const useStyles = makeStyles((theme) => ({
  info: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

export interface PartnerStepProps {
  advert: Advert;
}

const PartnerStep: VFC<PartnerStepProps> = ({ advert }) => {
  const { t } = useTranslation();
  const styles = useStyles();

  return (
    <>
      <div className={styles.info}>
        <Typography variant="h5">
          <strong> {t('advert.action.create.step.partner')}</strong>
        </Typography>
        <Typography>{t('advert.action.create.step.partner-info')}</Typography>
      </div>
      <AdvertPartnerTable advert={advert} />
    </>
  );
};

export default PartnerStep;
