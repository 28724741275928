import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  makeStyles,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import { Advert } from '../../models/Advert';
import Tag from '../common/Tag';
import AdvertIntegrationTable from './AdvertIntegrationTable';
import AdvertMediaTable from './AdvertMediaTable';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    gap: theme.spacing(4),
  },

  fill: {
    flex: 1,
  },
}));

export interface ExternalAdvertAccordionProps {
  advert: Advert;
}

const ExternalAdvertAccordion: VFC<ExternalAdvertAccordionProps> = ({ advert }) => {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <Accordion TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{advert?.name}</Typography>
        <Box flex={1} />
        {!advert.isActive ? (
          <Tag type="negative">{t('advert.tag.disabled')}</Tag>
        ) : (
          <Tag type="positive">{t('advert.tag.enabled')}</Tag>
        )}
      </AccordionSummary>
      <AccordionDetails className={styles.content}>
        <AdvertMediaTable advert={advert} />
        <AdvertIntegrationTable advert={advert} />
      </AccordionDetails>
    </Accordion>
  );
};

export default ExternalAdvertAccordion;
