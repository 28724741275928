import { Box, BoxProps, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { FC } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundImage: 'url("/img/background.jpg")',
  },
}));

const Background: FC<BoxProps> = ({ className, children, ...BoxProps }) => {
  const styles = useStyles();
  return <Box className={clsx(styles.root, className)}>{children}</Box>;
};
export default Background;
