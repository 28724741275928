import { Button, ButtonProps, makeStyles, Popover } from '@material-ui/core';
import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { Color, ColorPicker } from 'react-color-palette';
import 'react-color-palette/lib/css/styles.css';

const useStyles = makeStyles((theme) => ({
  color: {
    backgroundColor: (p: { color?: string }) => p.color,
    color: (p: { color: string }) => theme.palette.getContrastText(p.color),
    '&:hover': {
      backgroundColor: (p: { color?: string }) => p.color,
      color: (p: { color: string }) => theme.palette.getContrastText(p.color),
    },
  },
}));
export interface ColorButtonProps extends Omit<ButtonProps, 'value' | 'onChange' | 'onBlur'> {
  value: Color;
  onChange: Dispatch<SetStateAction<Color>>;
  onBlur?: (color: Color) => Promise<void> | void;
}
const ColorButton: FC<ColorButtonProps> = ({ value, onChange, onBlur, ...ButtonProps }) => {
  const [isColorOpen, setIsColorOpen] = useState(false);
  const [width, setWidth] = useState(250);

  const styles = useStyles({ color: value.hex });

  const ref = useRef<any>();

  //For the resizable color picker
  useEffect(() => {
    setWidth(ref.current?.getBoundingClientRect().width);
    window.addEventListener('resize', () => setWidth(ref.current?.getBoundingClientRect().width));
  }, [ref]);

  return (
    <>
      <Button
        {...ButtonProps}
        className={styles.color}
        ref={ref}
        onClick={(e) => {
          setIsColorOpen(true);
          if (ButtonProps.onClick) ButtonProps.onClick(e);
        }}
      />
      <Popover
        open={isColorOpen}
        onClose={() => {
          if (onBlur) onBlur(value);
          setIsColorOpen(false);
        }}
        anchorEl={ref.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
      >
        <ColorPicker
          width={width >= 250 ? width : 250}
          height={150}
          color={value}
          onChange={onChange}
          hideHSV
          hideRGB
        />
      </Popover>
    </>
  );
};
export default ColorButton;
