import {
  Fade,
  makeStyles,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import useUpdateLinkActive from '../../hooks/action/link/useUpdateLinkActive';
import useAddAdvertMediaDialog from '../../hooks/context/useAddAdvertMediaDialog';
import useMediaUpdateDialog from '../../hooks/context/useMediaUpdateDialog';
import { usePartnerCtx } from '../../hooks/context/usePartner';
import useAdvertMedias from '../../hooks/data/advert/useAdvertMedias';
import { Advert } from '../../models/Advert';
import OverlapButton from '../common/group.button/OverlapButton';
import SecondaryButton from '../common/group.button/SecondaryButton';
import MediaPreviewIndicator from '../common/MediaPreviewIndicator';
import MediaSizeIndicator from '../common/MediaSizeIndicator';
import MediaTypeIndicator from '../common/MediaTypeIndicator';
import SmallTableCell from '../common/SmallTableCell';
import Tag from '../common/Tag';

const useStyles = makeStyles((theme) => ({
  relative: {
    position: 'relative',
  },
  fill: {
    flex: 1,
  },
}));

export interface AdvertMediaTableProps {
  advert: Advert;
}

const AdvertMediaTable: VFC<AdvertMediaTableProps> = ({ advert }) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const { partner } = usePartnerCtx();
  const { advertMedias } = useAdvertMedias(advert);

  const addAdvertMediaDialog = useAddAdvertMediaDialog();
  const mediaUpdateDialog = useMediaUpdateDialog();

  const updateLinkActive = useUpdateLinkActive();

  return (
    <div className={styles.fill}>
      <Typography variant="subtitle1" gutterBottom>
        <strong>{t('advert.media.title')}</strong>
      </Typography>
      <TableContainer>
        <Table stickyHeader size="small">
          <TableHead>
            {partner.id === advert.partnerId && (
              <TableRow>
                <TableCell colSpan={6}>
                  <SecondaryButton
                    fullWidth
                    color="primary"
                    onClick={() => addAdvertMediaDialog.open({ advertId: advert.id })}
                  >
                    {t('advert.action.addMedia.button')}
                  </SecondaryButton>
                </TableCell>
                {/* {advertMedias.length > 0 && (
              <TableCell colSpan={2}>
                <SecondaryButton
                  fullWidth
                  color="primary"
                  onClick={() => {}}
                >
                  {t('advert.action.gallery.button')}
                </SecondaryButton>
              </TableCell>
            )} */}
              </TableRow>
            )}

            <Fade in>
              <TableRow>
                <SmallTableCell />
                <TableCell>{t('media.label')}</TableCell>
                <SmallTableCell />
                <SmallTableCell>{t('media.type')}</SmallTableCell>
                <SmallTableCell>{t('media.size')}</SmallTableCell>
                {partner.id === advert.partnerId && (
                  <SmallTableCell>{t('advert.media.status')}</SmallTableCell>
                )}
              </TableRow>
            </Fade>
          </TableHead>
          <TableBody>
            {advertMedias.length <= 0 ? (
              <TableRow>
                <TableCell colSpan={6}>
                  <Typography align="center">
                    <i>
                      {partner.id === advert.partnerId
                        ? t('advert.media.no-media-internal')
                        : t('advert.media.no-media-external')}
                    </i>
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              advertMedias.map(
                (advertMedia) =>
                  advertMedia.media && (
                    <TableRow key={advertMedia.media?.id} className={styles.relative}>
                      <SmallTableCell>
                        <MediaPreviewIndicator
                          media={advertMedia.media}
                          mediaUrl={advertMedia.mediaUrl}
                        />
                      </SmallTableCell>
                      <TableCell>
                        {advertMedia.media.name}
                        {!advertMedia.media.isActive && (
                          <Tag type="negative">{t('media.tag.disabled')}</Tag>
                        )}
                      </TableCell>
                      <SmallTableCell>
                        {partner.id === advertMedia.media?.partnerId && (
                          <OverlapButton
                            onClick={() =>
                              mediaUpdateDialog.open({ mediaId: advertMedia.media?.id })
                            }
                          />
                        )}
                      </SmallTableCell>
                      <SmallTableCell>
                        <MediaTypeIndicator media={advertMedia.media} />
                      </SmallTableCell>
                      <SmallTableCell>
                        <MediaSizeIndicator media={advertMedia.media} />
                      </SmallTableCell>
                      {partner.id === advert.partnerId && (
                        <SmallTableCell>
                          <Switch
                            color="primary"
                            checked={advertMedia.link?.isActive || false}
                            onChange={(e, checked) => {
                              updateLinkActive.action(advertMedia.link, checked);
                            }}
                          />
                        </SmallTableCell>
                      )}
                    </TableRow>
                  ),
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
export default AdvertMediaTable;
