import { makeStyles } from '@material-ui/core';
import { createContext, FC, useContext, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
}));

//Context
export interface SliderLayoutContextProps {
  drawerWidth: number;
  isDrawerOpen: boolean;
  openDrawer: () => void;
  closeDrawer: () => void;
}
const SliderLayoutContext = createContext<SliderLayoutContextProps>({} as SliderLayoutContextProps);
export const useSliderLayoutCtx = () => useContext(SliderLayoutContext);

//Component
export interface SliderLayoutProps {}
const SliderLayout: FC<SliderLayoutProps> = ({ children }) => {
  const styles = useStyles();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const openDrawer = () => setIsDrawerOpen(true);
  const closeDrawer = () => setIsDrawerOpen(false);

  return (
    <SliderLayoutContext.Provider
      value={{
        drawerWidth: 350,
        isDrawerOpen,
        openDrawer,
        closeDrawer,
      }}
    >
      <div className={styles.root}>{children}</div>
    </SliderLayoutContext.Provider>
  );
};
export default SliderLayout;
