import fb from 'firebase/app';
import COLLECTION from '../constants/collections';
import { Member, MemberProps, toMember } from '../models/Member';
import { OnDataFunction } from '../types/general';
import { defined, notDefined } from '../utils';
import { FirestoreRepo } from './FirestoreRepo';

class MemberRepo extends FirestoreRepo {
  private root(): fb.firestore.CollectionReference<MemberProps>;
  private root() {
    return this.fs.collection(COLLECTION.MEMBER);
  }

  async getMember(id: string) {
    const snap = await this.root().doc(id).get();
    return this.snapToMember(snap);
  }
  async getMembers(ids: string[]) {
    return this.clearUndefined(await Promise.all(ids.map(async (id) => await this.getMember(id))));
  }

  async create(member: Member) {
    notDefined(member.id, 'Member.id');
    const result = await this.root().add(member.props);
    return result.id;
  }
  async update(member: Member) {
    await this.root().doc(defined(member.id)).update(member.changes);
  }
  async remove(member: Member) {
    await this.root().doc(defined(member.id)).delete();
  }

  onMember(memberId: string, onData: OnDataFunction<Member | undefined>) {
    const unsubscribe = this.root()
      .doc(memberId)
      .onSnapshot(
        (snap) => {
          const member = this.snapToMember(snap);
          onData(member, unsubscribe);
        },
        (error) => {
          console.error(error);
          onData(undefined, unsubscribe);
        },
      );
    return unsubscribe;
  }
  onMembers(onData: OnDataFunction<Member[]>) {
    const unsubscribe = this.root().onSnapshot(
      (snap) => {
        const members = this.snapToMembers(snap);
        onData(members, unsubscribe);
      },
      (error) => {
        console.error(error);
        onData([], unsubscribe);
      },
    );
    return unsubscribe;
  }

  private snapToMember(snap: fb.firestore.DocumentSnapshot<MemberProps>) {
    const data = snap.data();
    if (!data) return undefined;
    return toMember(data, snap.id);
  }
  private snapToMembers(snap: fb.firestore.QuerySnapshot<MemberProps>) {
    return this.clearUndefined(snap.docs.map((snap) => this.snapToMember(snap)));
  }
}

export { MemberRepo };
