import { useEffect, useState } from 'react';
import { Integration, IntegrationStatus } from '../../../models/Integration';
import { Partner } from '../../../models/Partner';
import { integrationRepo } from '../../../repo';
import useRenderSafe from '../../useRenderSafe';

const usePartnerIntegrations = (partner?: Partner, linked?: boolean) => {
  const partnerId = useRenderSafe(partner?.id);
  const [integrations, setIntegrations] = useState<Integration[]>();

  useEffect(() => {
    try {
      if (!partnerId) return setIntegrations(undefined);
      const filter: { status?: IntegrationStatus } = {};
      if (linked) filter.status = IntegrationStatus.LINKED;
      return integrationRepo.onPartnerIntegrations(
        partnerId,
        (integrations) => setIntegrations(integrations),
        filter,
      );
    } catch (error) {
      setIntegrations(undefined);
    }
  }, [linked, partnerId]);

  return integrations;
};
export default usePartnerIntegrations;
