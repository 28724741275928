import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Partner } from '../../models/Partner';
import useCreateAdvert from '../action/advert/useCreateAdvert';

export interface useCreateAdvertFormConfig {
  partner?: Partner;
  onSuccess?: (advertId: string | undefined) => Promise<void> | void;
}

const useCreateAdvertForm = ({ partner, onSuccess }: useCreateAdvertFormConfig) => {
  const { t } = useTranslation();

  const createAdvert = useCreateAdvert();

  const formik = useFormik<{
    name: string;
  }>({
    enableReinitialize: true,
    initialValues: {
      name: '',
    },
    onSubmit: async ({ name }) => {
      if (!partner) return;
      const advertId = await createAdvert.action(partner, { name });
      formik.resetForm();
      if (onSuccess) await onSuccess(advertId);
    },
    validationSchema: yup.object({
      name: yup
        .string()
        .required(t('advert.action.create.validation.name-required'))
        .min(3, t('advert.action.create.validation.name-length')),
    }),
  });
  return formik;
};
export default useCreateAdvertForm;
