import { PartnerLinkType } from './enum';
import { ExternalPartnerPartnerLink } from './spec';

function newPartnerLink(
  type: PartnerLinkType.EXT_PARTNER_PARTNER,
): typeof ExternalPartnerPartnerLink.new;
function newPartnerLink(type: PartnerLinkType) {
  switch (type) {
    case PartnerLinkType.EXT_PARTNER_PARTNER:
      return ExternalPartnerPartnerLink.new;

    default:
      throw new Error('Unknown implementation');
  }
}

export { newPartnerLink };
