import { MouseEventHandler } from 'react';
import { FormikInstance } from '../types/Formik';

const formikToSubmit = <T>(
  formik: FormikInstance<T>,
): {
  type: 'submit';
  onClick: MouseEventHandler<HTMLButtonElement>;
  disabled: boolean;
} => {
  const onClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    formik.submitForm();
  };

  return {
    type: 'submit',
    onClick: onClick,
    disabled: formik.isSubmitting,
  };
};
export default formikToSubmit;
