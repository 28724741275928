import fb from 'firebase/app';
import { useEffect, useState } from 'react';
import { Member } from '../../models/Member';
import { memberRepo } from '../../repo';

const useMember = (currentUser?: fb.User | null | undefined) => {
  const [uid, setUid] = useState<string>();
  const [member, setMember] = useState<Member>();

  useEffect(() => {
    try {
      if (currentUser?.uid !== uid) setUid(currentUser?.uid);
    } catch (error) {
      console.error(error);
    }
  }, [currentUser, uid]);

  useEffect(() => {
    try {
      if (!uid) return;
      const unsubscribe = memberRepo.onMember(uid, (member) => {
        setMember(member);
      });

      return unsubscribe;
    } catch (error) {
      console.error(error);
    }
  }, [uid]);

  return member;
};
export default useMember;
