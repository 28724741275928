import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { FILE_SIZE, SUPPORTED_FORMATS } from '../../../constants/media';
import { Media, SizeType, toMedia } from '../../../models/Media';
import { mediaRepo, mediaStorageRepo } from '../../../repo';

const useUpdateMediaFile = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isBusy, setIsBusy] = useState(false);
  const action = async (media: Media, file: File) => {
    try {
      setIsBusy(true);

      await yup
        .mixed<File>()
        .required(t('media.action.changeFile.validation.file-required'))
        .test(
          'fileSize',
          t('media.action.changeFile.validation.file-size'),
          (file) => !file || file.size <= FILE_SIZE,
        )
        .test(
          'fileFormat',
          t('media.action.changeFile.validation.file-format'),
          (file) => !file || SUPPORTED_FORMATS.includes(file.type),
        )
        .validate(file);

      const changed = toMedia(media.partnerId, media.props, media.id);

      const aspectRatio = await getAspectRatio(file);
      const sizeType = getSizeType(aspectRatio);
      const fileName = await uploadFile(media.partnerId, file);
      changed.changeFile(fileName, sizeType);

      await mediaRepo.update(changed);
      enqueueSnackbar(t('media.action.changeFile.successMessage'), { variant: 'success' });
    } catch (error: any) {
      if (error.errors) {
        error.errors.forEach((errMessage: string) =>
          enqueueSnackbar(errMessage, { variant: 'error' }),
        );
      } else {
        enqueueSnackbar(t('media.action.changeFile.failedMessage'), { variant: 'error' });
      }
    } finally {
      setIsBusy(false);
    }
  };

  return { isBusy, action };
};
export default useUpdateMediaFile;

function getAspectRatio(file: File) {
  return new Promise<number>((resolve, reject) => {
    try {
      const img = new Image();
      img.onload = async function () {
        const width: number = (this as any).width;
        const height: number = (this as any).height;
        const aspectRatio = width / height;
        resolve(aspectRatio);
      };
      img.src = URL.createObjectURL(file);
    } catch (error) {
      reject(error);
    }
  });
}
function getSizeType(aspectRatio: number) {
  if (aspectRatio > 1.5) {
    if (aspectRatio > 3) {
      return SizeType.STRIP;
    } else {
      return SizeType.LANDSCAPE;
    }
  } else if (aspectRatio < 0.6) {
    if (aspectRatio < 0.3) {
      return SizeType.LANE;
    } else {
      return SizeType.PORTRAIT;
    }
  } else {
    return SizeType.SQUARE;
  }
}
function uploadFile(partnerId: string, file: File) {
  return new Promise<string>((resolve, reject) => {
    try {
      mediaStorageRepo.onCreate(
        partnerId,
        file,
        () => {},
        async (fileName) => {
          resolve(fileName);
        },
        (error) => {
          reject(error);
        },
      );
    } catch (error) {
      reject(error);
    }
  });
}
