import {
  Fade,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { useEffect, useState, VFC } from 'react';
import { useTranslation } from 'react-i18next';
import useMediaCreateDialog from '../../hooks/context/useMediaCreateDialog';
import { usePartnerCtx } from '../../hooks/context/usePartner';
import usePartnerMedias from '../../hooks/data/partner/usePartnerMedias';
import PrimaryButton from '../common/group.button/PrimaryButton';
import MediaSizeIndicator from '../common/MediaSizeIndicator';
import MediaTypeIndicator from '../common/MediaTypeIndicator';
import Tag from '../common/Tag';
import MediaEditor from './MediaEditor';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',

    margin: theme.spacing(1),
    gap: theme.spacing(1),
  },
  main: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    overflowY: 'auto',
    gap: theme.spacing(1),
  },
  mediaList: {
    flex: 1,

    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',

    padding: theme.spacing(1),
    maxHeight: '100%',
    minWidth: 200,
  },
  listContainer: {
    overflowY: 'auto',
  },
  mediaRow: {
    position: 'relative',
  },
  listIcons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  right: {
    flex: 2,

    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',

    padding: theme.spacing(1),
    maxHeight: '100%',
    minWidth: 600,
  },
  rightPanel: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',

    padding: theme.spacing(1),
    maxHeight: '100%',
  },
  tab: {
    textTransform: 'none',
  },
  updateForm: {
    display: 'flex',
    flexDirection: 'row',
  },
  bar: {
    overflow: 'visible',
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    padding: theme.spacing(1),
  },

  fill: {
    flex: 1,
  },
}));

const MediaArea: VFC = () => {
  const styles = useStyles();
  const { t } = useTranslation();

  const { partner } = usePartnerCtx();
  const partnerMedias = usePartnerMedias(partner);

  const [mediaLabelFilter, setMediaLabelFilter] = useState('');
  const [selectedMediaId, setSelectedMediaId] = useState<string>();
  const selectedMedia = partnerMedias?.find((p) => p.media.id === selectedMediaId);

  const mediaCreateDialog = useMediaCreateDialog();

  useEffect(() => {
    if (!selectedMediaId && partnerMedias) setSelectedMediaId(partnerMedias[0]?.media.id);
  }, [partnerMedias, selectedMediaId]);

  return (
    <Fade in>
      <div className={styles.root}>
        <Typography variant="h5">
          <strong> {t('media.page-header')}</strong>
        </Typography>
        <Typography>{t('media.page-info')}</Typography>
        <Paper className={styles.bar}>
          <TextField
            type="search"
            placeholder={t('global.search')}
            margin="none"
            size="small"
            variant="outlined"
            className={styles.fill}
            value={mediaLabelFilter}
            onChange={(e) => setMediaLabelFilter(e.target.value)}
          />

          <PrimaryButton onClick={() => mediaCreateDialog.open()}>
            {t('media.action.create.button')}
          </PrimaryButton>
        </Paper>
        <div className={styles.main}>
          <Paper className={styles.mediaList}>
            <Typography variant="h6">{t('media.list-partner')}</Typography>
            <div className={styles.listContainer}>
              {partnerMedias && partnerMedias?.length <= 0 ? (
                <Typography>
                  <i>{t('partner.media.no-media-internal')}</i>
                </Typography>
              ) : (
                <List>
                  {partnerMedias
                    ?.filter((p) =>
                      p.media.name.toLowerCase().includes(mediaLabelFilter.toLowerCase()),
                    )
                    .map((partnerMedia) => (
                      <ListItem
                        key={partnerMedia.media.id}
                        button
                        onClick={() => setSelectedMediaId(partnerMedia.media.id)}
                        selected={selectedMediaId === partnerMedia.media.id}
                      >
                        <ListItemText>
                          {partnerMedia.media.name}
                          {partnerMedia.media.isActive ? (
                            <Tag type="positive">{t('media.tag.enabled')}</Tag>
                          ) : (
                            <Tag type="negative">{t('media.tag.disabled')}</Tag>
                          )}
                        </ListItemText>
                        <ListItemIcon>
                          <div className={styles.listIcons}>
                            <MediaTypeIndicator media={partnerMedia.media} />
                            <MediaSizeIndicator media={partnerMedia.media} />
                          </div>
                        </ListItemIcon>
                      </ListItem>
                    ))}
                </List>
              )}
            </div>
          </Paper>
          <Fade in={Boolean(selectedMedia)}>
            <Paper className={styles.right}>
              <MediaEditor media={selectedMedia?.media} />
            </Paper>
          </Fade>
        </div>
      </div>
    </Fade>
  );
};
export default MediaArea;
