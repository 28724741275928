import { AppBar, IconButton, makeStyles, Toolbar, Typography } from '@material-ui/core';
import MenuItem from '@material-ui/icons/Menu';
import clsx from 'clsx';
import { FC } from 'react';
import { useSliderLayoutCtx } from './SliderLayout';

const useStyles = makeStyles((theme) => ({
  centeredText: {
    textAlign: 'center',
    flex: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: ({ drawerWidth }: { drawerWidth: number }) => drawerWidth,
    width: ({ drawerWidth }: { drawerWidth: number }) => `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: 'none',
  },
}));

interface SliderLayoutHeaderProps {
  title?: string;
}

const SliderLayoutHeader: FC<SliderLayoutHeaderProps> = ({ title, children }) => {
  const layout = useSliderLayoutCtx();
  const styles = useStyles(layout);

  return (
    <AppBar
      className={clsx(styles.appBar, {
        [styles.appBarShift]: layout.isDrawerOpen,
      })}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          onClick={() => layout.openDrawer()}
          edge="start"
          className={clsx({
            [styles.hide]: layout.isDrawerOpen,
          })}
        >
          <MenuItem />
        </IconButton>
        <Typography className={styles.centeredText} variant="h5" noWrap>
          {title}
        </Typography>
        {children}
      </Toolbar>
    </AppBar>
  );
};
export default SliderLayoutHeader;
