import { PartnerLinkType } from './enum';
import { PartnerLink, PartnerLinkProps } from './PartnerLink';
import { ExternalPartnerPartnerLink, ExternalPartnerPartnerLinkProps } from './spec';

function toPartnerLink(
  props: ExternalPartnerPartnerLinkProps,
  id?: string,
): ExternalPartnerPartnerLink;
function toPartnerLink(props: PartnerLinkProps, id?: string): PartnerLink;
function toPartnerLink(props: PartnerLinkProps, id?: string): PartnerLink {
  switch (props.type) {
    case PartnerLinkType.EXT_PARTNER_PARTNER:
      return new ExternalPartnerPartnerLink(props, id);

    default:
      throw new Error('Unknown implementation');
  }
}

export { toPartnerLink };
