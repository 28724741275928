import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Form as FormikForm, FormikProvider } from 'formik';
import { FC } from 'react';
import { FormikInstance } from '../types/Formik';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflowY: 'auto',
    display: 'flex',
    gap: theme.spacing(1),
  },
  column: {
    flexDirection: 'column',
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
}));
interface FormProps {
  form: FormikInstance<any>;
  row?: boolean;
  className?: string;
}

const Form: FC<FormProps> = ({ form, children, row, className }) => {
  const styles = useStyles();

  return (
    <FormikProvider value={form}>
      <FormikForm
        className={clsx(
          styles.root,
          {
            [styles.row]: row,
            [styles.column]: !row,
          },
          className,
        )}
      >
        {children}
      </FormikForm>
    </FormikProvider>
  );
};
export default Form;
