import { useState } from 'react';
import { Advert } from '../../../models/Advert';
import { LinkType, newLink } from '../../../models/Link';
import { Media } from '../../../models/Media';
import { linkRepo } from '../../../repo';
import { defined } from '../../../utils';

const useCreateAdvertMediaLink = () => {
  const [isBusy, setIsBusy] = useState(false);
  const action = async (advert: Advert, media: Media) => {
    try {
      setIsBusy(true);
      const link = newLink(LinkType.ADVERT_MEDIA)(advert.partnerId, {
        advertId: defined(advert.id, 'advert.id'),
        mediaId: defined(media.id, 'media.id'),
        isActive: true,
      });
      await linkRepo.create(link);
    } catch (error) {
      console.log(error);
    } finally {
      setIsBusy(false);
    }
  };

  return { isBusy, action };
};
export default useCreateAdvertMediaLink;
