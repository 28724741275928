import { makeStyles, TextField, Typography, TypographyProps } from '@material-ui/core';
import clsx from 'clsx';
import { FC, useState } from 'react';
import CloseIconButton from './group.button/CloseIconButton';
import EditIconButton from './group.button/EditIconButton';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  fill: {
    flex: 1,
  },
}));

export interface EditableTypographyProps extends TypographyProps {
  children?: string;
  onEdit: (value: string) => void | Promise<void>;
  disabled?: boolean;
}

const EditableTypography: FC<EditableTypographyProps> = ({
  className,
  disabled,
  onEdit,
  children = '',
}) => {
  const styles = useStyles();
  const [isEdit, setIsEdit] = useState(false);
  const [value, setValue] = useState(children);

  return isEdit ? (
    <div className={clsx(styles.root, className)}>
      <TextField
        className={styles.fill}
        autoFocus
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            onEdit(value);
            setIsEdit(false);
          }
        }}
      />
      <CloseIconButton
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          setIsEdit(false);
          setValue(children);
        }}
        disabled={disabled}
      />
    </div>
  ) : (
    <div className={clsx(styles.root, className)}>
      <Typography className={styles.fill}>{children}</Typography>
      <EditIconButton
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          setIsEdit(true);
        }}
      />
    </div>
  );
};

export default EditableTypography;
