import { useState } from 'react';
import { Media, toMedia } from '../../../models/Media';
import { mediaRepo } from '../../../repo';

const useUpdateMediaActive = () => {
  const [isBusy, setIsBusy] = useState(false);
  const action = async (media: Media, status: boolean) => {
    try {
      setIsBusy(true);
      const changed = toMedia(media.partnerId, media.props, media.id);
      changed.isActive = status;
      await mediaRepo.update(changed);
    } catch (error) {
    } finally {
      setIsBusy(false);
    }
  };

  return { isBusy, action };
};
export default useUpdateMediaActive;
