import { IntegrationType } from './enum';
import { Integration, IntegrationProps } from './Integration';
import { TapioIntegration, TapioIntegrationProps } from './spec';

function toIntegration(props: TapioIntegrationProps, id?: string): TapioIntegration;
function toIntegration(props: IntegrationProps, id?: string): Integration;
function toIntegration(props: IntegrationProps, id?: string): Integration {
  switch (props.type) {
    case IntegrationType.TAPIO:
      return new TapioIntegration(props, id);

    default:
      throw new Error('Unknown implementation');
  }
}

export { toIntegration };
