import { useEffect, useMemo, useState } from 'react';
import { Advert } from '../../../models/Advert';
import { AdvertMediaLink } from '../../../models/Link';
import { Media } from '../../../models/Media';
import { linkRepo, mediaRepo, mediaStorageRepo } from '../../../repo';
import { defined } from '../../../utils';
import useRenderSafe from '../../useRenderSafe';

export interface AdvertMedia {
  media: Media | undefined;
  mediaUrl: string | undefined;
  link: AdvertMediaLink;
}

const useAdvertMedias = (advert?: Advert, linksOnly?: boolean) => {
  const partnerId = useRenderSafe(advert?.partnerId);
  const advertId = useRenderSafe(advert?.id);
  const [advertMediaLinks, setAdvertMediaLinks] = useState<AdvertMediaLink[]>();
  const [advertMediasData, setAdvertMediasData] = useState<{
    [linkId: string]: {
      link: AdvertMediaLink;
      media?: Media;
      mediaUrl?: string;
      unsubscribe?: () => void;
    };
  }>({});

  useEffect(() => {
    try {
      if (!partnerId || !advertId) return setAdvertMediaLinks(undefined);
      return linkRepo.onAdvertMediaLinks(partnerId, advertId, (advertMediaLinks) =>
        setAdvertMediaLinks(advertMediaLinks),
      );
    } catch (error) {
      setAdvertMediaLinks(undefined);
    }
  }, [partnerId, advertId]);

  useEffect(() => {
    if (linksOnly) setAdvertMediasData({});
    if (!partnerId || !advertId)
      return setAdvertMediasData((data) => {
        Object.entries(data).forEach(([, { unsubscribe }]) => {
          if (unsubscribe) unsubscribe();
        });
        return {};
      });

    setAdvertMediasData((data) => {
      //Add new and update existing
      Object.keys(data).forEach((linkId) => {
        if (!advertMediaLinks?.some((p) => p.id === linkId)) {
          const unsubscribe = data[linkId].unsubscribe;
          if (unsubscribe) unsubscribe();
          delete data[linkId];
        }
      });
      //Remove old
      advertMediaLinks?.forEach((advertMediaLink) => {
        if (!advertMediaLink.id) return;
        if (!data[advertMediaLink.id]) {
          data[advertMediaLink.id] = {
            link: advertMediaLink,
          };
          const unsubscribe = mediaRepo.onMedia(
            partnerId,
            advertMediaLink.mediaId,
            async (media) => {
              const mediaUrl = media ? await mediaStorageRepo.getReadUrl(media) : undefined;
              setAdvertMediasData((old) => ({
                ...old,
                [defined(advertMediaLink.id)]: {
                  ...old[defined(advertMediaLink.id)],
                  media,
                  mediaUrl,
                  unsubscribe,
                },
              }));
            },
          );
        } else {
          data[advertMediaLink.id].link = advertMediaLink;
        }
      });
      return data;
    });
    return () => {
      setAdvertMediasData((data) => {
        Object.entries(data).forEach(([, { unsubscribe }]) => {
          if (unsubscribe) unsubscribe();
        });
        return {};
      });
    };
  }, [advertMediaLinks, advertId, linksOnly, partnerId]);

  const advertMedias: AdvertMedia[] = useMemo(
    () =>
      Object.values(advertMediasData)
        .filter((p) => p.media)
        .map(({ media, mediaUrl, link }) => ({ media, mediaUrl, link })),
    [advertMediasData],
  );

  return { advertMediaLinks, advertMedias };
};
export default useAdvertMedias;
