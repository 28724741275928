import { makeStyles, Typography } from '@material-ui/core';
import { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import { Advert } from '../../models/Advert';
import AdvertIntegrationTable from '../AdvertArea/AdvertIntegrationTable';

const useStyles = makeStyles((theme) => ({
  info: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

export interface IntegrationStepProps {
  advert: Advert;
}

const IntegrationStep: VFC<IntegrationStepProps> = ({ advert }) => {
  const { t } = useTranslation();
  const styles = useStyles();

  return (
    <>
      <div className={styles.info}>
        <Typography variant="h5">
          <strong> {t('advert.action.create.step.integration')}</strong>
        </Typography>
        <Typography>{t('advert.action.create.step.integration-info')}</Typography>
      </div>
      <AdvertIntegrationTable advert={advert} />
    </>
  );
};

export default IntegrationStep;
