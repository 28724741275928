import { IconButton, IconButtonProps } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { VFC } from 'react';

const EditIconButton: VFC<IconButtonProps> = ({ ...IconButtonProps }) => {
  return (
    <IconButton {...IconButtonProps}>
      <EditIcon />
    </IconButton>
  );
};
export default EditIconButton;
