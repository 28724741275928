import fb from 'firebase/app';
import COLLECTION from '../constants/collections';
import { Advert, AdvertProps, toAdvert } from '../models/Advert';
import { OnDataFunction } from '../types/general';
import { defined, notDefined } from '../utils';
import { FirestoreRepo } from './FirestoreRepo';

class AdvertRepo extends FirestoreRepo {
  private root(partnerId: string): fb.firestore.CollectionReference<AdvertProps>;
  private root(partnerId: string) {
    return this.fs.collection(COLLECTION.PARTNER).doc(partnerId).collection(COLLECTION.ADVERT);
  }

  async getAdvert(partnerId: string, id: string) {
    const snap = await this.root(partnerId).doc(id).get();
    return this.snapToAdvert(partnerId, snap);
  }
  async getAdverts(partnerId: string, ids: string[]) {
    return this.clearUndefined(
      await Promise.all(ids.map(async (id) => await this.getAdvert(partnerId, id))),
    );
  }

  async create(advert: Advert) {
    notDefined(advert.id, 'Advert.id');
    const result = await this.root(advert.partnerId).add(advert.props as AdvertProps);
    return result.id;
  }
  async update(advert: Advert) {
    await this.root(advert.partnerId).doc(defined(advert.id)).update(advert.changes);
  }
  async remove(advert: Advert) {
    await this.root(advert.partnerId).doc(defined(advert.id)).delete();
  }

  onAdvert(partnerId: string, advertId: string, onData: OnDataFunction<Advert | undefined>) {
    const unsubscribe = this.root(partnerId)
      .doc(advertId)
      .onSnapshot(
        (snap) => {
          const advert = this.snapToAdvert(partnerId, snap);
          onData(advert, unsubscribe);
        },
        (error) => {
          console.error(error);
          onData(undefined, unsubscribe);
        },
      );
    return unsubscribe;
  }
  onAdverts(partnerId: string, onData: OnDataFunction<Advert[]>) {
    const unsubscribe = this.root(partnerId).onSnapshot(
      (snap) => {
        const adverts = this.snapToAdverts(partnerId, snap);
        onData(adverts, unsubscribe);
      },
      (error) => {
        console.error(error);
        onData([], unsubscribe);
      },
    );
    return unsubscribe;
  }

  private snapToAdvert(partnerId: string, snap: fb.firestore.DocumentSnapshot<AdvertProps>) {
    const data = snap.data();
    if (!data) return undefined;
    return toAdvert(partnerId, data, snap.id);
  }
  private snapToAdverts(partnerId: string, snap: fb.firestore.QuerySnapshot<AdvertProps>) {
    return this.clearUndefined(snap.docs.map((snap) => this.snapToAdvert(partnerId, snap)));
  }
}

export { AdvertRepo };
