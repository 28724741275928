import fb from 'firebase/app';
import COLLECTION from '../constants/collections';
import {
  ExternalPartnerPartnerLink,
  ExternalPartnerPartnerLinkProps,
  PartnerLink,
  PartnerLinkProps,
  PartnerLinkStatus,
  PartnerLinkType,
  toPartnerLink,
} from '../models/PartnerLink';
import { OnDataFunction } from '../types/general';
import { defined, notDefined } from '../utils';
import { FirestoreRepo } from './FirestoreRepo';

class PartnerLinkRepo extends FirestoreRepo {
  private root(
    type: PartnerLinkType.EXT_PARTNER_PARTNER,
  ): fb.firestore.CollectionReference<ExternalPartnerPartnerLinkProps>;
  private root(): fb.firestore.CollectionReference<PartnerLinkProps>;
  private root(type?: PartnerLinkType) {
    const root = this.fs.collection(COLLECTION.PARTNERLINK);
    if (!type) return root;
    return this.filteredQuery(root, { type });
  }

  async getPartnerLink(id: string) {
    const snap = await this.root().doc(id).get();
    return this.snapToPartnerLink(snap);
  }
  async getPartnerLinks(ids: string[]) {
    return this.clearUndefined(
      await Promise.all(ids.map(async (id) => await this.getPartnerLink(id))),
    );
  }

  async create(partnerLink: PartnerLink) {
    notDefined(partnerLink.id, 'PartnerLink.id');
    const id = `${partnerLink.partnerId}_${partnerLink.extPartnerId}`;
    await this.root().doc(id).set(partnerLink.props);
    return id;
  }
  async update(partnerLink: PartnerLink) {
    await this.root().doc(defined(partnerLink.id)).update(partnerLink.changes);
  }
  async remove(partnerLink: PartnerLink) {
    await this.root().doc(defined(partnerLink.id)).delete();
  }

  onPartnerLink(partnerLinkId: string, onData: OnDataFunction<PartnerLink | undefined>) {
    const unsubscribe = this.root()
      .doc(partnerLinkId)
      .onSnapshot(
        (snap) => {
          const partnerLink = this.snapToPartnerLink(snap);
          onData(partnerLink, unsubscribe);
        },
        (error) => {
          console.error(error);
          onData(undefined, unsubscribe);
        },
      );
    return unsubscribe;
  }
  onPartnerLinks(onData: OnDataFunction<PartnerLink[]>) {
    const unsubscribe = this.root().onSnapshot(
      (snap) => {
        const partnerLinks = this.snapToPartnerLinks(snap);
        onData(partnerLinks, unsubscribe);
      },
      (error) => {
        console.error(error);
        onData([], unsubscribe);
      },
    );
    return unsubscribe;
  }

  onIncomingPartnerLinks(partnerId: string, onData: OnDataFunction<ExternalPartnerPartnerLink[]>) {
    const unsubscribe = this.filteredQuery(this.root(PartnerLinkType.EXT_PARTNER_PARTNER), {
      partnerId,
    })
      .where('status', '!=', PartnerLinkStatus.REFUSED)
      .onSnapshot(
        (snap) => {
          const partnerLinks = this.snapToPartnerLinks(snap);
          onData(partnerLinks, unsubscribe);
        },
        (error) => {
          console.error(error);
          onData([], unsubscribe);
        },
      );
    return unsubscribe;
  }
  onOutgoingPartnerLinks(partnerId: string, onData: OnDataFunction<ExternalPartnerPartnerLink[]>) {
    const unsubscribe = this.filteredQuery(this.root(PartnerLinkType.EXT_PARTNER_PARTNER), {
      extPartnerId: partnerId,
    }).onSnapshot(
      (snap) => {
        const partnerLinks = this.snapToPartnerLinks(snap);
        onData(partnerLinks, unsubscribe);
      },
      (error) => {
        console.error(error);
        onData([], unsubscribe);
      },
    );
    return unsubscribe;
  }

  private snapToPartnerLink(
    snap: fb.firestore.DocumentSnapshot<ExternalPartnerPartnerLinkProps>,
  ): ExternalPartnerPartnerLink | undefined;
  private snapToPartnerLink(
    snap: fb.firestore.DocumentSnapshot<PartnerLinkProps>,
  ): PartnerLink | undefined;
  private snapToPartnerLink(snap: fb.firestore.DocumentSnapshot<PartnerLinkProps>) {
    const data = snap.data();
    if (!data) return undefined;
    return toPartnerLink(data, snap.id);
  }

  private snapToPartnerLinks(
    snap: fb.firestore.QuerySnapshot<ExternalPartnerPartnerLinkProps>,
  ): ExternalPartnerPartnerLink[];
  private snapToPartnerLinks(snap: fb.firestore.QuerySnapshot<PartnerLinkProps>): PartnerLink[];
  private snapToPartnerLinks(snap: fb.firestore.QuerySnapshot<PartnerLinkProps>) {
    return this.clearUndefined(snap.docs.map((snap) => this.snapToPartnerLink(snap)));
  }
}

export { PartnerLinkRepo };
