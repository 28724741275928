import { BoxProps, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { AnchorHTMLAttributes, VFC } from 'react';
import generateOveridableBox from '../../utils/generateOveridableBox';

const Box = generateOveridableBox<AnchorHTMLAttributes<HTMLAnchorElement>>();

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'auto 100%',
    backgroundImage: 'url("/img/logo.png")',
    minWidth: 150,
    minHeight: 150,
  },
}));

const Logo: VFC<BoxProps> = ({ className, ...BoxProps }) => {
  const styles = useStyles();
  return (
    <Box
      component="a"
      href="https://watdrinkje.be"
      className={clsx(styles.root, className)}
      {...BoxProps}
    />
  );
};
export default Logo;
