import get from 'lodash.get';
import set from 'lodash.set';
import { DeepReadonly } from 'ts-essentials';

abstract class DataModel<P extends object> {
  protected _id;
  protected _props;
  private _changed;
  constructor(props: P, id?: string) {
    this._id = id;
    this._props = props;
    this._changed = new Set<string>();
  }

  get id() {
    return this._id;
  }
  get props() {
    return this.imm(this._props);
  }
  get json() {
    return this.imm({ ...this.props, id: this.id });
  }

  protected imm<T>(value: T): DeepReadonly<T> {
    return value as DeepReadonly<T>;
  }

  protected propChange<K1 extends keyof P, K2 extends keyof P[K1], K3 extends keyof P[K1][K2]>(
    v: any,
    k1: K1,
    k2?: K2,
    k3?: K3,
  ) {
    const key = [k2, k3].reduce((acc, curr) => (acc += curr ? `.${curr}` : ''), k1.toString());
    if (get(this._props, key) === v) return false;
    set(this._props, key, v);
    this._changed.add(key);
    return true;
  }
  get changes() {
    return this.imm(
      Array.from(this._changed.values()).reduce<{ [field: string]: any }>((acc, k) => {
        acc[k] = get(this._props, k);
        return acc;
      }, {}),
    );
  }
}
export default DataModel;
