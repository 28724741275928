import { Box, makeStyles, TextField, Typography } from '@material-ui/core';
import { useEffect, useState, VFC } from 'react';
import { toColor, useColor } from 'react-color-palette';
import { useTranslation } from 'react-i18next';
import useRemoveMedia from '../../hooks/action/media/useRemoveMedia';
import useUpdateMediaActive from '../../hooks/action/media/useUpdateMediaActive';
import useUpdateMediaColor from '../../hooks/action/media/useUpdateMediaColor';
import useUpdateMediaFile from '../../hooks/action/media/useUpdateMediaFile';
import useUpdateMediaLabel from '../../hooks/action/media/useUpdateMediaLabel';
import { useConfirm } from '../../hooks/context/useConfirm';
import useMediaUrl from '../../hooks/data/media/useMediaUrl';
import { Media } from '../../models/Media';
import ColorButton from '../common/ColorButton';
import FileButton from '../common/FileButton';
import TertiaryButton from '../common/group.button/TertiaryButton';
import Image from '../common/Image';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    overflowY: 'auto',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    overflowY: 'auto',
  },
  actions: {},
  form: {
    flex: 2,

    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  image: {
    flex: 3,

    display: 'flex',
    flexDirection: 'column',

    maxHeight: '100%',
    overflowY: 'auto',
  },
  deleteMediaButton: {
    color: theme.palette.error.main,
  },
  disableMediaButton: {
    color: theme.palette.error.main,
  },
  enableMediaButton: {
    color: theme.palette.success.main,
  },

  fill: {
    flex: 1,
  },
}));

export interface MediaEditorProps {
  media?: Media;
}

const MediaEditor: VFC<MediaEditorProps> = ({ media }) => {
  const { t } = useTranslation();
  const styles = useStyles();

  const mediaUrl = useMediaUrl(media);

  const [label, setLabel] = useState<string>();
  const [backgroundColor, setBackgroundColor] = useColor('hex', '#000000');
  const [url, setUrl] = useState<string>();

  const confirm = useConfirm();
  const updateMediaActive = useUpdateMediaActive();
  const updateMediaLabel = useUpdateMediaLabel();
  const updateMediaColor = useUpdateMediaColor();
  const updateMediaFile = useUpdateMediaFile();
  const removeMedia = useRemoveMedia();

  useEffect(() => {
    if (media?.backgroundColor) setBackgroundColor(toColor('hex', media?.backgroundColor));
  }, [media?.backgroundColor, setBackgroundColor]);

  if (!media) return null;
  return (
    <div className={styles.root}>
      <Typography variant="h6" gutterBottom>
        {t('media.update-dialog-header')}
      </Typography>
      <div className={styles.content}>
        <div className={styles.form}>
          <TextField
            label={t('media.label')}
            value={label || media.name}
            onChange={(e) => {
              setLabel(e.target.value);
            }}
            onBlur={async (e) => {
              await updateMediaLabel.action(media, e.target.value);
              setLabel(undefined);
            }}
          />
          <ColorButton
            value={backgroundColor || toColor('hex', media.backgroundColor)}
            onChange={(color) => {
              setBackgroundColor(color);
            }}
            onBlur={async (color) => {
              await updateMediaColor.action(media, color.hex);
            }}
          >
            {t('media.backgroundColor')}
          </ColorButton>
          <FileButton
            onChange={(file) => {
              setUrl(file ? URL.createObjectURL(file) : undefined);
            }}
            confirmLabel={t('media.action.changeFile.confirmButton')}
            onConfirm={async (file, reset) => {
              await updateMediaFile.action(media, file);
              setUrl(undefined);
              reset();
              await mediaUrl.refresh();
            }}
            accept="image/*"
          >
            {t('media.file')}
          </FileButton>
        </div>
        <div className={styles.image}>
          <Image
            alt={label || media.name}
            backgroundColor={backgroundColor.hex || media.backgroundColor}
            url={url || mediaUrl.value}
          />
        </div>
      </div>
      <div className={styles.actions}>
        <TertiaryButton
          className={styles.deleteMediaButton}
          onClick={() => {
            confirm({
              message: t('media.action.delete.question'),
              confirmButton: t('media.action.delete.confirm'),
              cancelButton: t('media.action.delete.cancel'),
              action: () => removeMedia.action(media),
            });
          }}
        >
          {t('media.action.delete.button')}
        </TertiaryButton>
        {media.isActive ? (
          <TertiaryButton
            className={styles.disableMediaButton}
            onClick={() => updateMediaActive.action(media, false)}
          >
            {t('advert.action.disable.disableButton')}
          </TertiaryButton>
        ) : (
          <TertiaryButton
            className={styles.enableMediaButton}
            onClick={() => updateMediaActive.action(media, true)}
          >
            {t('advert.action.disable.enableButton')}
          </TertiaryButton>
        )}
        <Box flex={1} />
      </div>
    </div>
  );
};
export default MediaEditor;
