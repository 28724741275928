import { createContext, FC, ReactNode, useContext, useState } from 'react';

export interface GlobalDialogContextProps {
  open: (name: string, data?: { [prop: string]: any }) => void;
  close: (name: string) => void;
}
const GlobalDialogContext = createContext<GlobalDialogContextProps>({} as GlobalDialogContextProps);

export interface GlobalDialogCtxProviderProps {
  render?: (
    isOpen: (name: string) => boolean,
    data: (name: string) => { [prop: string]: any },
    open: (name: string) => void,
    close: (name: string) => void,
  ) => ReactNode;
}
export const GlobalDialogCtxProvider: FC<GlobalDialogCtxProviderProps> = ({ children, render }) => {
  const [isOpen, setIsOpen] = useState<{ [name: string]: boolean }>({});
  const [data, setData] = useState<{ [name: string]: { [prop: string]: any } }>({});

  const open = (name: string, data?: { [prop: string]: any }) => {
    if (data) setData((v) => ({ ...v, [name]: data }));
    setIsOpen((v) => ({ ...v, [name]: true }));
  };
  const close = (name: string) => {
    setIsOpen((v) => ({ ...v, [name]: false }));
    setData((v) => ({ ...v, [name]: {} }));
  };

  return (
    <GlobalDialogContext.Provider
      value={{
        open,
        close,
      }}
    >
      {children}
      {render &&
        render(
          (name: string) => isOpen[name] || false,
          (name: string) => data[name] || {},
          open,
          close,
        )}
    </GlobalDialogContext.Provider>
  );
};

function useGlobalDialog<P>(name: string) {
  const { open, close } = useContext(GlobalDialogContext);
  return {
    open: (props?: P) => open(name, props),
    close: () => close(name),
  };
}
export default useGlobalDialog;
