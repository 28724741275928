import {
  Fade,
  makeStyles,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import useCreateAdvertPartnerLink from '../../hooks/action/link/useCreateAdvertPartnerLink';
import useUpdateLinkActive from '../../hooks/action/link/useUpdateLinkActive';
import { usePartnerCtx } from '../../hooks/context/usePartner';
import useAdvertPartners from '../../hooks/data/advert/useAdvertPartners';
import usePartnerOutgoingPartners from '../../hooks/data/partner/usePartnerOutgoingPartners';
import { Advert } from '../../models/Advert';
import { PartnerLinkStatus } from '../../models/PartnerLink';
import SmallTableCell from '../common/SmallTableCell';
import Tag from '../common/Tag';

const useStyles = makeStyles((theme) => ({
  fill: {
    flex: 1,
  },
}));

export interface AdvertPartnerTableProps {
  advert: Advert;
}

const AdvertPartnerTable: VFC<AdvertPartnerTableProps> = ({ advert }) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const { partner } = usePartnerCtx();
  const { outgoingPartners } = usePartnerOutgoingPartners(partner);
  const { advertPartnerLinks } = useAdvertPartners(advert, true);
  const acceptedOutgoingPartners = outgoingPartners.filter(
    (p) => p.link.status === PartnerLinkStatus.ACCEPTED,
  );

  const createAdvertPartnerLink = useCreateAdvertPartnerLink();
  const updateLinkActive = useUpdateLinkActive();

  return (
    <Fade in>
      <div className={styles.fill}>
        <Typography variant="subtitle1" gutterBottom>
          <strong>{t('advert.partner.title')}</strong>
        </Typography>
        <TableContainer>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell>{t('partner.name')}</TableCell>
                <SmallTableCell>{t('advert.partner.status')}</SmallTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {acceptedOutgoingPartners.length <= 0 ? (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Typography align="center">
                      <i>{t('partner.partnership.no-partnerships-internal')}</i>
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                acceptedOutgoingPartners?.map((outgoingPartner) => {
                  const link = advertPartnerLinks?.find(
                    (p) => p.linkedPartnerId === outgoingPartner?.partner?.id,
                  );
                  return (
                    outgoingPartner.partner && (
                      <TableRow key={outgoingPartner.partner.id}>
                        <TableCell>
                          {outgoingPartner.partner.name}
                          {!outgoingPartner.link.isActive && (
                            <Tag type="negative">{t('partnership.tag.disabled')}</Tag>
                          )}
                        </TableCell>
                        <SmallTableCell>
                          <Switch
                            color="primary"
                            checked={link?.isActive || false}
                            onChange={(e, checked) => {
                              if (!outgoingPartner.partner) return;

                              if (link) updateLinkActive.action(link, checked);
                              else createAdvertPartnerLink.action(advert, outgoingPartner.partner);
                            }}
                          />
                        </SmallTableCell>
                      </TableRow>
                    )
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Fade>
  );
};
export default AdvertPartnerTable;
