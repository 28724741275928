import { ButtonProps, makeStyles } from '@material-ui/core';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { FC, useRef, useState } from 'react';
import PrimaryButton from './group.button/PrimaryButton';
import TertiaryButton from './group.button/TertiaryButton';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  button: {
    flex: 1,
    color: theme.palette.grey[500],
  },
}));

export interface FileButtonProps extends Omit<ButtonProps, 'onChange'> {
  onChange?: (file?: File) => Promise<void> | void;
  confirmLabel?: string;
  onConfirm?: (file: File, reset: () => void) => Promise<void> | void;

  accept?: string;
}
const FileButton: FC<FileButtonProps> = ({
  onChange,
  confirmLabel,
  onConfirm,
  accept,
  children,
  ...ButtonProps
}) => {
  const [file, setFile] = useState<File>();
  const styles = useStyles();
  const ref = useRef<any>();

  return (
    <div className={styles.root}>
      <TertiaryButton
        className={styles.button}
        {...ButtonProps}
        onClick={() => ref.current?.click()}
      >
        {children}
        <InsertDriveFileIcon />
      </TertiaryButton>
      {file && onConfirm && (
        <PrimaryButton
          color="primary"
          onClick={() => {
            if (file) onConfirm(file, () => setFile(undefined));
          }}
        >
          {confirmLabel}
        </PrimaryButton>
      )}
      <input
        hidden
        ref={ref}
        type="file"
        onChange={(e) => {
          const file = e.target.files ? e.target.files[0] : undefined;
          setFile(file);
          if (onChange) onChange(file);
        }}
        accept={accept}
        disabled={ButtonProps.disabled}
      />
    </div>
  );
};
export default FileButton;
