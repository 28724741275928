import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemText,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import useRemoveIntegration from '../hooks/action/integration/useRemoveIntegration';
import useUpdateIntegrationActive from '../hooks/action/integration/useUpdateIntegrationActive';
import { useConfirm } from '../hooks/context/useConfirm';
import { usePartnerCtx } from '../hooks/context/usePartner';
import usePartnerIntegrations from '../hooks/data/partner/usePartnerIntegrations';
import useCreateIntegrationForm from '../hooks/form/useCreateIntegrationForm';
import { IntegrationStatus } from '../models/Integration';
import PrimaryButton from './common/group.button/PrimaryButton';
import TertiaryButton from './common/group.button/TertiaryButton';
import SmallTableCell from './common/SmallTableCell';
import Tag from './common/Tag';
import Form from './Form';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    overflowY: 'auto',
  },
  form: {
    overflow: 'visible',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: theme.spacing(1),
  },
  deleteIntegrationButton: {
    color: theme.palette.error.main,
  },
  disableIntegrationButton: {
    color: theme.palette.error.main,
  },
  enableIntegrationButton: {
    color: theme.palette.success.main,
  },

  fill: {
    flex: 1,
  },
}));

export interface IntegrationDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const IntegrationDialog: VFC<IntegrationDialogProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const styles = useStyles();

  const { partner } = usePartnerCtx();
  const partnerIntegrations = usePartnerIntegrations(partner);

  const createIntegrationForm = useCreateIntegrationForm({
    partner,
    onSuccess: () => {},
  });

  const confirm = useConfirm();
  const removeIntegration = useRemoveIntegration();
  const updateIntegrationActive = useUpdateIntegrationActive();

  return (
    <Dialog open={isOpen} maxWidth="md" fullWidth>
      <DialogTitle>{t('integration.dialog-header')}</DialogTitle>
      <DialogContent className={styles.content}>
        <Typography>{t('integration.dialog-info')}</Typography>
        <Form className={styles.form} form={createIntegrationForm}>
          <TextField
            margin="none"
            size="small"
            variant="outlined"
            className={styles.fill}
            autoFocus
            label={t('integration.externalKey')}
            value={createIntegrationForm.values.externalKey}
            onChange={(e) => {
              createIntegrationForm.setFieldValue('externalKey', e.target.value);
            }}
            error={Boolean(createIntegrationForm.errors.externalKey)}
          />
          <PrimaryButton type="submit">{t('integration.action.create.button')}</PrimaryButton>
        </Form>
        <TableContainer>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell>{t('integration.name')}</TableCell>
                <SmallTableCell />
                <SmallTableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {partnerIntegrations?.map((integration) => (
                <TableRow key={integration.id}>
                  <TableCell>
                    <ListItemText secondary={integration.externalKey}>
                      {integration.label}
                      {integration.status === IntegrationStatus.LINKED && (
                        <>
                          <Tag type="positive">{t('integration.tag.linked')}</Tag>
                          {integration.isActive ? (
                            <Tag type="positive">{t('integration.tag.enabled')}</Tag>
                          ) : (
                            <Tag type="negative">{t('integration.tag.disabled')}</Tag>
                          )}
                        </>
                      )}
                      {integration.status === IntegrationStatus.LOADING && (
                        <Tag type="neutral">{t('integration.tag.loading')}</Tag>
                      )}
                      {integration.status === IntegrationStatus.UNLINKED && (
                        <Tag type="negative">{t('integration.tag.not-linked')}</Tag>
                      )}
                    </ListItemText>
                  </TableCell>
                  <SmallTableCell>
                    {integration.isLinked && (
                      <>
                        {integration.isActive ? (
                          <TertiaryButton
                            className={styles.disableIntegrationButton}
                            onClick={() => updateIntegrationActive.action(integration, false)}
                          >
                            {t('integration.action.disable.disableButton')}
                          </TertiaryButton>
                        ) : (
                          <TertiaryButton
                            className={styles.enableIntegrationButton}
                            onClick={() => updateIntegrationActive.action(integration, true)}
                          >
                            {t('integration.action.disable.enableButton')}
                          </TertiaryButton>
                        )}
                      </>
                    )}
                  </SmallTableCell>
                  <SmallTableCell>
                    <TertiaryButton
                      className={styles.deleteIntegrationButton}
                      onClick={() => {
                        confirm({
                          message: t('integration.action.delete.question'),
                          confirmButton: t('integration.action.delete.confirm'),
                          cancelButton: t('integration.action.delete.cancel'),
                          action: () => removeIntegration.action(integration),
                        });
                      }}
                    >
                      {t('integration.action.delete.button')}
                    </TertiaryButton>
                  </SmallTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <TertiaryButton onClick={() => onClose()}>{t('global.close')}</TertiaryButton>
      </DialogActions>
    </Dialog>
  );
};
export default IntegrationDialog;
