import { useEffect, useMemo, useState } from 'react';
import { Advert } from '../../../models/Advert';
import { AdvertPartnerLink } from '../../../models/Link';
import { Partner } from '../../../models/Partner';
import { linkRepo, partnerRepo } from '../../../repo';
import { defined } from '../../../utils';
import useRenderSafe from '../../useRenderSafe';

export interface AdvertPartner {
  partner: Partner | undefined;
  link: AdvertPartnerLink;
}

const useAdvertPartners = (advert?: Advert, linksOnly?: boolean) => {
  const partnerId = useRenderSafe(advert?.partnerId);
  const advertId = useRenderSafe(advert?.id);
  const [advertPartnerLinks, setAdvertPartnerLinks] = useState<AdvertPartnerLink[]>();
  const [advertPartnersData, setAdvertPartnersData] = useState<{
    [linkId: string]: {
      link: AdvertPartnerLink;
      partner?: Partner;
      unsubscribe?: () => void;
    };
  }>({});

  useEffect(() => {
    try {
      if (!partnerId || !advertId) return setAdvertPartnerLinks(undefined);
      return linkRepo.onAdvertPartnerLinks(partnerId, advertId, (advertPartnerLinks) =>
        setAdvertPartnerLinks(advertPartnerLinks),
      );
    } catch (error) {
      setAdvertPartnerLinks(undefined);
    }
  }, [partnerId, advertId]);

  useEffect(() => {
    if (linksOnly) setAdvertPartnersData({});
    if (!partnerId || !advertId)
      return setAdvertPartnersData((data) => {
        Object.entries(data).forEach(([, { unsubscribe }]) => {
          if (unsubscribe) unsubscribe();
        });
        return {};
      });
    setAdvertPartnersData((data) => {
      //Add new and update existing
      Object.keys(data).forEach((linkId) => {
        if (!advertPartnerLinks?.some((p) => p.id === linkId)) {
          const unsubscribe = data[linkId].unsubscribe;
          if (unsubscribe) unsubscribe();
          delete data[linkId];
        }
      });
      //Remove old
      advertPartnerLinks?.forEach((advertPartnerLink) => {
        if (!advertPartnerLink.id) return;
        if (!data[advertPartnerLink.id]) {
          data[advertPartnerLink.id] = {
            link: advertPartnerLink,
          };
          const unsubscribe = partnerRepo.onPartner(advertPartnerLink.partnerId, (advert) => {
            setAdvertPartnersData((old) => ({
              ...old,
              [defined(advertPartnerLink.id)]: {
                ...old[defined(advertPartnerLink.id)],
                advert,
                unsubscribe,
              },
            }));
          });
        } else {
          data[advertPartnerLink.id].link = advertPartnerLink;
        }
      });
      return data;
    });
    return () => {
      setAdvertPartnersData((data) => {
        Object.entries(data).forEach(([, { unsubscribe }]) => {
          if (unsubscribe) unsubscribe();
        });
        return {};
      });
    };
  }, [advertPartnerLinks, advertId, linksOnly, partnerId]);

  const advertPartners: AdvertPartner[] = useMemo(
    () =>
      Object.values(advertPartnersData)
        .filter((p) => p.partner)
        .map(({ partner, link }) => ({ partner, link })),
    [advertPartnersData],
  );

  return { advertPartnerLinks, advertPartners };
};
export default useAdvertPartners;
