import { createContext, FC, useContext } from 'react';
import { Member } from '../../models/Member';
import { Partner } from '../../models/Partner';
import useCurrentPartner from '../data/useCurrentPartner';
import useMember from '../data/useMember';
import { useAuth } from './useAuth';

interface PartnerContextProps {
  partner: Partner;
  currentMember: Member;
}

export const PartnerContext = createContext<PartnerContextProps>({} as PartnerContextProps);
export const usePartnerCtx = () => useContext(PartnerContext);
export const PartnerCtxProvider: FC = ({ children }) => {
  const { currentUser } = useAuth();
  const member = useMember(currentUser);
  const partner = useCurrentPartner(member);

  return (
    <PartnerContext.Provider
      value={{
        partner: partner as Partner,
        currentMember: member as Member,
      }}
    >
      {partner && member && children}
    </PartnerContext.Provider>
  );
};
