import fb from 'firebase/app';
import getFromPath from 'lodash.get';
import setFromPath from 'lodash.set';
import { FieldValue } from '../firebase';

abstract class FirestoreRepo {
  protected readonly fs;
  constructor(fs: fb.firestore.Firestore) {
    this.fs = fs;
  }

  protected converter(
    dateTime: string[],
  ): fb.firestore.FirestoreDataConverter<fb.firestore.DocumentData> {
    return {
      fromFirestore: (snap) => {
        const fsData = snap.data();
        dateTime.forEach((path) => {
          const timeStamp = getFromPath(fsData, path);
          if (!timeStamp) return;
          setFromPath(fsData, path, timeStamp.toDate());
        });

        return fsData;
      },
      toFirestore: (data) => {
        dateTime.forEach((path) => {
          const date: Date | FieldValue | undefined = getFromPath(data, path);
          if (!date || !('getTime' in date)) return; //Checks if date exists and if it is actually a Date not a Fieldvalue
          setFromPath(data, path, fb.firestore.Timestamp.fromDate(date));
        });

        return data;
      },
    };
  }

  protected filteredQuery<T>(query: fb.firestore.Query<T>, filter: Partial<T>) {
    return Object.entries(filter).reduce((acc, [k, v]) => acc.where(k, '==', v), query);
  }

  protected clearUndefined<T>(arr: (T | undefined)[]) {
    return arr.filter((p): p is T => p !== undefined);
  }
}
export { FirestoreRepo };
