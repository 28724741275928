import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useAuth } from '../../../hooks/context/useAuth';
import { FormikInstance } from '../../../types/Formik';
import { onFunction } from '../../../types/general';

type ResetPasswordFormik = FormikInstance<{
  email: string;
}>;

const useResetPasswordForm = (
  onSuccess?: onFunction,
  onFail?: onFunction,
  onFinish?: onFunction,
) => {
  const { resetPassword } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const formik: ResetPasswordFormik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: async ({ email }) => {
      try {
        await resetPassword(email);
        enqueueSnackbar(t('account.message.reset-password-succes'), { variant: 'success' });
        formik.resetForm();
        if (onSuccess) onSuccess();
      } catch (error) {
        enqueueSnackbar(t('account.message.reset-password-failed'), { variant: 'error' });
        if (onFail) onFail();
      } finally {
        if (onFinish) onFinish();
      }
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email(t('account.validation.email-invalid'))
        .required(t('account.validation.email-required')),
    }),
  });

  return formik;
};
export default useResetPasswordForm;
export type { ResetPasswordFormik };
