import { useState } from 'react';
import { MediaType, newMedia, SizeType, toMedia } from '../../../models/Media';
import { Partner } from '../../../models/Partner';
import { mediaRepo, mediaStorageRepo } from '../../../repo';

const useCreateMedia = () => {
  const [isBusy, setIsBusy] = useState(false);
  const action = async (
    partner: Partner,
    props: { name: string; file: File; backgroundColor: string },
  ) => {
    try {
      if (!partner.id) return;
      setIsBusy(true);
      const aspectRatio = await getAspectRatio(props.file);
      const sizeType = getSizeType(aspectRatio);
      const fileName = await uploadFile(partner.id, props.file);

      const media = newMedia(MediaType.IMAGE)(partner.id, {
        name: props.name,
        size: sizeType,
        backgroundColor: props.backgroundColor,
        fileName,
        isActive: true,
      });
      const id = await mediaRepo.create(media);
      return toMedia(media.partnerId, media.props, id);
    } catch (error) {
    } finally {
      setIsBusy(false);
    }
  };

  return { isBusy, action };
};
export default useCreateMedia;

function getAspectRatio(file: File) {
  return new Promise<number>((resolve, reject) => {
    try {
      const img = new Image();
      img.onload = async function () {
        const width: number = (this as any).width;
        const height: number = (this as any).height;
        const aspectRatio = width / height;
        resolve(aspectRatio);
      };
      img.src = URL.createObjectURL(file);
    } catch (error) {
      reject(error);
    }
  });
}
function getSizeType(aspectRatio: number) {
  if (aspectRatio > 1.5) {
    if (aspectRatio > 3) {
      return SizeType.STRIP;
    } else {
      return SizeType.LANDSCAPE;
    }
  } else if (aspectRatio < 0.6) {
    if (aspectRatio < 0.3) {
      return SizeType.LANE;
    } else {
      return SizeType.PORTRAIT;
    }
  } else {
    return SizeType.SQUARE;
  }
}
function uploadFile(partnerId: string, file: File) {
  return new Promise<string>((resolve, reject) => {
    try {
      mediaStorageRepo.onCreate(
        partnerId,
        file,
        () => {},
        async (fileName) => {
          resolve(fileName);
        },
        (error) => {
          reject(error);
        },
      );
    } catch (error) {
      reject(error);
    }
  });
}
