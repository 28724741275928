import { useEffect, useState } from 'react';
import { Advert } from '../../../models/Advert';
import { Partner } from '../../../models/Partner';
import { advertRepo } from '../../../repo';
import useRenderSafe from '../../useRenderSafe';

const useAdvert = (partner?: Partner, advertId?: string) => {
  const partnerId = useRenderSafe(partner?.id);
  const [advert, setAdvert] = useState<Advert>();

  useEffect(() => {
    try {
      if (!partnerId || !advertId) return setAdvert(undefined);
      return advertRepo.onAdvert(partnerId, advertId, (advert) => setAdvert(advert));
    } catch (error) {
      setAdvert(undefined);
    }
  }, [partnerId, advertId]);

  return advert;
};
export default useAdvert;
