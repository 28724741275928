import { LinkType } from '../enum';
import { LinkBase, LinkBaseProps, NewLinkBaseProps } from '../Link.base';

interface ExternalAdvertIntegrationLinkProps extends LinkBaseProps {
  type: LinkType.EXT_ADVERT_INTEGRATION;
  extPartnerId: string;
  advertId: string;
  integrationId: string;
}

interface NewExternalAdvertIntegrationLinkProps extends NewLinkBaseProps {
  extPartnerId: string;
  advertId: string;
  integrationId: string;
}

class ExternalAdvertIntegrationLink extends LinkBase<ExternalAdvertIntegrationLinkProps> {
  static new(partnerId: string, props: NewExternalAdvertIntegrationLinkProps) {
    return new ExternalAdvertIntegrationLink(partnerId, {
      type: LinkType.EXT_ADVERT_INTEGRATION,
      ...super.New(props),
    });
  }

  get type() {
    return this._props.type;
  }
  get extPartnerId() {
    return this._props.extPartnerId;
  }
  get advertId() {
    return this._props.advertId;
  }
  get integrationId() {
    return this._props.integrationId;
  }
}

export { ExternalAdvertIntegrationLink };
export type { ExternalAdvertIntegrationLinkProps, NewExternalAdvertIntegrationLinkProps };
