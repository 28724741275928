import { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import ROUTES from '../../constants/routes';
import { useAuth } from '../../hooks/context/useAuth';

const PrivateRoute: FC<RouteProps> = ({ component, ...props }) => {
  const auth = useAuth();

  const authComponent =
    auth.currentUser && component ? component : <Redirect to={ROUTES.SIGN_IN} />;

  if (auth.currentUser) return <Route component={authComponent as any} {...props} />;
  return <Route render={() => <Redirect to={ROUTES.SIGN_IN} />} {...props} />;
};
export default PrivateRoute;
