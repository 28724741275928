import { useState } from 'react';
import { Advert } from '../../../models/Advert';
import { LinkType, newLink } from '../../../models/Link';
import { Partner } from '../../../models/Partner';
import { linkRepo } from '../../../repo';
import { defined } from '../../../utils';

const useCreateAdvertPartnerLink = () => {
  const [isBusy, setIsBusy] = useState(false);
  const action = async (advert: Advert, partner: Partner) => {
    console.log({ advert, partner });
    try {
      setIsBusy(true);
      const link = newLink(LinkType.ADVERT_PARTNER)(advert.partnerId, {
        advertId: defined(advert.id, 'advert.id'),
        linkedPartnerId: defined(partner.id, 'partner.id'),
        isActive: true,
      });
      await linkRepo.create(link);
    } catch (error) {
    } finally {
      setIsBusy(false);
    }
  };

  return { isBusy, action };
};
export default useCreateAdvertPartnerLink;
