import { Dispatch, SetStateAction, VFC } from 'react';
import { usePartnerCtx } from '../../hooks/context/usePartner';
import useAdvert from '../../hooks/data/advert/useAdvert';
import { AdvertCreateStep } from './AdvertCreateWizard';
import AdvertStep from './AdvertStep';
import IntegrationStep from './IntegrationStep';
import MediaStep from './MediaStep';
import PartnerStep from './PartnerStep';

export interface AdvertCreateWizardContentProps {
  step: AdvertCreateStep;
  advertId?: string;
  setAdvertId: Dispatch<SetStateAction<string | undefined>>;
  nextStep: () => void;
}

const AdvertCreateWizardContent: VFC<AdvertCreateWizardContentProps> = ({
  step,
  advertId,
  setAdvertId,
  nextStep,
}) => {
  const { partner } = usePartnerCtx();
  const advert = useAdvert(partner, advertId);

  switch (step) {
    case AdvertCreateStep.ADVERT:
      return <AdvertStep setAdvertId={setAdvertId} nextStep={nextStep} />;
    case AdvertCreateStep.MEDIA:
      return advert ? <MediaStep advert={advert} /> : null;
    case AdvertCreateStep.INTEGRATION:
      return advert ? <IntegrationStep advert={advert} /> : null;
    case AdvertCreateStep.PARTNER:
      return advert ? <PartnerStep advert={advert} /> : null;

    default:
      return null;
  }
};

export default AdvertCreateWizardContent;
