import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { useState, VFC } from 'react';
import { useTranslation } from 'react-i18next';
import { usePartnerCtx } from '../hooks/context/usePartner';
import useAdvert from '../hooks/data/advert/useAdvert';
import useCreateMediaForm from '../hooks/form/useCreateMediaForm';
import ColorButton from './common/ColorButton';
import FileButton from './common/FileButton';
import PrimaryButton from './common/group.button/PrimaryButton';
import TertiaryButton from './common/group.button/TertiaryButton';
import Image from './common/Image';
import Form from './Form';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    overflowY: 'auto',
  },
  formWrapper: {},
  form: {
    flex: 2,

    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  image: {
    flex: 3,

    display: 'flex',
    flexDirection: 'column',

    maxHeight: '100%',
    overflowY: 'auto',
  },

  fill: {
    flex: 1,
  },
}));

export interface MediaCreateDialogProps {
  isOpen: boolean;
  onClose: () => void;
  advertId?: string;
}

const MediaCreateDialog: VFC<MediaCreateDialogProps> = ({ isOpen, onClose, advertId }) => {
  const { t } = useTranslation();
  const styles = useStyles();

  const { partner } = usePartnerCtx();
  const advert = useAdvert(partner, advertId);

  const [url, setUrl] = useState<string>();

  const form = useCreateMediaForm({
    partner,
    advert,
    onSuccess: () => {
      onClose();
      setUrl(undefined);
    },
  });

  return (
    <Dialog open={isOpen} maxWidth="md" fullWidth>
      <Form className={styles.formWrapper} form={form}>
        <DialogTitle>{t('media.create-dialog-header')}</DialogTitle>
        <DialogContent className={styles.content}>
          <div className={styles.form}>
            <TextField
              label={t('media.label')}
              value={form.values.label}
              onChange={(e) => {
                form.setFieldValue('label', e.target.value);
              }}
              error={Boolean(form.errors.label)}
              helperText={form.errors.label}
            />
            <ColorButton
              value={form.values.backgroundColor}
              onChange={(color) => {
                form.setFieldValue('backgroundColor', color);
              }}
            >
              {t('media.backgroundColor')}
            </ColorButton>
            <FileButton
              onChange={(file) => {
                form.setFieldValue('file', file);
                setUrl(file ? URL.createObjectURL(file) : undefined);
              }}
              accept="image/*"
            >
              {t('media.file')}
            </FileButton>
            <FormHelperText error>{form.errors.file}</FormHelperText>
          </div>
          <div className={styles.image}>
            <Image
              alt={form.values.label}
              backgroundColor={form.values.backgroundColor.hex}
              url={url}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <PrimaryButton type="submit">{t('media.action.create.saveButton')}</PrimaryButton>
          <TertiaryButton onClick={() => onClose()}>{t('global.close')}</TertiaryButton>
        </DialogActions>
      </Form>
    </Dialog>
  );
};
export default MediaCreateDialog;
