import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Partner } from '../../models/Partner';
import useCreateExternalPartnerPartnerLink from '../action/partnerLink/useCreateExternalPartnerPartnerLink';

export interface useCreateOutgoingPartnerFormConfig {
  partner?: Partner;
  onSuccess?: () => Promise<void> | void;
}

const useCreateOutgoingPartnerForm = ({
  partner,
  onSuccess,
}: useCreateOutgoingPartnerFormConfig) => {
  const { t } = useTranslation();

  const createPartnerLink = useCreateExternalPartnerPartnerLink();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      key: '',
    },
    onSubmit: async ({ key }) => {
      if (!partner) return;
      await createPartnerLink.action(partner, key);
      formik.resetForm();
      if (onSuccess) await onSuccess();
    },
    validationSchema: yup.object({
      key: yup
        .string()
        .required(t('partnerLink.validation.partnerId-required'))
        .length(20, t('partnerLink.validation.partnerId-invalid')),
    }),
  });
  return formik;
};
export default useCreateOutgoingPartnerForm;
