import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Step,
  StepLabel,
  Stepper,
} from '@material-ui/core';
import { useState, VFC } from 'react';
import { useTranslation } from 'react-i18next';
import { usePartnerCtx } from '../../hooks/context/usePartner';
import { PartnerPermission } from '../../models/Partner/enum';
import PrimaryButton from '../common/group.button/PrimaryButton';
import TertiaryButton from '../common/group.button/TertiaryButton';
import AdvertCreateWizardContent from './AdvertCreateWizardContent';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    gap: theme.spacing(1),
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
}));

export enum AdvertCreateStep {
  ADVERT = 'advert',
  MEDIA = 'media',
  INTEGRATION = 'integration',
  PARTNER = 'partner',
}

export interface AdvertCreateWizardProps {
  isOpen: boolean;
  onClose: () => void;
}

const AdvertCreateWizard: VFC<AdvertCreateWizardProps> = ({ isOpen, onClose }) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const { partner } = usePartnerCtx();

  const [step, setStep] = useState(AdvertCreateStep.ADVERT);
  const [advertId, setAdvertId] = useState<string>();

  const steps = [
    AdvertCreateStep.ADVERT,
    AdvertCreateStep.MEDIA,
    partner.hasPermission(PartnerPermission.PARTNERSHIP_OWNER)
      ? AdvertCreateStep.INTEGRATION
      : undefined,
    partner.hasPermission(PartnerPermission.PARTNERSHIP_MEMBER)
      ? AdvertCreateStep.PARTNER
      : undefined,
  ];

  const reset = () => {
    setStep(AdvertCreateStep.ADVERT);
    setAdvertId(undefined);
    onClose();
  };
  const nextStep = () => {
    switch (step) {
      case AdvertCreateStep.ADVERT:
        setStep(AdvertCreateStep.MEDIA);
        break;
      case AdvertCreateStep.MEDIA:
        if (partner.hasPermission(PartnerPermission.PARTNERSHIP_OWNER))
          setStep(AdvertCreateStep.INTEGRATION);
        else setStep(AdvertCreateStep.PARTNER);
        break;
      case AdvertCreateStep.INTEGRATION:
        if (partner.hasPermission(PartnerPermission.PARTNERSHIP_MEMBER))
          setStep(AdvertCreateStep.PARTNER);
        else reset();
        break;
      case AdvertCreateStep.PARTNER:
        reset();
        break;

      default:
        console.log(step);
        break;
    }
  };
  const previousStep = () => {
    switch (step) {
      case AdvertCreateStep.ADVERT:
        reset();
        break;
      case AdvertCreateStep.MEDIA:
        break;
      case AdvertCreateStep.INTEGRATION:
        setStep(AdvertCreateStep.MEDIA);
        break;
      case AdvertCreateStep.PARTNER:
        if (partner.hasPermission(PartnerPermission.PARTNERSHIP_OWNER))
          setStep(AdvertCreateStep.INTEGRATION);
        else setStep(AdvertCreateStep.MEDIA);
        break;

      default:
        break;
    }
  };

  return (
    <Dialog open={isOpen} fullScreen>
      <DialogTitle>
        <Stepper activeStep={steps.findIndex((p) => p === step)}>
          {steps?.map(
            (step) =>
              step && (
                <Step key={step}>
                  <StepLabel>{t(`advert.action.create.step.${step}`)}</StepLabel>
                </Step>
              ),
          )}
        </Stepper>
      </DialogTitle>
      <DialogContent className={styles.content}>
        <AdvertCreateWizardContent
          step={step}
          advertId={advertId}
          setAdvertId={setAdvertId}
          nextStep={nextStep}
        />
      </DialogContent>
      <DialogActions className={styles.actions}>
        {step !== AdvertCreateStep.MEDIA && (
          <TertiaryButton onClick={() => previousStep()}>
            {step === AdvertCreateStep.ADVERT
              ? t('advert.action.create.cancel')
              : t('advert.action.create.previousStep')}
          </TertiaryButton>
        )}
        {step !== AdvertCreateStep.ADVERT && (
          <PrimaryButton onClick={() => nextStep()}>
            {(!partner.hasPermission(PartnerPermission.PARTNERSHIP_MEMBER) &&
              step === AdvertCreateStep.INTEGRATION) ||
            step === AdvertCreateStep.PARTNER
              ? t('advert.action.create.finish')
              : t('advert.action.create.nextStep')}
          </PrimaryButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AdvertCreateWizard;
