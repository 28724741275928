export function defined<T>(v?: T, name: string = '?') {
  if (v === undefined) throw new Error(`Expected ${name} to be defined`);
  return v;
}
export function notDefined<T>(v?: T, name: string = '?') {
  if (v !== undefined) throw new Error(`Expected ${name} to be undefined`);
  return undefined;
}
export function single<T>(v: T[], name: string = '?') {
  if (v.length > 1) throw new Error(`Expected only one ${name}`);
  return v[0] ? v[0] : undefined;
}
