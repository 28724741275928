import { Fade, makeStyles, Typography } from '@material-ui/core';
import { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import { usePartnerCtx } from '../../hooks/context/usePartner';
import usePartnerAdverts from '../../hooks/data/partner/usePartnerAdverts';
import usePartnerExternalAdverts from '../../hooks/data/partner/usePartnerExternalAdverts';
import { Partner } from '../../models/Partner';
import AdvertAccordion from './AdvertAccordion';
import ExternalAdvertAccordion from './ExternalAdvertAccordion';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',

    margin: theme.spacing(1),
    gap: theme.spacing(1),
  },
  main: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    overflowY: 'auto',
    gap: theme.spacing(1),
  },
  right: {
    flex: 3,

    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',

    maxHeight: '100%',
    gap: theme.spacing(1),
  },
  advertList: {
    flex: 3,

    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',

    maxHeight: '100%',
  },
  bar: {
    overflow: 'visible',
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    padding: theme.spacing(1),
  },

  accordionContent: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    gap: theme.spacing(4),
  },
  deleteAdvertButton: {
    color: theme.palette.error.main,
  },
  disableAdvertButton: {
    color: theme.palette.error.main,
  },
  enableAdvertButton: {
    color: theme.palette.success.main,
  },

  fill: {
    flex: 1,
  },
  fillRow: {
    flex: 1,

    display: 'flex',
    flexDirection: 'row',

    gap: theme.spacing(4),
  },
}));

export interface AdvertListProps {
  filter: string;
  selectedPartner?: Partner;
}

const AdvertList: VFC<AdvertListProps> = ({ selectedPartner, filter }) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const { partner } = usePartnerCtx();
  const partnerAdverts = usePartnerAdverts(partner);
  const { externalAdverts } = usePartnerExternalAdverts(partner, selectedPartner);

  return (
    <Fade in>
      <div className={styles.advertList}>
        {selectedPartner?.id === partner.id ? (
          partnerAdverts && partnerAdverts?.length <= 0 ? (
            <Typography align="center">
              <i>{t('partner.advert.no-adverts-internal')}</i>
            </Typography>
          ) : (
            partnerAdverts
              ?.filter((p) => p.name.toLowerCase().includes(filter.toLowerCase()))
              .map((advert) => <AdvertAccordion key={advert.id} advert={advert} />)
          )
        ) : externalAdverts && externalAdverts?.length <= 0 ? (
          <Typography align="center">
            <i>{t('partner.advert.no-adverts-external')}</i>
          </Typography>
        ) : (
          externalAdverts
            ?.filter((p) => p.advert?.name.toLowerCase().includes(filter.toLowerCase()))
            .map(
              ({ advert }) => advert && <ExternalAdvertAccordion key={advert.id} advert={advert} />,
            )
        )}
      </div>
    </Fade>
  );
};

export default AdvertList;
