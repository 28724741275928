import { LinkType } from './enum';
import {
  AdvertIntegrationLink,
  AdvertMediaLink,
  AdvertPartnerLink,
  ExternalAdvertIntegrationLink,
} from './spec';

function newLink(type: LinkType.ADVERT_MEDIA): typeof AdvertMediaLink.new;
function newLink(type: LinkType.ADVERT_INTEGRATION): typeof AdvertIntegrationLink.new;
function newLink(type: LinkType.ADVERT_PARTNER): typeof AdvertPartnerLink.new;
function newLink(type: LinkType.EXT_ADVERT_INTEGRATION): typeof ExternalAdvertIntegrationLink.new;
function newLink(type: LinkType) {
  switch (type) {
    case LinkType.ADVERT_INTEGRATION:
      return AdvertIntegrationLink.new;
    case LinkType.ADVERT_MEDIA:
      return AdvertMediaLink.new;
    case LinkType.ADVERT_PARTNER:
      return AdvertPartnerLink.new;
    case LinkType.EXT_ADVERT_INTEGRATION:
      return ExternalAdvertIntegrationLink.new;

    default:
      throw new Error('Unknown implementation');
  }
}

export { newLink };
