import { useState } from 'react';
import { Advert } from '../../../models/Advert';
import { Integration } from '../../../models/Integration';
import { LinkType, newLink } from '../../../models/Link';
import { linkRepo } from '../../../repo';
import { defined } from '../../../utils';

const useCreateAdvertIntegrationLink = () => {
  const [isBusy, setIsBusy] = useState(false);
  const action = async (advert: Advert, integration: Integration) => {
    try {
      setIsBusy(true);
      const link = newLink(LinkType.ADVERT_INTEGRATION)(advert.partnerId, {
        advertId: defined(advert.id, 'advert.id'),
        integrationId: defined(integration.id, 'integration.id'),
        isActive: true,
      });
      await linkRepo.create(link);
    } catch (error) {
    } finally {
      setIsBusy(false);
    }
  };

  return { isBusy, action };
};
export default useCreateAdvertIntegrationLink;
