import { Fade, makeStyles, Paper, TextField, Typography } from '@material-ui/core';
import { useEffect, useState, VFC } from 'react';
import { useTranslation } from 'react-i18next';
import useAdvertCreateWizard from '../../hooks/context/useAdvertCreateWizard';
import { usePartnerCtx } from '../../hooks/context/usePartner';
import { Partner } from '../../models/Partner';
import { PartnerPermission } from '../../models/Partner/enum';
import PrimaryButton from '../common/group.button/PrimaryButton';
import AdvertList from './AdvertList';
import IncomingPartnerList from './IncomingPartnerList';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',

    margin: theme.spacing(1),
    gap: theme.spacing(1),
  },
  main: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    overflowY: 'auto',
    gap: theme.spacing(1),
  },
  right: {
    flex: 3,

    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',

    maxHeight: '100%',
    gap: theme.spacing(1),
  },
  advertList: {
    flex: 3,

    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',

    maxHeight: '100%',
  },
  bar: {
    overflow: 'visible',
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    padding: theme.spacing(1),
  },

  accordionContent: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    gap: theme.spacing(4),
  },
  deleteAdvertButton: {
    color: theme.palette.error.main,
  },
  disableAdvertButton: {
    color: theme.palette.error.main,
  },
  enableAdvertButton: {
    color: theme.palette.success.main,
  },

  fill: {
    flex: 1,
  },
  fillRow: {
    flex: 1,

    display: 'flex',
    flexDirection: 'row',

    gap: theme.spacing(4),
  },
}));

const AdvertArea: VFC = () => {
  const styles = useStyles();
  const { t } = useTranslation();

  const { partner } = usePartnerCtx();
  const [selectedPartner, setSelectedPartner] = useState<Partner>();
  const [advertNameFilter, setAdvertNameFilter] = useState('');

  const advertCreateWizard = useAdvertCreateWizard();

  useEffect(() => {
    setSelectedPartner(partner);
  }, [partner]);

  return (
    <Fade in>
      <div className={styles.root}>
        <Typography variant="h5">
          <strong> {t('advert.page-header')}</strong>
        </Typography>
        <Typography>{t('advert.page-info')}</Typography>
        <Paper className={styles.bar}>
          <TextField
            type="search"
            placeholder={t('global.search')}
            margin="none"
            size="small"
            variant="outlined"
            className={styles.fill}
            value={advertNameFilter}
            onChange={(e) => setAdvertNameFilter(e.target.value)}
          />
          {selectedPartner?.id === partner.id && (
            <PrimaryButton onClick={() => advertCreateWizard.open()}>
              {t('advert.action.create.button')}
            </PrimaryButton>
          )}
        </Paper>
        <div className={styles.main}>
          {partner.hasPermission(PartnerPermission.PARTNERSHIP_OWNER) && (
            <IncomingPartnerList
              selectedPartner={selectedPartner}
              setSelectedPartner={setSelectedPartner}
            />
          )}
          <AdvertList selectedPartner={selectedPartner} filter={advertNameFilter} />{' '}
        </div>
      </div>
    </Fade>
  );
};
export default AdvertArea;
