import fb from 'firebase/app';
import COLLECTION from '../constants/collections';
import {
  AdvertIntegrationLink,
  AdvertIntegrationLinkProps,
  AdvertMediaLink,
  AdvertMediaLinkProps,
  AdvertPartnerLink,
  AdvertPartnerLinkProps,
  ExternalAdvertIntegrationLink,
  ExternalAdvertIntegrationLinkProps,
  Link,
  LinkProps,
  LinkType,
  toLink,
} from '../models/Link';
import { OnDataFunction } from '../types/general';
import { defined, notDefined, single } from '../utils';
import { FirestoreRepo } from './FirestoreRepo';

class LinkRepo extends FirestoreRepo {
  private root(
    partnerId: string,
    type: LinkType.ADVERT_MEDIA,
  ): fb.firestore.CollectionReference<AdvertMediaLinkProps>;
  private root(
    partnerId: string,
    type: LinkType.ADVERT_INTEGRATION,
  ): fb.firestore.CollectionReference<AdvertIntegrationLinkProps>;
  private root(
    partnerId: string,
    type: LinkType.ADVERT_PARTNER,
  ): fb.firestore.CollectionReference<AdvertPartnerLinkProps>;
  private root(
    partnerId: string,
    type: LinkType.EXT_ADVERT_INTEGRATION,
  ): fb.firestore.CollectionReference<ExternalAdvertIntegrationLinkProps>;
  private root(partnerId: string): fb.firestore.CollectionReference<LinkProps>;
  private root(partnerId: string, type?: LinkType) {
    const root = this.fs.collection(COLLECTION.PARTNER).doc(partnerId).collection(COLLECTION.LINK);
    if (!type) return root;
    return this.filteredQuery(root, { type });
  }

  async getActiveAdvertIntegrationLinksByIntegrationId(partnerId: string, integrationId: string) {
    const snap = await this.filteredQuery(this.root(partnerId, LinkType.ADVERT_INTEGRATION), {
      isActive: true,
      integrationId,
    }).get();
    return this.snapToLinks(partnerId, snap);
  }
  async getActiveAdvertMediaLinksByAdvertId(partnerId: string, advertId: string) {
    const snap = await this.filteredQuery(this.root(partnerId, LinkType.ADVERT_MEDIA), {
      isActive: true,
      advertId,
    }).get();
    return this.snapToLinks(partnerId, snap);
  }

  async getLink(partnerId: string, id: string) {
    const snap = await this.root(partnerId).doc(id).get();
    return this.snapToLink(partnerId, snap);
  }
  async getLinks(partnerId: string, ids: string[]) {
    return this.clearUndefined(
      await Promise.all(ids.map(async (id) => await this.getLink(partnerId, id))),
    );
  }

  async create(link: Link) {
    notDefined(link.id, 'Link.id');
    let id = '';
    switch (link.type) {
      case LinkType.ADVERT_INTEGRATION:
        id = `${link.advertId}_${link.integrationId}`;
        break;
      case LinkType.ADVERT_MEDIA:
        id = `${link.advertId}_${link.mediaId}`;
        break;
      case LinkType.ADVERT_PARTNER:
        id = `${link.advertId}_${link.linkedPartnerId}`;
        break;
      case LinkType.EXT_ADVERT_INTEGRATION:
        id = `${link.advertId}_${link.integrationId}`;
        break;

      default:
        break;
    }
    await this.root(link.partnerId).doc(id).set(link.props);
    return id;
  }
  async update(link: Link) {
    await this.root(link.partnerId).doc(defined(link.id)).update(link.changes);
  }
  async remove(link: Link) {
    await this.root(link.partnerId).doc(defined(link.id)).delete();
  }

  onLink(partnerId: string, linkId: string, onData: OnDataFunction<Link | undefined>) {
    const unsubscribe = this.root(partnerId)
      .doc(linkId)
      .onSnapshot(
        (snap) => {
          const link = this.snapToLink(partnerId, snap);
          onData(link, unsubscribe);
        },
        (error) => {
          console.error(error);
          onData(undefined, unsubscribe);
        },
      );
    return unsubscribe;
  }
  onLinks(partnerId: string, onData: OnDataFunction<Link[]>) {
    const unsubscribe = this.root(partnerId).onSnapshot(
      (snap) => {
        const links = this.snapToLinks(partnerId, snap);
        onData(links, unsubscribe);
      },
      (error) => {
        console.error(error);
        onData([], unsubscribe);
      },
    );
    return unsubscribe;
  }
  onAdvertMediaLinks(
    partnerId: string,
    advertId: string,
    onData: OnDataFunction<AdvertMediaLink[]>,
  ) {
    const unsubscribe = this.filteredQuery(this.root(partnerId, LinkType.ADVERT_MEDIA), {
      advertId,
    }).onSnapshot(
      (snap) => {
        const links = this.snapToLinks(partnerId, snap);
        onData(links, unsubscribe);
      },
      (error) => {
        console.error(error);
        onData([], unsubscribe);
      },
    );
    return unsubscribe;
  }
  onMediaAdvertLinks(
    partnerId: string,
    mediaId: string,
    onData: OnDataFunction<AdvertMediaLink[]>,
  ) {
    const unsubscribe = this.filteredQuery(this.root(partnerId, LinkType.ADVERT_MEDIA), {
      mediaId,
    }).onSnapshot(
      (snap) => {
        const links = this.snapToLinks(partnerId, snap);
        onData(links, unsubscribe);
      },
      (error) => {
        console.error(error);
        onData([], unsubscribe);
      },
    );
    return unsubscribe;
  }
  onAdvertIntegrationLinks(
    partnerId: string,
    advertId: string,
    onData: OnDataFunction<AdvertIntegrationLink[]>,
  ) {
    const unsubscribe = this.filteredQuery(this.root(partnerId, LinkType.ADVERT_INTEGRATION), {
      advertId,
    }).onSnapshot(
      (snap) => {
        const links = this.snapToLinks(partnerId, snap);
        onData(links, unsubscribe);
      },
      (error) => {
        console.error(error);
        onData([], unsubscribe);
      },
    );
    return unsubscribe;
  }
  onIntegrationAdvertLinks(
    partnerId: string,
    integrationId: string,
    onData: OnDataFunction<AdvertIntegrationLink[]>,
  ) {
    const unsubscribe = this.filteredQuery(this.root(partnerId, LinkType.ADVERT_INTEGRATION), {
      integrationId,
    }).onSnapshot(
      (snap) => {
        const links = this.snapToLinks(partnerId, snap);
        onData(links, unsubscribe);
      },
      (error) => {
        console.error(error);
        onData([], unsubscribe);
      },
    );
    return unsubscribe;
  }
  onAdvertIntegrationLink(
    partnerId: string,
    advertId: string,
    integrationId: string,
    onData: OnDataFunction<AdvertIntegrationLink | undefined>,
  ) {
    const unsubscribe = this.filteredQuery(this.root(partnerId, LinkType.ADVERT_INTEGRATION), {
      integrationId,
      advertId,
    }).onSnapshot(
      (snap) => {
        const links = this.snapToLinks(partnerId, snap);
        onData(single(links, 'advertIntegrationLink'), unsubscribe);
      },
      (error) => {
        console.error(error);
        onData(undefined, unsubscribe);
      },
    );
    return unsubscribe;
  }

  onAdvertPartnerLinks(
    partnerId: string,
    advertId: string,
    onData: OnDataFunction<AdvertPartnerLink[]>,
  ) {
    const unsubscribe = this.filteredQuery(this.root(partnerId, LinkType.ADVERT_PARTNER), {
      advertId,
    }).onSnapshot(
      (snap) => {
        const links = this.snapToLinks(partnerId, snap);
        onData(links, unsubscribe);
      },
      (error) => {
        console.error(error);
        onData([], unsubscribe);
      },
    );
    return unsubscribe;
  }
  onAdvertPartnerLinksByLinkedPartner(
    partnerId: string,
    linkedPartnerId: string,
    onData: OnDataFunction<AdvertPartnerLink[]>,
  ) {
    const unsubscribe = this.filteredQuery(this.root(partnerId, LinkType.ADVERT_PARTNER), {
      linkedPartnerId,
    }).onSnapshot(
      (snap) => {
        const links = this.snapToLinks(partnerId, snap);
        onData(links, unsubscribe);
      },
      (error) => {
        console.error(error);
        onData([], unsubscribe);
      },
    );
    return unsubscribe;
  }
  onExternalAdvertIntegrationLinks(
    partnerId: string,
    extAdvertId: string,
    onData: OnDataFunction<ExternalAdvertIntegrationLink[]>,
  ) {
    const unsubscribe = this.filteredQuery(this.root(partnerId, LinkType.EXT_ADVERT_INTEGRATION), {
      advertId: extAdvertId,
    }).onSnapshot(
      (snap) => {
        const links = this.snapToLinks(partnerId, snap);
        onData(links, unsubscribe);
      },
      (error) => {
        console.error(error);
        onData([], unsubscribe);
      },
    );
    return unsubscribe;
  }

  onIntegrationExternalAdvertLinks(
    partnerId: string,
    integrationId: string,
    onData: OnDataFunction<ExternalAdvertIntegrationLink[]>,
  ) {
    const unsubscribe = this.filteredQuery(this.root(partnerId, LinkType.EXT_ADVERT_INTEGRATION), {
      integrationId,
    }).onSnapshot(
      (snap) => {
        const links = this.snapToLinks(partnerId, snap);
        onData(links, unsubscribe);
      },
      (error) => {
        console.error(error);
        onData([], unsubscribe);
      },
    );
    return unsubscribe;
  }

  private snapToLink(
    partnerId: string,
    snap: fb.firestore.DocumentSnapshot<AdvertMediaLinkProps>,
  ): AdvertMediaLink | undefined;
  private snapToLink(
    partnerId: string,
    snap: fb.firestore.DocumentSnapshot<AdvertIntegrationLinkProps>,
  ): AdvertIntegrationLink | undefined;
  private snapToLink(
    partnerId: string,
    snap: fb.firestore.DocumentSnapshot<AdvertPartnerLinkProps>,
  ): AdvertPartnerLink | undefined;
  private snapToLink(
    partnerId: string,
    snap: fb.firestore.DocumentSnapshot<ExternalAdvertIntegrationLinkProps>,
  ): ExternalAdvertIntegrationLink | undefined;
  private snapToLink(
    partnerId: string,
    snap: fb.firestore.DocumentSnapshot<LinkProps>,
  ): Link | undefined;
  private snapToLink(partnerId: string, snap: fb.firestore.DocumentSnapshot<LinkProps>) {
    const data = snap.data();
    if (!data) return undefined;
    return toLink(partnerId, data, snap.id);
  }
  private snapToLinks(
    partnerId: string,
    snap: fb.firestore.QuerySnapshot<AdvertMediaLinkProps>,
  ): AdvertMediaLink[];
  private snapToLinks(
    partnerId: string,
    snap: fb.firestore.QuerySnapshot<AdvertIntegrationLinkProps>,
  ): AdvertIntegrationLink[];
  private snapToLinks(
    partnerId: string,
    snap: fb.firestore.QuerySnapshot<AdvertPartnerLinkProps>,
  ): AdvertPartnerLink[];
  private snapToLinks(
    partnerId: string,
    snap: fb.firestore.QuerySnapshot<ExternalAdvertIntegrationLinkProps>,
  ): ExternalAdvertIntegrationLink[];
  private snapToLinks(partnerId: string, snap: fb.firestore.QuerySnapshot<LinkProps>): Link[];
  private snapToLinks(partnerId: string, snap: fb.firestore.QuerySnapshot<LinkProps>) {
    return this.clearUndefined(snap.docs.map((snap) => this.snapToLink(partnerId, snap)));
  }
}

export { LinkRepo };
