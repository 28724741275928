const ROUTES = {
  REGISTER: '/register',
  SIGN_IN: '/signin',
  FORGOT_PASSWORD: '/forgot-password',
  PARTNER: '/partner',
  ADVERTS: '/partner/advert',
  MEDIA: '/partner/media',
  INTEGRATION: '/partner/integration',
};

export default ROUTES;
