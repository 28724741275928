import { IconButton } from '@material-ui/core';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import { FC, useState } from 'react';
import { Media, MediaType } from '../../models/Media';
import ImagePopover from './ImagePopover';

export interface MediaPreviewIndicatorProps {
  media: Media;
  mediaUrl?: string;
}

const MediaPreviewIndicator: FC<MediaPreviewIndicatorProps> = ({ media, mediaUrl }) => {
  const [anchorEl, setAnchorEl] = useState<Element>();

  switch (media.type) {
    case MediaType.IMAGE:
      return (
        <>
          <IconButton
            size="small"
            onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
            onMouseLeave={(e) => setAnchorEl(undefined)}
          >
            <ImageSearchIcon />
          </IconButton>
          <ImagePopover
            anchorEl={anchorEl}
            alt={media.name}
            url={mediaUrl}
            backgroundColor={media.backgroundColor}
          />
        </>
      );

    default:
      return null;
  }
};
export default MediaPreviewIndicator;
