import { useState } from 'react';
import { Partner } from '../../../models/Partner';
import { newPartnerLink, PartnerLinkType } from '../../../models/PartnerLink';
import { partnerLinkRepo } from '../../../repo';
import { defined } from '../../../utils';

const useCreateExternalPartnerPartnerLink = () => {
  const [isBusy, setIsBusy] = useState(false);
  const action = async (partner: Partner, extPartnerId: string) => {
    try {
      setIsBusy(true);
      const partnerLink = newPartnerLink(PartnerLinkType.EXT_PARTNER_PARTNER)({
        partnerId: extPartnerId,
        extPartnerId: defined(partner.id),
        isActive: true,
      });
      await partnerLinkRepo.create(partnerLink);
    } catch (error) {
    } finally {
      setIsBusy(false);
    }
  };

  return { isBusy, action };
};
export default useCreateExternalPartnerPartnerLink;
