import fb from 'firebase/app';
import { v4 } from 'uuid';
import { Media } from '../models/Media';
import { StorageRepo } from './StorageRepo';

class MediaStorageRepo extends StorageRepo {
  private ref(media: Media) {
    return this.storage.ref(`partners/${media.partnerId}/media/${media.fileName}`);
  }
  private lowresRef(media: Media) {
    return this.storage.ref(`partners/${media.partnerId}/media/lowres_${media.fileName}`);
  }

  async delete(media: Media) {
    try {
      await this.ref(media).delete();
    } catch (error) {}
    try {
      await this.lowresRef(media).delete();
    } catch (error) {}
  }

  async getReadUrl(media: Media) {
    try {
      return await this.lowresRef(media).getDownloadURL();
    } catch (error) {
      return await this.ref(media).getDownloadURL();
    }
  }

  onCreate(
    partnerId: string,
    file: File,
    onProgress: (progress: number) => void,
    onSuccess: (fileName: string) => void,
    onError: (error: Error) => void,
  ) {
    const metadata = {
      contentType: file.type,
    };
    const splittedName = file.name.split('.');
    const fileExtension = splittedName[splittedName.length - 1];
    const fileName = `${v4()}.${fileExtension}`;
    const storageTask = this.storage
      .ref(`partners/${partnerId}/media/${fileName}`)
      .put(file, metadata);

    storageTask.on(
      fb.storage.TaskEvent.STATE_CHANGED,
      (snap) => onProgress((snap.bytesTransferred / snap.totalBytes) * 100),
      (error) => onError(error),
      () => onSuccess(fileName),
    );
  }
}
export { MediaStorageRepo };
