import { useEffect, useState } from 'react';
import { Media } from '../../../models/Media';
import { Partner } from '../../../models/Partner';
import { mediaRepo, mediaStorageRepo } from '../../../repo';
import useRenderSafe from '../../useRenderSafe';

export interface PartnerMedia {
  media: Media;
  mediaUrl?: string | undefined;
}

const usePartnerMedias = (partner?: Partner) => {
  const partnerId = useRenderSafe(partner?.id);
  const [medias, setMedias] = useState<PartnerMedia[]>();

  useEffect(() => {
    try {
      if (!partnerId) return setMedias(undefined);
      return mediaRepo.onMedias(partnerId, async (medias) =>
        setMedias(
          await Promise.all(
            medias.map(async (media) => ({
              media,
              mediaUrl: await mediaStorageRepo.getReadUrl(media),
            })),
          ),
        ),
      );
    } catch (error) {
      setMedias(undefined);
    }
  }, [partnerId]);

  return medias;
};
export default usePartnerMedias;
