import { Box, BoxProps, makeStyles, TextField } from '@material-ui/core';
import clsx from 'clsx';
import { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import ROUTES from '../../constants/routes';
import formikToInput from '../../utils/formikToInput';
import formikToSubmit from '../../utils/formikToSubmit';
import PrimaryButton from '../common/group.button/PrimaryButton';
import LinkButton from '../common/LinkButton';
import useSignInForm from './hooks/useSignInForm';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  spacing: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

interface SignInAreaProps extends BoxProps {
  onSignIn: () => void;
}

const SignInArea: VFC<SignInAreaProps> = ({ onSignIn, className, ...BoxProps }) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const signInForm = useSignInForm(onSignIn);

  return (
    <Box className={clsx(styles.root, className)} {...BoxProps}>
      <form>
        <TextField
          fullWidth
          margin="normal"
          label={t('account.email')}
          {...formikToInput(signInForm, 'email')}
        />
        <TextField
          type="password"
          fullWidth
          margin="normal"
          label={t('account.password')}
          {...formikToInput(signInForm, 'password')}
        />
        <PrimaryButton
          className={styles.spacing}
          fullWidth
          variant="contained"
          color="primary"
          {...formikToSubmit(signInForm)}
        >
          {t('account.login')}
        </PrimaryButton>
      </form>
      <LinkButton fullWidth to={ROUTES.FORGOT_PASSWORD}>
        {t('account.to-reset-password')}
      </LinkButton>
    </Box>
  );
};
export default SignInArea;
